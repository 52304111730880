import React, { useCallback, useState, FormEvent, ChangeEvent } from 'react';
import * as Yup from 'yup';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import api from '../../services/api';
import IRequestDefunctDocs from '../../dtos/IRequestDefunctDocs';

interface IProps {
  handleToogleModal: () => void;
  addNewItemInList: (doc: IRequestDefunctDocs) => void;
  open: boolean;
  defunctId: string;
}

interface IRequest {
  defunctDoc: IRequestDefunctDocs;
}

const useStyles = makeStyles(() => {
  return {
    uploadDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
});

const ModalAddDefunctDocs: React.FC<IProps> = ({
  handleToogleModal,
  addNewItemInList,
  open,
  defunctId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [file, setFile] = useState<File | null>();

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = new FormData();

        if (file) {
          data.append('file', file);
        }
        data.append('name', name);
        data.append('defunctId', defunctId);

        const response = await api.post<IRequest>('defuncts-docs', data);
        // FIX: Arrumar A ORDEM
        addNewItemInList(response.data.defunctDoc);
        setName('');
        setFile(null);
        handleToogleModal();
        enqueueSnackbar('Documento adicionado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      file,
      defunctId,
      enqueueSnackbar,
      handleToogleModal,
      addNewItemInList,
    ],
  );

  const handleChangeAvatar = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
    },
    [],
  );

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item>Novo Documento</Grid>
          <Grid item>
            <IconButton onClick={() => handleToogleModal()}>
              <FiXCircle />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nome do Arquivo"
                name="name"
                onChange={event => setName(event.target.value)}
                value={name}
              />
            </Grid>
            <Grid item md={12} className={classes.uploadDiv}>
              <Typography>{file && file.name}</Typography>
              <label htmlFor="uploadFile">
                <input
                  style={{ display: 'none' }}
                  id="uploadFile"
                  multiple
                  type="file"
                  onChange={handleChangeAvatar}
                />

                <Button color="inherit" variant="text" component="span">
                  Selecionar
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" disabled={isLoading}>
            {isLoading ? <CircularProgress /> : 'Adicionar Arquivo'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalAddDefunctDocs;
