import { format, parseISO } from 'date-fns';
import IRequestHistoric from '../dtos/IRequestHistoric';

const formatHistoric = (historic: IRequestHistoric): IRequestHistoric => {
  return {
    ...historic,
    formatedCreatedAt: format(parseISO(historic.createdAt), 'dd/MM/yyyy'),
  };
};

export default formatHistoric;
