import React, { useCallback, useState } from 'react';
import {
  AppBar,
  Avatar,
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  FiAlignLeft,
  FiArrowLeftCircle,
  FiLogOut,
  FiSearch,
} from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { shade } from 'polished';

import { useAuth } from '../../hooks/auth';

import menuItem from '../../constants/side-navigation';
import ClientSearch from '../../components/ClientSearch';

const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  title: {
    color: '#FFF',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    marginTop: '20px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  userSection: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      color: `${shade(0.2, '#fff')}`,
      transition: 'color 1s',
    },
  },
  userName: {
    paddingLeft: '10px',
    fontWeight: 'bold',
  },
  list: {
    padding: '8px',
  },
  container: {
    paddingTop: '40px',
  },
  itemLink: {
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.7)',
  },
  backButton: {
    backgroundColor: '#000000',
  },
}));

const Dashboard: React.FC = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { user, singOut } = useAuth();

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleMenuToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOpenMenu(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = useCallback(() => {
    setOpenMenu(null);
  }, []);

  const toggleSearch = useCallback(() => {
    setOpenSearch(!openSearch);
  }, [openSearch]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Hidden smDown implementation="css">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Cemitério Parque Maringá
            </Typography>
            {location.pathname !== '/dashboard' && (
              <Button
                className={classes.backButton}
                startIcon={<FiArrowLeftCircle />}
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            )}
            <div className={classes.grow} />
            <Link to="/profile" className={classes.userSection}>
              <Avatar src={user.avatarUrl} alt={user.name} />
              <Typography className={classes.userName}>{user.name}</Typography>
            </Link>
            <div>
              <Tooltip title="Buscar Cliente/Sepultado">
                <IconButton
                  aria-label="Buscar Cliente/Sepultado"
                  color="inherit"
                  onClick={toggleSearch}
                >
                  <FiSearch />
                </IconButton>
              </Tooltip>
              <Tooltip title="Sair do App">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => singOut()}
                >
                  <FiLogOut />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </Hidden>
        <Hidden mdUp implementation="css">
          <Toolbar>
            <Tooltip title="Abrir Menu">
              <IconButton
                aria-label="Abrir Menu"
                color="inherit"
                onClick={handleDrawerToggle}
              >
                <FiAlignLeft />
              </IconButton>
            </Tooltip>
            {location.pathname !== '/dashboard' && (
              <Button
                color="inherit"
                startIcon={<FiArrowLeftCircle />}
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            )}
            <div className={classes.grow} />
            <Button className={classes.userSection} onClick={handleMenuToggle}>
              <Avatar src={user.avatarUrl} alt={user.name} />
            </Button>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={openMenu}
              open={Boolean(openMenu)}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <Link to="/profile">Meu Perfil</Link>
              </MenuItem>
              <MenuItem button onClick={toggleSearch}>
                Buscar Cliente/Sepultado
              </MenuItem>
              <MenuItem button onClick={() => singOut()}>
                Sair do app
              </MenuItem>
            </Menu>
          </Toolbar>
        </Hidden>
      </AppBar>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List className={classes.list}>
              {menuItem
                .filter(item => item.roles.includes(user.role.name))
                .map(item => (
                  <div key={item.id}>
                    <ListSubheader>{item.name}</ListSubheader>
                    {item.subItems
                      .filter(subItem => subItem.roles.includes(user.role.name))
                      .map(subItem => (
                        <Link
                          to={subItem.route}
                          className={classes.itemLink}
                          key={subItem.id}
                        >
                          <ListItem
                            button
                            selected={location.pathname.includes(subItem.route)}
                          >
                            <ListItemIcon>{subItem.icon}</ListItemIcon>
                            <ListItemText primary={subItem.name} />
                          </ListItem>
                        </Link>
                      ))}
                  </div>
                ))}
            </List>
          </div>
        </Drawer>
      </Hidden>
      <ClientSearch open={openSearch} toggle={toggleSearch} />
      <Hidden>
        <Drawer
          variant="temporary"
          BackdropProps={{ invisible: true }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List className={classes.list}>
              {menuItem.map(item => (
                <div key={item.id}>
                  <ListSubheader>{item.name}</ListSubheader>
                  {item.subItems.map(subItem => (
                    <Link
                      to={subItem.route}
                      className={classes.itemLink}
                      key={subItem.id}
                    >
                      <ListItem
                        button
                        selected={location.pathname.includes(subItem.route)}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.name} />
                      </ListItem>
                    </Link>
                  ))}
                </div>
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <Toolbar />

        {children}
      </main>
    </div>
  );
};

export default Dashboard;
