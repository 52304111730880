import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  Card,
  Typography,
  makeStyles,
  createStyles,
  CardContent,
  Box,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FiEye, FiEyeOff, FiX } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';

const useStyles = makeStyles(() =>
  createStyles({
    cardContent: {
      display: 'flex',
      padding: '32px',
      minHeight: '450px',
      flexDirection: 'column',
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '24px',
      justifyContent: 'space-between',
    },
    title1: {
      fontWeight: 'bold',
    },
    subTitle: {
      color: '#546e7a',
      fontWeight: 'bold',
    },
    boxButton: {
      marginTop: '16px',
    },
  }),
);

const SignIn: React.FC = () => {
  const classes = useStyles();
  const { signIn } = useAuth();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleSetPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (!password) {
        setShowPassword(false);
      }
      setPassword(event.currentTarget.value);
    },
    [password],
  );

  const handleAuth = useCallback(
    async (event: FormEvent): Promise<void> => {
      event.preventDefault();
      try {
        setLoading(true);
        const data = {
          userName,
          password,
        };
        const schema = Yup.object().shape({
          userName: Yup.string().required('Login obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });
        await schema.validate(data, { abortEarly: false });
        await signIn({ login: data.userName, password: data.password });

        history.push('/dashboard');
      } catch (err) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    },
    [userName, password, signIn, history],
  );

  return (
    <Card elevation={3}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardTitle}>
          <div>
            <Typography variant="h2" className={classes.title1} gutterBottom>
              Bem-vindo
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.subTitle}
            >
              Para continuar, realize seu login
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box>
          <form noValidate autoComplete="off" onSubmit={handleAuth}>
            <TextField
              variant="outlined"
              label="Usuário"
              margin="normal"
              value={userName}
              onChange={event => setUserName(event.target.value.toUpperCase())}
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              label="Senha"
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handleSetPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      disabled={!password}
                    >
                      {!showPassword ? <FiEye /> : <FiEyeOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              fullWidth
            />
            <Box className={classes.boxButton}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disableElevation
                size="large"
                disabled={!userName || !password || loading}
                onClick={handleAuth}
              >
                {loading ? <CircularProgress /> : 'Acessar'}
              </Button>
            </Box>
          </form>
          {hasError && (
            <Box mt="10px">
              <Alert
                variant="filled"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setHasError(false);
                    }}
                  >
                    <FiX />
                  </IconButton>
                }
              >
                <AlertTitle>Erro ao realizar o login</AlertTitle>
                Login/Senha estão incorretos, tente novamente
              </Alert>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SignIn;
