import React from 'react';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ptBR } from '@material-ui/core/locale';

import AppProvider from './hooks';
import Routes from './routes';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#22863a',
      },
      secondary: {
        main: '#5850EC',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          fontWeight: 700,
          borderRadius: '8px',
        },
        contained: {
          color: '#FFF',
        },
        text: {
          color: '#FFF',
        },
      },
      MuiTypography: {
        h2: {
          fontSize: '1.875rem',
        },
        body1: {
          fontSize: '0.875rem',
          fontWeight: 400,
        },
        h3: {
          fontSize: '1.4996rem',
          fontWeight: 700,
        },
      },
      MuiListItemText: {
        primary: {
          fontWeight: 'bold',
        },
      },
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: 'transparent',
            color: '#22863a',
          },
          '&:hover': {
            borderRadius: '10px',
            transtion: 'border-radius 1s',
          },
        },
      },
      MuiPaper: {
        outlined: {
          padding: '20px',
        },
      },
    },
  },
  ptBR,
);

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <CssBaseline />
            <SnackbarProvider maxSnack={6}>
              <Routes />
            </SnackbarProvider>
          </AppProvider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
