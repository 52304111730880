import React, { useCallback, useState, FormEvent, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import api from '../../services/api';
import IRequestRolesActions from '../../dtos/IRequestRolesActions';

interface IProps {
  handleToogleModal: () => void;
  handleUpdateItemInList: (action: IRequestRolesActions) => void;
  open: boolean;
  action: IRequestRolesActions;
}

interface IRequest {
  updatedRoleAction: IRequestRolesActions;
}
const ModalEditRoleAction: React.FC<IProps> = ({
  handleToogleModal,
  open,
  action,
  handleUpdateItemInList,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nickname, setNickname] = useState<string>('');
  const [route, setRoute] = useState<string>('');

  useEffect(() => {
    if (action) {
      setNickname(action.nickname);
      setRoute(action.route);
    }
  }, [action]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          nickname,
          route,
        };
        const schema = Yup.object().shape({
          nickname: Yup.string().required('Descrição é obrigatório.'),
          route: Yup.string().required('Rota é obrigatório.'),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put<IRequest>(
          `roles-actions/${action.id}`,
          data,
        );
        handleUpdateItemInList(response.data.updatedRoleAction);
        enqueueSnackbar('Ação atualizada com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [nickname, route, enqueueSnackbar, action, handleUpdateItemInList],
  );

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item>Editando Ação</Grid>
          <Grid item>
            <IconButton onClick={() => handleToogleModal()}>
              <FiXCircle />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                label="Descrição"
                required
                fullWidth
                value={nickname}
                onChange={event => setNickname(event.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                label="Rota"
                required
                fullWidth
                value={route}
                onChange={event => setRoute(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" disabled={isLoading}>
            {isLoading ? <CircularProgress /> : 'Atualizar Ação'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditRoleAction;
