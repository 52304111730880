import { format, parseISO } from 'date-fns';
import IRequestDeposits from '../dtos/IRequestDeposits';
import { formatContracts } from './formatContracts';

const formatDeposit = (deposit: IRequestDeposits): IRequestDeposits => {
  return {
    ...deposit,
    formatedCreatedAt: format(parseISO(deposit.createdAt), 'dd/MM/yyyy'),
    contracts: deposit.contracts?.map(item => formatContracts(item)),
  };
};

export default formatDeposit;
