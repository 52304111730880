import React, {
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
  FormEvent,
} from 'react';
import * as Yup from 'yup';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Box,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FiArrowRight, FiX } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import IRequestStreets from '../../../dtos/IRequestStreets';
import IRequestDeposits from '../../../dtos/IRequestDeposits';
import IRequestInativeHistoric from '../../../dtos/IRequestInativeHistoric';

import api from '../../../services/api';
import IRequestClient from '../../../dtos/IRequestClient';
import ListDepositDrawers from '../../../components/ListDepositDrawers';
import TabPanel from '../../../components/TabPanel';
import ListDepositOssuary from '../../../components/ListDepositOssuary';
import FieldNumberFormatCustom from '../../../components/FieldNumberFormatCustom';
import ListBillsInDeposit from './ListBillsInDeposit';
import IRequestBlocks from '../../../dtos/IRequestBlocks';
import ListContractDeposit from '../../../components/ListContractDeposit';
import ModalAddFullClient from '../../../components/ModalAddFullClient';
import StyledBreadcrumb from '../../../components/StyledBreadcrumb';
import IRequestContracts from '../../../dtos/IRequestContracts';

const useStyles = makeStyles(() => {
  return {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    link: {
      margin: 0,
      padding: 0,
      fontWeight: 500,
      textTransform: 'capitalize',
    },

    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '8px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    formControl: {
      minWidth: 120,
    },
  };
});

interface IRequestStreet {
  streets: IRequestStreets[];
  total: number;
}

interface IRequestBlock {
  blocks: IRequestBlocks[];
}

interface IRequestClients {
  clients: IRequestClient[];
}

interface IRequest {
  deposit: IRequestDeposits;
}

interface IParams {
  id: string;
}

interface ILocation {
  blockId?: string;
  streetId?: string;
  clientId?: string;
}

interface IApiRequestBlock {
  blocks: IRequestBlocks;
}
interface IApiRequestStreet {
  street: IRequestStreets;
}
interface IApiRequestClient {
  client: IRequestClient;
}

const formatInativeHistoric = (
  inativeHistoric: IRequestInativeHistoric,
): IRequestInativeHistoric => {
  return {
    ...inativeHistoric,
    formatedCreatedAt: format(
      parseISO(inativeHistoric.createdAt),
      'dd/MM/yyyy',
    ),
  };
};

const DepositForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const location = useLocation<ILocation>();

  const { enqueueSnackbar } = useSnackbar();
  const [originalData, setOriginalData] = useState<IRequestDeposits>(
    {} as IRequestDeposits,
  );
  const [value, setValue] = useState<number>(0);
  const [openModalClient, setOpenModalClient] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [hasOssuary, setHasOssuary] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disableOssuary, setDisableOssuary] = useState<boolean>(false);
  const [sector, setSector] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [valueSold, setValueSold] = useState<string>('');
  const [standardValue, setStandardValue] = useState<string>('');
  const [contracts, setContracts] = useState<IRequestContracts[]>([]);

  const [ccoContract, setCcoContract] = useState<string>('');
  const [generateAutomaticName, setGenerateAutomaticName] = useState<boolean>(
    true,
  );

  const [streets, setStreets] = useState<IRequestStreets[]>([]);
  const [streetSelected, setStreetSelected] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );

  const [blocks, setBlocks] = useState<IRequestBlocks[]>([]);
  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );

  const [clients, setClients] = useState<IRequestClient[]>([]);
  const [clientSelected, setClientSelected] = useState<IRequestClient>(
    {} as IRequestClient,
  );
  const [openContract, setOpenContract] = useState<boolean>(false);
  const [
    inativeHistoric,
    setInativeHistoric,
  ] = useState<IRequestInativeHistoric>({} as IRequestInativeHistoric);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const handleToggleModalAddFullClient = useCallback(() => {
    setOpenModalClient(!openModalClient);
  }, [openModalClient]);

  const handleSelectClient = useCallback((client: IRequestClient) => {
    setClientSelected(client);
  }, []);

  useEffect(() => {
    if (location.state && location.state.clientId) {
      api
        .get<IApiRequestClient>(`clients/${location.state.clientId}`)
        .then(response => {
          setClientSelected(response.data.client);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar o cliente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
    if (location.state && location.state.blockId) {
      api
        .get<IApiRequestBlock>(`blocks/${location.state.blockId}`)
        .then(response => {
          setBlockSelected(response.data.blocks);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar o quadra.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
    if (location.state && location.state.streetId) {
      api
        .get<IApiRequestStreet>(`streets/${location.state.streetId}`)
        .then(response => {
          setStreetSelected(response.data.street);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar a Rua.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [enqueueSnackbar, location]);

  useEffect(() => {
    api
      .get<IRequestClients>('clients/active')
      .then(response => {
        setClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar ao clientes, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    api
      .get<IRequestBlock>('blocks/actives')
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if ('id' in blockSelected) {
      api
        .get<IRequestStreet>(`streets/${blockSelected.id}/actives`)
        .then(response => {
          setStreets(response.data.streets);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as ruas, tente novamente.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [blockSelected, enqueueSnackbar]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      api
        .get<IRequest>(`deposits/${id}`)
        .then(response => {
          setOriginalData(response.data.deposit);
          setName(response.data.deposit.name);
          setSector(response.data.deposit.sector);
          setHasOssuary(response.data.deposit.hasOssuary);
          setIsOpen(response.data.deposit.isOpen);
          setDisableOssuary(response.data.deposit.hasOssuary);
          setStatus(response.data.deposit.status);
          setModel(response.data.deposit.model);
          setCcoContract(response.data.deposit.ccoContract);
          setValueSold(response.data.deposit.valueSold);
          setStandardValue(response.data.deposit.standardValue);

          if (response.data.deposit.status === 'SOLD') {
            setOpenContract(true);
          }
          if (response.data.deposit.note) {
            setNote(response.data.deposit.note);
          }
          if (response.data.deposit.street) {
            setBlockSelected(response.data.deposit.street.block);
            setStreetSelected(response.data.deposit.street);
          }
          if (response.data.deposit.client) {
            setClientSelected(response.data.deposit.client);
          }
          if (response.data.deposit.inativeHistoric) {
            setInativeHistoric(
              formatInativeHistoric(response.data.deposit.inativeHistoric),
            );
          }
          if (response.data.deposit.contracts) {
            setContracts(response.data.deposit.contracts);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao buscar o jazigo.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [id, enqueueSnackbar]);

  useEffect(() => {
    api
      .get<IRequestBlock>('blocks/actives')
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if ('id' in blockSelected) {
      api
        .get<IRequestStreet>(`streets/${blockSelected.id}/actives`)
        .then(response => {
          setStreets(response.data.streets);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as ruas, tente novamente.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [blockSelected, enqueueSnackbar]);

  useEffect(() => {
    api
      .get<IRequestClients>('clients/active')
      .then(response => {
        setClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar ao clientes, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  const handleChangeGenerateName = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setGenerateAutomaticName(event.target.checked);
  };

  const handleChangeStatus = (event: ChangeEvent<{ value: unknown }>): void => {
    setStatus(event.target.value as string);
  };

  const handleChangeModel = (event: ChangeEvent<{ value: unknown }>): void => {
    setModel(event.target.value as string);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          ...(note
            ? {
                note,
              }
            : {}),
          hasOssuary,
          ...(generateAutomaticName
            ? { generateAutomaticName }
            : { name: name.toUpperCase() }),
          ...(sector
            ? {
                sector,
              }
            : {}),
          status,
          model,
          ...(valueSold
            ? {
                valueSold,
              }
            : {}),
          ...(standardValue
            ? {
                standardValue,
              }
            : {}),

          streetId: streetSelected.id,
          ...(clientSelected.id
            ? {
                clientId: clientSelected.id,
              }
            : {}),
          isOpen,
        };
        const schema = Yup.object().shape({
          generateAutomaticName: Yup.boolean(),
          name: Yup.string().when('generateAutomaticName', {
            is: val => !val,
            then: Yup.string().required('Nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          sector: Yup.string(),
          streetId: Yup.string().required('Rua é obrigatório'),
          clientId: Yup.string().when('status', {
            is: val => val === 'SOLD',
            then: Yup.string().required('Cliente é obrigatório'),
            otherwise: Yup.string(),
          }),
          hasOssuary: Yup.boolean().required('Gaveta é obrigatório'),
          isOpen: Yup.boolean().required('Aberto é obrigatório'),
          note: Yup.string(),
          valueSold: Yup.number(),
          standardValue: Yup.number(),
          status: Yup.string().required(),
          model: Yup.string().required(),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.post('deposits', data);
        if (response.data.status === 'SOLD') {
          setValue(3);
          setOpenContract(true);
        } else {
          history.push('/deposits');
        }
        enqueueSnackbar('Jazigo adicionado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      note,
      hasOssuary,
      generateAutomaticName,
      name,
      sector,
      status,
      model,
      valueSold,
      standardValue,
      streetSelected.id,
      clientSelected.id,
      isOpen,
      enqueueSnackbar,
      history,
    ],
  );

  const handleUpdate = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          name: name.toUpperCase(),
          ...(note
            ? {
                note,
              }
            : {}),
          hasOssuary,
          ...(sector
            ? {
                sector,
              }
            : {}),
          status,
          ...(valueSold
            ? {
                valueSold,
              }
            : {}),
          ...(standardValue
            ? {
                standardValue,
              }
            : {}),

          model,
          streetId: streetSelected.id,
          ...(clientSelected.id
            ? {
                clientId: clientSelected.id,
              }
            : {}),
          isOpen,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do jazigo é obrigatório'),
          sector: Yup.string(),
          streetId: Yup.string().required('Rua é obrigatório'),
          clientId: Yup.string().when('status', {
            is: val => val === 'SOLD',
            then: Yup.string().required('Cliente é obrigatório'),
            otherwise: Yup.string(),
          }),
          hasOssuary: Yup.boolean().required('Gaveta é obrigatório'),
          isOpen: Yup.boolean().required('Aberto é obrigatório'),
          note: Yup.string(),
          valueSold: Yup.number(),
          standardValue: Yup.number(),
          status: Yup.string().required(),
          model: Yup.string().required(),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put(`deposits/${id}`, data);
        enqueueSnackbar('Jazigo atualizado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        if (response.data.deposit.status === 'SOLD') {
          setValue(3);
          setOpenContract(true);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        } else {
          enqueueSnackbar('Ocorreu um erro, tente novamente mais tarde.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      note,
      hasOssuary,
      sector,
      status,
      valueSold,
      standardValue,
      model,
      streetSelected.id,
      clientSelected.id,
      isOpen,
      id,
      enqueueSnackbar,
    ],
  );

  return (
    <Container>
      <StyledBreadcrumb deposit={originalData} />
      <h2>{isUpdate ? 'Atualizando Jazigo' : 'Novo Jazigo'}</h2>
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="Geral" className={classes.tabLabel} />
          <Tab
            label={
              <Badge color="secondary" showZero>
                Gavetas
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!isUpdate}
          />
          <Tab
            label={
              <Badge color="secondary" showZero>
                Ossuario
              </Badge>
            }
            disabled={!isUpdate || !hasOssuary}
            className={classes.tabLabel}
          />
          <Tab
            label={
              <Badge color="secondary" showZero>
                Contratos
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!openContract}
          />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={isUpdate ? handleUpdate : handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item md={8} sm={12} xs={12}>
              <Card>
                <Box className={classes.boxHeader}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Typography className={classes.headerTitle}>
                        JAZIGO
                      </Typography>
                      {'id' in inativeHistoric && (
                        <Typography>
                          Inativado em {inativeHistoric.formatedCreatedAt}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      {clientSelected.id && (
                        <Button
                          color="primary"
                          variant="outlined"
                          disableElevation
                          style={{ fontWeight: 700 }}
                          endIcon={<FiArrowRight />}
                          onClick={() =>
                            history.push(`/form/client/${clientSelected.id}`)
                          }
                        >
                          Acessar Perfil do cliente
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Divider variant="fullWidth" />
                <Grid container spacing={2} style={{ padding: '16px' }}>
                  <Grid item md={3} sm={3} xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="CCO"
                      required
                      name="nro_contract"
                      value={ccoContract}
                      disabled
                      onChange={event => setCcoContract(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={6}>
                    <Autocomplete
                      options={blocks}
                      value={blockSelected}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, val) =>
                        option.name === val.name
                      }
                      style={{ width: '100%' }}
                      disabled={
                        !!(location.state && location.state.blockId) || isUpdate
                      }
                      onChange={(event, block) => {
                        if (block) {
                          setBlockSelected(block);
                          setStreetSelected({} as IRequestStreets);
                        } else {
                          setBlockSelected({} as IRequestBlocks);
                          setStreetSelected({} as IRequestStreets);
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Quadras"
                          required
                        />
                      )}
                      noOptionsText="Não encontramos nenhuma quadra ativa, verifique acessando a lista"
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={6}>
                    <Autocomplete
                      options={streets}
                      value={streetSelected}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, val) =>
                        option.name === val.name
                      }
                      style={{ width: '100%' }}
                      disabled={
                        !('id' in blockSelected) ||
                        !!(location.state && location.state.streetId) ||
                        isUpdate
                      }
                      onChange={(event, street) => {
                        if (street) {
                          setStreetSelected(street);
                        } else {
                          setStreetSelected({} as IRequestStreets);
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Ruas"
                          required
                        />
                      )}
                      noOptionsText="Não encontramos nenhuma rua ativa, verifique acessando a lista"
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Jazigo"
                      required
                      name="name"
                      onChange={event => setName(event.target.value)}
                      value={name}
                      disabled={
                        status === 'INACTIVE' ||
                        (generateAutomaticName && !isUpdate)
                      }
                      helperText={
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              onChange={handleChangeGenerateName}
                              value={generateAutomaticName}
                              checked={generateAutomaticName}
                              disabled={status === 'INACTIVE' || isUpdate}
                            />
                          }
                          label="Gerar nome automático"
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={3} xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select
                      label="Setor"
                      name="sector"
                      onChange={event =>
                        setSector(event.target.value as string)
                      }
                      value={sector}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <IconButton onClick={() => setSector('')}>
                                <FiX />
                              </IconButton>
                            </InputAdornment>
                          </>
                        ),
                      }}
                    >
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                      <MenuItem value="E">E</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="G">G</MenuItem>
                      <MenuItem value="H">H</MenuItem>
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="J">J</MenuItem>
                      <MenuItem value="K">K</MenuItem>
                      <MenuItem value="L">L</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item md={9} sm={9} xs={12}>
                    <Autocomplete
                      options={clients}
                      value={clientSelected}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, val) =>
                        option.name === val.name
                      }
                      style={{ width: '100%' }}
                      disabled={
                        status === 'INACTIVE' ||
                        !!(location.state && location.state.clientId)
                      }
                      onChange={(event, client) => {
                        if (client) {
                          setClientSelected(client);
                          setStatus('SOLD');
                        } else {
                          setClientSelected({} as IRequestClient);
                          setStatus('');
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Cliente"
                          helperText={
                            <Typography>
                              Novo Cliente,{' '}
                              <Button
                                className={classes.link}
                                color="primary"
                                onClick={handleToggleModalAddFullClient}
                              >
                                Criar
                              </Button>
                            </Typography>
                          }
                        />
                      )}
                      noOptionsText="Não encontramos nenhum cliente ativo, verifique acessando a lista"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{ padding: '5px 16px 16px 16px ' }}
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <FormControl
                      component="fieldset"
                      disabled={status === 'INACTIVE'}
                      required
                    >
                      <FormLabel component="legend">Status</FormLabel>
                      <RadioGroup
                        row
                        aria-label="status"
                        name="status"
                        value={status}
                        onChange={handleChangeStatus}
                      >
                        <FormControlLabel
                          value="INACTIVE"
                          control={<Radio />}
                          label="Inativo"
                        />
                        <FormControlLabel
                          value="NOTBUILT"
                          control={<Radio />}
                          label="Não construído"
                        />
                        <FormControlLabel
                          value="NOTSOLD"
                          control={<Radio />}
                          label="Não Vendido"
                        />
                        <FormControlLabel
                          value="SOLD"
                          control={<Radio disabled={!clientSelected.id} />}
                          label="Vendido"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <FormControl
                      component="fieldset"
                      disabled={status === 'INACTIVE'}
                      required
                    >
                      <FormLabel component="legend">Modelo</FormLabel>
                      <RadioGroup
                        row
                        aria-label="Modelo"
                        name="Modelo"
                        value={model}
                        onChange={handleChangeModel}
                      >
                        <FormControlLabel
                          value="ADULT"
                          control={<Radio />}
                          label="Adulto"
                        />
                        <FormControlLabel
                          value="DOUBLE"
                          control={<Radio />}
                          label="Duplo"
                        />
                        <FormControlLabel
                          value="AMERICAN"
                          control={<Radio />}
                          label="Americano"
                        />
                        <FormControlLabel
                          value="NOBLE"
                          control={<Radio />}
                          label="Nobre"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          color="primary"
                          onChange={event =>
                            setHasOssuary(event.target.checked)
                          }
                          value={hasOssuary}
                          checked={hasOssuary}
                          disabled={disableOssuary}
                        />
                      }
                      label="Possui Ossuário"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          color="primary"
                          onChange={event => setIsOpen(event.target.checked)}
                          value={isOpen}
                          checked={isOpen}
                        />
                      }
                      label="Jazigo está aberto"
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Observação"
                      name="note"
                      multiline
                      rows="2"
                      onChange={event => setNote(event.target.value)}
                      value={note}
                      disabled={status === 'INACTIVE'}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Valor Padrão"
                      name="name"
                      onChange={event => setStandardValue(event.target.value)}
                      value={standardValue}
                      InputProps={{
                        inputComponent: FieldNumberFormatCustom as any,
                      }}
                      disabled={status === 'INACTIVE'}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Valor Vendido"
                      name="valueSold"
                      onChange={event => setValueSold(event.target.value)}
                      value={valueSold}
                      InputProps={{
                        inputComponent: FieldNumberFormatCustom as any,
                      }}
                      disabled={status === 'INACTIVE'}
                    />
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" />
                <Box className={classes.boxAction}>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    color={!isUpdate ? 'primary' : 'secondary'}
                    disabled={isLoading || status === 'INACTIVE'}
                  >
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      `${isUpdate ? 'Atualizar Jazigo' : 'Adicionar Jazigo'}`
                    )}
                  </Button>
                </Box>
              </Card>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <ListBillsInDeposit contracts={contracts} />
            </Grid>
          </Grid>
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListDepositDrawers depositId={id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ListDepositOssuary depositId={id} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ListContractDeposit depositId={id} clientSelected={clientSelected} />
      </TabPanel>
      <ModalAddFullClient
        open={openModalClient}
        handleToggle={handleToggleModalAddFullClient}
        handleSelectClient={handleSelectClient}
      />
    </Container>
  );
};

export default DepositForm;
