import React from 'react';
import { makeStyles, Container, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'flex-start',
  },
  wrap: {
    height: '90%',
    minHeight: '25rem',
    width: '100%',
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    height: '12.375rem',
    width: '100%',
  },
  main: {
    overflow: 'auto',
    paddingBottom: '1.5rem',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      margin: '-14.875rem auto 0',
      paddingTop: '70px',
      paddingBottom: '70px',
    },
    margin: '-8.875rem auto 0',
    paddingTop: '80px',
    paddingBottom: '80px',
  },
}));

const Auth: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrap}>
        <div className={classes.main}>
          <div className={classes.content} />
          <Container maxWidth="sm" className={classes.container}>
            <div>{children}</div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Auth;
