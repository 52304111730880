import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiCheckCircle, FiEdit, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';
import IRequestUsers from '../../../dtos/IRequestUsers';
import LocaleText from '../../../LocaleText/index.json';

interface IRequest {
  users: IRequestUsers[];
  total: number;
}

interface IRequestStatus {
  deactivateUser: IRequestUsers;
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});
const formatUser = (user: IRequestUsers): IRequestUsers => {
  return {
    ...user,
    formatedCreatedAt: format(parseISO(user.createdAt), 'dd/MM/yyyy'),
  };
};

const ListUsers: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [listUsers, setListUsers] = useState<IRequestUsers[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    (async () => {
      api
        .get<IRequest>('users', {
          params: {
            page: page + 1,
            pageSize,
          },
        })
        .then(res => {
          const { users, total } = res.data;
          const usersFormatted = users.map(user => formatUser(user));
          if (!active) {
            return;
          }
          setTotalItem(total);
          setListUsers(usersFormatted);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, enqueueSnackbar]);

  const handleUpdateStatusUser = useCallback(
    async (id: string) => {
      try {
        setInProgress(true);
        const response = await api.patch<IRequestStatus>(`users/${id}/status`);
        const updatedList = listUsers.filter(user => user.id !== id);
        const userFormatted = formatUser(response.data.deactivateUser);
        setListUsers([...updatedList, userFormatted]);
      } catch {
        enqueueSnackbar(
          'Houve um erro ao atualizar o usuário, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setInProgress(false);
      }
    },
    [listUsers, enqueueSnackbar],
  );
  return (
    <Container>
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Todos Usuários
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Total: {totalItem}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={() => history.push('/form/users')}
              >
                Novo Usuário
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ width: '100%', marginBottom: 16 }} />
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                disableColumnMenu
                localeText={LocaleText}
                rows={listUsers as RowModel[]}
                columns={[
                  {
                    field: 'Avatar',
                    headerName: 'Avatar',
                    flex: 0.5,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <Avatar src={params?.row.avatarUrl} />
                    ),
                  },
                  {
                    field: 'name',
                    headerName: 'Nome',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.value ? 'Sem apelido' : params.value}
                      </div>
                    ),
                  },
                  {
                    field: 'date',
                    headerName: 'Criado em',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formatedCreatedAt}</>
                    ),
                  },
                  {
                    field: 'author',
                    headerName: 'Autor',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.user.name ? '' : params.row.user.name}
                      </div>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    width: 150,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `form/users/${params.row.id.toString()}`,
                            )
                          }
                        >
                          <FiEdit className={classes.editButton} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleUpdateStatusUser(params.row.id.toString())
                          }
                          disabled={inProgress}
                        >
                          {params.row.active ? (
                            <FiTrash2 className={classes.trashButton} />
                          ) : (
                            <FiCheckCircle className={classes.iconActive} />
                          )}
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

export default ListUsers;
