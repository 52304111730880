import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as Yup from 'yup';
import { ptBR } from 'date-fns/esm/locale';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import FieldNumberFormatCustom from '../../../../components/FieldNumberFormatCustom';
import api from '../../../../services/api';
import ListItem from './ListItem';
import IRequestItemContract from '../../../../dtos/IRequestItemContract';
import formatItemContract from '../../../../utils/formatItemContract';
import { formatPrice } from '../../../../utils/formatPriceBeforeSend';
import ModalConfirmItemToBill from './ModalConfirmItemToBill';
import IRequestClient from '../../../../dtos/IRequestClient';
import IRequestDeposits from '../../../../dtos/IRequestDeposits';

interface IPropsItems {
  contractId: string;
  contractCreated: boolean;
  contractItems: IRequestItemContract[];
  contractActive: boolean;
  client: IRequestClient;
  ccoNumber: number;
  deposit: IRequestDeposits;
}

interface IRequestPost {
  item: IRequestItemContract;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
  };
});

const ItemsForm: React.FC<IPropsItems> = ({
  contractId,
  contractCreated,
  contractItems,
  contractActive,
  client,
  ccoNumber,
  deposit,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [maintenanceDate, setMaintenanceDate] = useState<Date | null>(null);
  const [product, setProduct] = useState<string>('Manutenção anual');
  const [price, setPrice] = useState<string>('0');
  const [quantity, setQuantity] = useState<number>(1);
  const [items, setItems] = useState<IRequestItemContract[]>([]);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IRequestItemContract>(
    {} as IRequestItemContract,
  );

  useEffect(() => {
    setItems(contractItems);
  }, [contractItems]);

  const handleChangeStatus = (event: ChangeEvent<{ value: unknown }>): void => {
    setProduct(event.target.value as string);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        setIsLoading(true);
        event.preventDefault();
        const data = {
          product,
          maintenanceDate,
          quantity,
          price: formatPrice(price),
          contractId,
        };

        const schema = Yup.object().shape({
          maintenanceDate: Yup.date().required('Vencimeto é obrigatório'),
          product: Yup.string().required('Item é obrigatório'),
          price: Yup.number().required('Valor do item é obrigatório'),
          quantity: Yup.number().required('Quantidade é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });
        const response = await api.post<IRequestPost>('/contracts-items', data);
        const itemFormated = formatItemContract(response.data.item);
        setItems(oldValue => [...oldValue, itemFormated]);
        enqueueSnackbar('Item adicionado ao contrato com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });

          return;
        }
        enqueueSnackbar(
          'Erro inesperado ao gerar o contrato, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [product, maintenanceDate, quantity, price, contractId, enqueueSnackbar],
  );

  const handleDeleteItem = useCallback(
    async (itemId: string) => {
      try {
        await api.delete(`contracts-items/${itemId}`);
        const updatedList = items.filter(item => item.id !== itemId);
        setItems(updatedList);
        enqueueSnackbar('Item deletado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [enqueueSnackbar, items],
  );

  const handleToggleModal = useCallback(() => {
    setOpenModalConfirm(!openModalConfirm);
  }, [openModalConfirm]);

  const handleSelectItem = useCallback(
    (item: IRequestItemContract) => {
      setSelectedItem(item);
      handleToggleModal();
    },
    [handleToggleModal],
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item md={12}>
            <Card>
              <Box className={classes.boxHeader}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography className={classes.headerTitle}>
                      Novo Item
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={3} sm={12} xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Produto</FormLabel>
                      <RadioGroup
                        aria-label="status"
                        name="status"
                        value={product}
                        onChange={handleChangeStatus}
                      >
                        <FormControlLabel
                          value="Manutenção anual"
                          control={<Radio disabled={!contractActive} />}
                          label="MANUTENÇÃO ANUAL"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Valor"
                      name="name"
                      onChange={event => setPrice(event.target.value)}
                      value={price}
                      disabled={!contractActive}
                      InputProps={{
                        inputComponent: FieldNumberFormatCustom as any,
                      }}
                    />
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <TextField
                      type="number"
                      variant="outlined"
                      fullWidth
                      label="Quantidade"
                      name="quantidade"
                      onChange={event =>
                        setQuantity(Number(event.target.value))
                      }
                      value={quantity}
                      disabled={!contractActive}
                    />
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <KeyboardDatePicker
                        fullWidth
                        autoOk
                        disabled={!contractActive}
                        inputVariant="outlined"
                        format="dd/MM"
                        label="Data de Cobrança"
                        onChange={(date: Date | null) =>
                          setMaintenanceDate(date)
                        }
                        helperText="Data pré-definida para envio de boleto"
                        value={maintenanceDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box className={classes.boxAction}>
                {contractActive && (
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    color="primary"
                    disabled={!contractCreated || isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="primary" />
                    ) : (
                      'Adicionar Item'
                    )}
                  </Button>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>
      <ListItem
        items={items}
        handleDeleteItem={handleDeleteItem}
        handleSelectItem={handleSelectItem}
      />
      <ModalConfirmItemToBill
        open={openModalConfirm}
        handleToggleModal={handleToggleModal}
        selectedItem={selectedItem}
        client={client}
        ccoNumber={ccoNumber}
        deposit={deposit}
      />
    </>
  );
};

export default ItemsForm;
