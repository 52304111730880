import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  LinearProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import IRequestUsers from '../../dtos/IRequestUsers';

const useStyles = makeStyles(() => {
  return {
    input: {
      display: 'none',
    },
    avatar: {
      width: '160px',
      height: '160px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    contentProgress: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '100px',
    },
    progress: {
      width: '50%',
    },
  };
});

interface IRequest {
  user: IRequestUsers;
}

interface IRequestUpdate {
  updatedUser: IRequestUsers;
}
const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [name, setName] = useState<string>('');
  const [avatarUrl, setAvatarUrl] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [inProgressAvatar, setInProgressAvatar] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get<IRequest>(`users/${user.id}`)
      .then(response => {
        setName(response.data.user.name);
        setUserName(response.data.user.login);
        setEmail(response.data.user.email);
        if (response.data.user.avatarUrl) {
          setAvatarUrl(response.data.user.avatarUrl);
        }
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar o seu perfil, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [user, enqueueSnackbar]);

  const handleChangeAvatar = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setInProgressAvatar(true);
      try {
        const data = new FormData();
        if (event.target.files) {
          data.append('avatar', event.target.files[0]);
        }
        const response = await api.patch<IRequest>(`users/avatar`, data);
        updateUser(response.data.user);
        if (response.data.user.avatarUrl) {
          setAvatarUrl(response.data.user.avatarUrl);
        }
      } catch (err) {
        enqueueSnackbar('Erro ao atualizar o seu avatar, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setInProgressAvatar(false);
      }
    },
    [updateUser, enqueueSnackbar],
  );

  const handleUpdateProfile = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();
        setInProgress(true);
        const data = {
          name,
          login: userName,
          ...(email ? { email } : {}),
          ...(password
            ? {
                password,
                confirmPassword,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          login: Yup.string().required('Login é obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string().email('Precisamos de E-mail válido'),
          password: Yup.string(),
          confirmPassword: Yup.string()
            .when('password', {
              is: val => !!val,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password')], 'Confirmação incorreta'),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put<IRequestUpdate>(
          `users/${user.id}`,
          data,
        );
        updateUser(response.data.updatedUser);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setInProgress(false);
      }
    },
    [
      name,
      password,
      userName,
      confirmPassword,
      email,
      updateUser,
      user,
      enqueueSnackbar,
    ],
  );

  return (
    <Container>
      <h1>Configurações</h1>
      {isLoading ? (
        <div className={classes.contentProgress}>
          <LinearProgress className={classes.progress} color="secondary" />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item md={4} sm={12} xs={12}>
            <Paper>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column"
                spacing={2}
              >
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  lg={8}
                  style={{ padding: '15px' }}
                >
                  {inProgressAvatar ? (
                    <CircularProgress size="100px" />
                  ) : (
                    <Avatar className={classes.avatar} src={avatarUrl} />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h3">{user.name}</Typography>
                </Grid>
                <Grid item>
                  <label htmlFor="uploadFile">
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="uploadFile"
                      multiple
                      type="file"
                      onChange={handleChangeAvatar}
                    />
                    <Button color="inherit" variant="text" component="span">
                      Alterar Avatar
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} lg={8} xs={12}>
            <form autoComplete="off" noValidate onSubmit={handleUpdateProfile}>
              <Card>
                <Box className={classes.boxHeader}>
                  <Typography className={classes.headerTitle}>
                    Perfil
                  </Typography>
                </Box>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Nome Completo"
                        required
                        name="name"
                        value={name}
                        onChange={event => setName(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="E-mail"
                        value={email}
                        name="mail"
                        onChange={event => setEmail(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Usuário"
                        required
                        value={userName}
                        name="userName"
                        onChange={event => setUserName(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        type="password"
                        fullWidth
                        label="Senha"
                        required
                        value={password}
                        name="password"
                        onChange={event => setPassword(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="password"
                        label="Confirmação da Senha"
                        required
                        value={confirmPassword}
                        name="confirmPassword"
                        onChange={event =>
                          setConfirmPassword(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box className={classes.boxAction}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    type="submit"
                    disabled={inProgress}
                  >
                    {inProgress ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      'Atualizar Perfil'
                    )}
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Profile;
