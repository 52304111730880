import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { FiArrowDown, FiArrowUp, FiFilter, FiPlusCircle } from 'react-icons/fi';

import IRequestBlocks from '../../dtos/IRequestBlocks';
import IRequestStreets from '../../dtos/IRequestStreets';
import ModalInMapAddBlock from '../../components/ModalInMapAddBlock';
import ModalInMapAddStreet from '../../components/ModalInMapAddStreet';

interface IBlockProps {
  blocks: IRequestBlocks[];
  handleSelectedStreet(street: IRequestStreets): void;
  handleToggleModalFilter(): void;
  handleAddBlockInList: (block: IRequestBlocks) => void;
  handleAddStreetInList: (street: IRequestStreets) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '500px',
      width: '100%',
    },
    nested: {
      paddingLeft: theme.spacing(6),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

const BlockList: React.FC<IBlockProps> = ({
  blocks,
  handleSelectedStreet,
  handleToggleModalFilter,
  handleAddBlockInList,
  handleAddStreetInList,
}) => {
  const [indexActive, setIndexActive] = useState<number | null>(null);
  const [disableFilterButton, setDisableFilterButton] = useState<boolean>(true);
  const [openModalAddBlock, setOpenModalAddBlock] = useState<boolean>(false);
  const [openModalAddStreet, setOpenModalAddStreet] = useState<boolean>(false);
  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );

  const classes = useStyles();

  const handleOpenBlock = useCallback(
    (index: number) => {
      if (index === indexActive) {
        setIndexActive(null);
      } else {
        setIndexActive(index);
      }
    },
    [indexActive],
  );

  const handleSelect = useCallback(
    (street: IRequestStreets) => {
      handleSelectedStreet(street);
      setDisableFilterButton(false);
    },
    [handleSelectedStreet],
  );

  const handleToggleModalAddBlock = useCallback(() => {
    setOpenModalAddBlock(!openModalAddBlock);
  }, [openModalAddBlock]);

  const handleToggleModalAddStreet = useCallback(() => {
    setOpenModalAddStreet(!openModalAddStreet);
  }, [openModalAddStreet]);

  const handleSelectedBlock = useCallback(
    (block: IRequestBlocks) => {
      setBlockSelected(block);
      handleToggleModalAddStreet();
    },
    [handleToggleModalAddStreet],
  );

  return (
    <>
      <ModalInMapAddBlock
        handleToggleModal={handleToggleModalAddBlock}
        handleAddBlockInList={handleAddBlockInList}
        open={openModalAddBlock}
      />
      <ModalInMapAddStreet
        blockSelected={blockSelected}
        handleToggleModal={handleToggleModalAddStreet}
        handleAddStreetInList={handleAddStreetInList}
        open={openModalAddStreet}
      />
      <Paper variant="outlined" style={{ height: '87vh', padding: 0 }}>
        <Box style={{ padding: '16px' }}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="h6">Quadras</Typography>
            </Grid>
            <Grid item>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<FiFilter className={classes.extendedIcon} />}
                onClick={() => handleToggleModalFilter()}
                disabled={disableFilterButton}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="fullWidth" />
        <List className={classes.root}>
          {blocks.map((block, index) => (
            <li key={block.id}>
              <ListItem button onClick={() => handleOpenBlock(index)}>
                <ListItemText
                  primary={
                    <>
                      {` ${block.name}
                  ${
                    block.streets.length === 0
                      ? ' (Nenhuma Rua encontrada)'
                      : ''
                  }`}
                    </>
                  }
                />
                {index === indexActive ? (
                  <FiArrowUp size={22} />
                ) : (
                  <FiArrowDown size={22} />
                )}
              </ListItem>
              <Collapse timeout="auto" in={index === indexActive}>
                <List component="div" disablePadding>
                  {block.streets.map(street => (
                    <ListItem
                      className={classes.nested}
                      button
                      onClick={() => handleSelect(street)}
                    >
                      <ListItemText secondary={street.name} />
                    </ListItem>
                  ))}
                  <ListItem
                    className={classes.nested}
                    button
                    onClick={() => handleSelectedBlock(block)}
                  >
                    <FiPlusCircle size={22} />
                    <ListItemText
                      secondary="Nova rua"
                      style={{ paddingLeft: '1em' }}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </li>
          ))}
          <ListItem button onClick={handleToggleModalAddBlock}>
            <FiPlusCircle size={22} />
            <ListItemText
              primary="Nova Quadra"
              style={{ paddingLeft: '1em' }}
            />
          </ListItem>
        </List>
      </Paper>
    </>
  );
};

export default BlockList;
