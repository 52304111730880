import React, { useState, useCallback, FormEvent, ChangeEvent } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  IconButton,
  makeStyles,
  createStyles,
  Grid,
  TextField,
  Button,
  Theme,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  AppBar,
  Toolbar,
  Typography,
  DialogContent,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';

import api from '../../services/api';
import IRequestBlocks from '../../dtos/IRequestBlocks';
import IRequestStreets from '../../dtos/IRequestStreets';

interface IPropsModal {
  handleToggleModal: () => void;
  handleAddStreetInList: (street: IRequestStreets) => void;
  blockSelected: IRequestBlocks;
  open: boolean;
}

interface APIRequestStreet {
  street: IRequestStreets;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const ModalInMapAddStreet: React.FC<IPropsModal> = ({
  handleToggleModal,
  handleAddStreetInList,
  blockSelected,
  open,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [generateAutomaticName, setGenerateAutomaticName] = useState<boolean>(
    true,
  );

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          ...(generateAutomaticName
            ? { generateAutomaticName }
            : { name: name.toUpperCase() }),
          blockId: blockSelected.id,
          ...(note
            ? {
                note,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          generateAutomaticName: Yup.boolean(),
          name: Yup.string().when('generateAutomaticName', {
            is: val => !val,
            then: Yup.string().required('Nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          blockId: Yup.string().required('Quadra é obrigatório'),
          note: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        const responsePost = await api.post<APIRequestStreet>('streets', data);
        const response = await api.get(
          `streets/${responsePost.data.street.id}`,
        );
        handleAddStreetInList(response.data.street);
        handleToggleModal();
        enqueueSnackbar('Rua adicionada com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      generateAutomaticName,
      name,
      blockSelected,
      note,
      handleAddStreetInList,
      handleToggleModal,
      enqueueSnackbar,
    ],
  );

  const handleChangeGenerateName = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setGenerateAutomaticName(event.target.checked);
  };

  return (
    <>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={handleToggleModal}>
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={handleToggleModal}
            >
              <FiXCircle />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Nova Rua
            </Typography>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              color="primary"
              onClick={handleSubmit}
            >
              {isLoading ? <CircularProgress color="primary" /> : 'Salvar'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Nome"
                  required
                  name="name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                  disabled={generateAutomaticName}
                  helperText={
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedB"
                          color="primary"
                          onChange={handleChangeGenerateName}
                          value={generateAutomaticName}
                          checked={generateAutomaticName}
                        />
                      }
                      label="Gerar nome automático"
                    />
                  }
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Quadra"
                  required
                  name="quadra"
                  value={blockSelected.name}
                  disabled
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Observações"
                  name="note"
                  value={note}
                  onChange={event => setNote(event.target.value)}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalInMapAddStreet;
