import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import {
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { FiPrinter, FiXCircle } from 'react-icons/fi';

import IRequestContracts from '../../dtos/IRequestContracts';

import formatBase64ToBlob from '../../utils/FormatBase64ToBlob';
import api from '../../services/api';
import IRequestDrawers from '../../dtos/IRequestDrawers';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IPropsContractItem {
  contract: IRequestContracts;
  handleToggleModal: () => void;
  open: boolean;
}

interface APIRequestPDF {
  base64PDF: string;
}

interface APIRequestDrawers {
  total: number;
  drawers: IRequestDrawers[];
}

const ModalContractDepositItem: React.FC<IPropsContractItem> = ({
  contract,
  open,
  handleToggleModal,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [listDrawers, setListDrawers] = useState<IRequestDrawers[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if ('id' in contract) {
      setIsLoading(true);
      api
        .get<APIRequestDrawers>(`drawers/${contract.depositId}/deposit`, {
          params: { page: 1, pageSize: 50 },
        })
        .then(response => {
          const { drawers } = response.data;
          setListDrawers(drawers);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [enqueueSnackbar, contract]);

  const handleDecodeAndPrint = useCallback(
    async (id: string) => {
      try {
        const response = await api.get<APIRequestPDF>(`/contracts/pdf/${id}`);

        const fileUrl = URL.createObjectURL(
          formatBase64ToBlob(response.data.base64PDF),
        );
        window.open(fileUrl);
      } catch (err) {
        enqueueSnackbar(
          'Erro ao imprimir contrato, verificar dados do cliente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      }
    },
    [enqueueSnackbar],
  );
  const handleDecodeAndPrintDrawer = useCallback(
    async (id: string, drawerId: string) => {
      try {
        const response = await api.get<APIRequestPDF>(
          `/contracts/pdf/${id}/drawer/${drawerId}`,
        );

        const fileUrl = URL.createObjectURL(
          formatBase64ToBlob(response.data.base64PDF),
        );
        window.open(fileUrl);
      } catch (err) {
        enqueueSnackbar(
          'Erro ao imprimir contrato, verificar dados do cliente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      }
    },
    [enqueueSnackbar],
  );

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Gerar Contrato</DialogTitle>
        <IconButton onClick={handleToggleModal}>
          <FiXCircle />
        </IconButton>
      </header>

      <DialogContent dividers>
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <List>
            <ListItem>
              <ListItemText primary="Contrato de Jazigo Perpétuo" />
              <Tooltip title="Imprimir Contrato">
                <IconButton onClick={() => handleDecodeAndPrint(contract.id)}>
                  <FiPrinter />
                </IconButton>
              </Tooltip>
            </ListItem>
            {listDrawers.map(item => {
              return (
                <ListItem>
                  <ListItemText primary={`Contrato da Gaveta ${item.name}`} />
                  <Tooltip title="Imprimir Contrato">
                    <IconButton
                      onClick={() =>
                        handleDecodeAndPrintDrawer(contract.id, item.id)
                      }
                    >
                      <FiPrinter />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalContractDepositItem;
