import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import IRequestRolesActions from '../../../dtos/IRequestRolesActions';
import ModalAddRoleAction from '../../../components/ModalAddRoleAction';
import api from '../../../services/api';
import ModalEditRoleAction from '../../../components/ModalEditRoleAction';
import NoDataContent from '../../../components/NoDataContent';

interface IProps {
  listActions: IRequestRolesActions[];
}

interface IParams {
  id: string;
}

const useStyles = makeStyles(() => {
  return {
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const ListActions: React.FC<IProps> = ({ listActions }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<IParams>();
  const [toogleModalAdd, setToogleModalAdd] = useState<boolean>(false);
  const [toogleModalEdit, setToogleModalEdit] = useState<boolean>(false);
  const [actions, setActions] = useState<IRequestRolesActions[]>([]);
  const [actionEditing, setActionEditing] = useState<IRequestRolesActions>(
    {} as IRequestRolesActions,
  );

  useEffect(() => {
    if (listActions) {
      setActions(listActions);
    }
  }, [listActions]);

  const handleToogleModalAdd = useCallback(() => {
    setToogleModalAdd(!toogleModalAdd);
  }, [toogleModalAdd]);

  const handleToogleModalEdit = useCallback(() => {
    setToogleModalEdit(!toogleModalEdit);
  }, [toogleModalEdit]);

  const handleOpenEdit = useCallback((action: IRequestRolesActions) => {
    setToogleModalEdit(true);
    setActionEditing(action);
  }, []);

  const handleAddNewItemInList = useCallback((action: IRequestRolesActions) => {
    setActions(oldValue => [...oldValue, action]);
  }, []);

  const handleUpdateItemInList = useCallback(
    (newAction: IRequestRolesActions) => {
      const updatedList = actions.filter(action => action.id !== newAction.id);
      setActions([...updatedList, newAction]);
    },
    [actions],
  );

  const handleDeleteAction = useCallback(
    async (actionId: string) => {
      try {
        await api.delete(`roles-actions/${actionId}`);
        const updatedList = actions.filter(action => action.id !== actionId);
        setActions(updatedList);
        enqueueSnackbar('Ação deletada com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [actions, enqueueSnackbar],
  );

  return (
    <Container>
      <ModalAddRoleAction
        open={toogleModalAdd}
        handleToogleModal={handleToogleModalAdd}
        addNewItemInList={handleAddNewItemInList}
        roleId={id}
      />
      <ModalEditRoleAction
        open={toogleModalEdit}
        handleToogleModal={handleToogleModalEdit}
        action={actionEditing}
        handleUpdateItemInList={handleUpdateItemInList}
      />
      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <Typography variant="h3" style={{ fontWeight: 'bold' }}>
            Ações
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            style={{ fontWeight: 700 }}
            startIcon={<FiPlusCircle />}
            onClick={handleToogleModalAdd}
          >
            Nova Ação
          </Button>
        </Grid>
      </Grid>
      {actions.length > 0 ? (
        <List>
          {actions.map(action => (
            <Paper
              variant="outlined"
              elevation={0}
              style={{ marginTop: '20px' }}
              key={action.id}
            >
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h6">{`Apelido: ${action.nickname}`}</Typography>
                  }
                  secondary={
                    <Typography variant="body1">
                      {`Rota: ${action.route}`}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleOpenEdit(action)}>
                    <FiEdit className={classes.editButton} />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteAction(action.id)}
                  >
                    <FiTrash2 className={classes.trashButton} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Paper>
          ))}
        </List>
      ) : (
        <NoDataContent title="Esse cargo não possui açẽos" />
      )}
    </Container>
  );
};

export default ListActions;
