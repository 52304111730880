import React from 'react';
import { Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

import { FiInbox } from 'react-icons/fi';
import IRequestDeposits from '../../dtos/IRequestDeposits';

interface IDepositProps {
  deposit: IRequestDeposits;
}

type TTextStatus = {
  [key: string]: string;
};

function getIconStatus(status: string): string {
  const depositState: TTextStatus = {
    NOTBUILT: '#5850EC',
    SOLD: '#22863a',
    INACTIVE: '#C70039',
    NOTSOLD: '#f39c12',
  };

  return depositState[status];
}

function getTextStatus(status: string): string {
  const depositState: TTextStatus = {
    NOTSOLD: 'NV',
    SOLD: 'VE',
    INACTIVE: 'IN',
    NOTBUILT: 'NC',
  };

  return depositState[status];
}

type Props = {
  color: string;
};

const useStyles = makeStyles<Theme, Props>(() => {
  return {
    link: {
      textDecoration: 'none',
    },
    cardAnimate: {
      padding: '8px',
      color: '#FFF',
      backgroundColor: props => props.color,
      '&:hover': {
        backgroundColor: props => `${shade(0.2, `${props.color}`)}`,
      },
    },
    textPrimary: {
      fontWeight: 'bold',
    },
  };
});

const DepositCard: React.FC<IDepositProps> = ({ deposit }) => {
  const classes = useStyles({ color: getIconStatus(deposit.status) });
  return (
    <Link className={classes.link} to={`/form/deposits/${deposit.id}`}>
      <Paper variant="outlined" className={classes.cardAnimate}>
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="column"
        >
          <Grid item>
            <span className={classes.textPrimary}>{deposit.name}</span>
          </Grid>
          <Grid item>
            {deposit.isOpen && (
              <span className={classes.textPrimary}>
                <FiInbox />
              </span>
            )}
            <span>{getTextStatus(deposit.status)}</span>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  );
};

export default DepositCard;
