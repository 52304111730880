import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle } from '@material-ui/lab';
import TabPanel from '../../../components/TabPanel';
import api from '../../../services/api';
import IRequestRoles from '../../../dtos/IRequestRoles';
import ListActions from '../ListActions';
import IRequestRolesActions from '../../../dtos/IRequestRolesActions';

const useStyles = makeStyles(() => {
  return {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
  };
});

interface IParams {
  id: string;
}

interface IRequestUpdate {
  role: IRequestRoles;
}

const RoleForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<IParams>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [value, setValue] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [listActions, setListActions] = useState<IRequestRolesActions[]>([]);
  const [badgeActions, setBadgeActions] = useState<number>(0);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      api.get<IRequestUpdate>(`roles/${id}`).then(response => {
        setName(response.data.role.name);
        setActive(response.data.role.active);
        if (response.data.role.roleActions) {
          setListActions(response.data.role.roleActions);
          setBadgeActions(response.data.role.roleActions.length);
        }
      });
    }
  }, [id]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  const handleSubmitRole = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          name: name.toUpperCase(),
        };
        const schema = Yup.object().shape({
          name: Yup.string()
            .uppercase('Nome deve estar em maiúsculo.')
            .required('Nome é obrigatório.'),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post('roles', data);
        history.push('/roles');
        enqueueSnackbar('Cargo criado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [name, enqueueSnackbar, history],
  );

  const handleUpadateRole = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          name: name.toUpperCase(),
        };
        const schema = Yup.object().shape({
          name: Yup.string()
            .uppercase('Nome deve estar em maiúsculo.')
            .required('Nome é obrigatório.'),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`roles/${id}`, data);
        enqueueSnackbar('Cargo atualizado com sucesso!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [name, enqueueSnackbar, id],
  );

  return (
    <Container>
      <h1>Cargo</h1>
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Geral" className={classes.tabLabel} />
          <Tab
            label={
              <Badge color="secondary" badgeContent={badgeActions} showZero>
                Ações
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!isUpdate}
          />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        {isUpdate && !active && (
          <Box mb="25px">
            <Alert variant="filled" severity="warning">
              <AlertTitle>
                <Typography variant="h3">Aviso</Typography>
              </AlertTitle>
              <Typography variant="body1">
                Esse cargo está desativado.
              </Typography>
            </Alert>
          </Box>
        )}
        <form
          onSubmit={isUpdate ? handleUpadateRole : handleSubmitRole}
          autoComplete="off"
          noValidate
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                value={name}
                onChange={event => setName(event.target.value.toUpperCase())}
                helperText="O nome do cargo deve começar com 'API_'"
                required
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  `${isUpdate ? 'Atualizar Cargo' : 'Novo Cargo'}`
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListActions listActions={listActions} />
      </TabPanel>
    </Container>
  );
};

export default RoleForm;
