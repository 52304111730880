import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';

interface User {
  name: string;
  id: string;
  email: string;
  avatarUrl?: string;
  role: {
    id: string;
    name: string;
  };
}

interface SignInCredentials {
  login: string;
  password: string;
}

interface AuthContextState {
  user: User;
  signIn(crendentials: SignInCredentials): Promise<void>;
  singOut(): void;
  updateUser(user: User): void;
}

interface AuthState {
  token: string;
  user: User;
  refreshToken: string;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Parque:token');
    const refreshToken = localStorage.getItem('@Parque:refreshToken');
    const user = localStorage.getItem('@Parque:user');
    if (token && user && refreshToken) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user), refreshToken };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ login, password }) => {
    const response = await api.post<AuthState>('authenticate', {
      login,
      password,
    });

    const { token, user, refreshToken } = response.data;

    localStorage.setItem('@Parque:token', token);
    localStorage.setItem('@Parque:user', JSON.stringify(user));
    localStorage.setItem('@Parque:refreshToken', refreshToken);

    api.defaults.headers.authorization = `Bearer ${token}`;
    setData({ token, user, refreshToken });
  }, []);

  const singOut = useCallback(() => {
    localStorage.removeItem('@Parque:token');
    localStorage.removeItem('@Parque:user');
    localStorage.removeItem('@Parque:refreshToken');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        token: data.token,
        refreshToken: data.refreshToken,
        user,
      });
      localStorage.setItem('@Parque:user', JSON.stringify(user));
    },
    [setData, data],
  );
  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, singOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
