import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { ptBR } from 'date-fns/esm/locale';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import IRequestClient from '../../dtos/IRequestClient';
import api from '../../services/api';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
  };
});

interface IRequestClientList {
  clients: IRequestClient[];
}

interface IParmsFormGet {
  expirationDateFilter: Date | null;
  statusPaymentFilter: string;
  clientFilter: IRequestClient;
}

interface IProps {
  handleAddFilter(data: IParmsFormGet): void;
}

const FilterBillList: React.FC<IProps> = ({ handleAddFilter }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [listClients, setListClients] = useState<IRequestClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IRequestClient>(
    {} as IRequestClient,
  );
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string>('');

  useEffect(() => {
    api
      .get<IRequestClientList>('clients-active')
      .then(response => {
        setListClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as clientes, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      handleAddFilter({
        expirationDateFilter: expirationDate || null,
        statusPaymentFilter: paymentStatus || '',
        clientFilter: selectedClient || null,
      });
    },
    [handleAddFilter, expirationDate, paymentStatus, selectedClient],
  );
  return (
    <Card>
      <Box className={classes.boxHeader}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography className={classes.headerTitle}>Filtros</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Autocomplete
                options={listClients}
                value={selectedClient}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, client) => {
                  if (client) {
                    setSelectedClient(client);
                  } else {
                    setSelectedClient({} as IRequestClient);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Cliente"
                  />
                )}
              />
            </Grid>
            <Grid item md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Data de Vencimento"
                  onChange={(date: Date | null) => setExpirationDate(date)}
                  value={expirationDate}
                  name="expirationDate"
                  clearable
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4}>
              <TextField
                variant="outlined"
                label="Estado do boleto"
                fullWidth
                select
                value={paymentStatus}
                onChange={event => setPaymentStatus(event.target.value)}
              >
                <MenuItem value="" selected>
                  Todos
                </MenuItem>
                <MenuItem value="CREATED">Criado</MenuItem>
                <MenuItem value="WAITING">Aguardando</MenuItem>
                <MenuItem value="PAID">Pago</MenuItem>
                <MenuItem value="NOT_PAID">Não Pago</MenuItem>
                <MenuItem value="REVERTED">Revertido</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" type="submit" variant="contained">
            Filtrar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default FilterBillList;
