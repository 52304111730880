import React, { FormEvent, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import api from '../../../services/api';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
  };
});

interface IParams {
  contractId: string;
}
const Cancel: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { contractId } = useParams<IParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          note,
          contractId,
        };
        const schema = Yup.object().shape({
          note: Yup.string().required('Motivo do cancelamento é obrigatório'),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post('contracts-cancel', data);
        history.goBack();
        enqueueSnackbar('Contrato Cancelado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [contractId, note, history, enqueueSnackbar],
  );

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item md={12}>
            <Card>
              <Box className={classes.boxHeader}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography className={classes.headerTitle}>
                      Cancelar Contrato
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={12}
                      label="Atestado de Óbito NRO"
                      required
                      name="nroDeathCertificate"
                      value={note}
                      onChange={event => setNote(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box className={classes.boxAction}>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    'Cancelar Contrato'
                  )}
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Cancel;
