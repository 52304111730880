import { createStyles, Icon, makeStyles, Theme } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { FiFlag, FiHome, FiMap, FiMapPin, FiSquare } from 'react-icons/fi';
import IRequestBlocks from '../../dtos/IRequestBlocks';
import IRequestDefuncts from '../../dtos/IRequestDefuncts';
import IRequestDeposits from '../../dtos/IRequestDeposits';
import IRequestDrawers from '../../dtos/IRequestDrawers';
import IRequestOssuary from '../../dtos/IRequestOssuary';
import IRequestStreets from '../../dtos/IRequestStreets';

interface IProps {
  block?: IRequestBlocks;
  street?: IRequestStreets;
  deposit?: IRequestDeposits;
  drawer?: IRequestDrawers;
  ossuary?: IRequestOssuary;
  defunct?: IRequestDefuncts;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      margin: 0,
      padding: 0,
      display: 'inline-block',
      listStyle: 'none',
      '& li': {
        float: 'left',
        '& a': {
          color: '#FFF',
          display: 'block',
          backgroundColor: theme.palette.primary.main,
          textDecoration: 'none',
          position: 'relative',
          height: '40px',
          lineHeight: '40px',
          padding: '0 10px 0 5px',
          textAlign: 'center',
          marginRight: '23px',

          '&:before, &:after': {
            position: 'absolute',
            content: '""',
            top: 0,
            border: `0 solid ${theme.palette.primary.main}`,
            borderWidth: '20px 10px',
            width: 0,
            height: 0,
          },
          '&:before': {
            left: '-20px',
            borderLeftColor: 'transparent',
          },
          '&:after': {
            left: '100%',
            borderColor: 'transparent',
            borderLeftColor: theme.palette.primary.main,
          },
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            '&:before': {
              borderColor: theme.palette.secondary.main,
              borderLeftColor: 'transparent',
            },
            '&:after': {
              borderLeftColor: theme.palette.secondary.main,
            },
          },
          '&:active': {
            backgroundColor: theme.palette.secondary.dark,

            '&:before': {
              borderColor: theme.palette.secondary.dark,
              borderLeftColor: 'transparent',
            },
            '&:after': {
              borderLeftColor: theme.palette.secondary.dark,
            },
          },
        },
        '&:nth-child(even)': {
          '& a': {
            backgroundColor: theme.palette.primary.dark,

            '&:before': {
              borderColor: theme.palette.primary.dark,
              borderLeftColor: 'transparent',
            },
            '&:after': {
              borderLeftColor: theme.palette.primary.dark,
            },
          },
        },
        '&:first-child': {
          '& a': {
            paddingLeft: '15px',
            borderRadius: '4px 0 0 4px',
            '&:before': {
              border: 'none',
            },
          },
        },
        '&:last-child': {
          '& a': {
            paddingRight: '15px',
            borderRadius: '0 4px 4px 0',
            '&:after': {
              border: 'none',
            },
          },
        },
      },
    },
  }),
);

const StyledBreadcrumb: React.FC<IProps> = ({
  block,
  street,
  deposit,
  defunct,
}) => {
  const classes = useStyles();
  const [blockSelected, setBlockSelected] = useState({} as IRequestBlocks);
  const [streetSelected, setStreetSelected] = useState({} as IRequestStreets);
  const [depositSelected, setDepositSelected] = useState(
    {} as IRequestDeposits,
  );
  const [drawerSelected, setDrawerSelected] = useState({} as IRequestDrawers);
  const [defunctSelected, setDefunctSelected] = useState(
    {} as IRequestDefuncts,
  );
  const [ossuarySelected, setOssuarySelected] = useState({} as IRequestOssuary);

  useEffect(() => {
    if (block) {
      setBlockSelected(block);
    }
    if (street) {
      setStreetSelected(street);
      if (street.block) {
        setBlockSelected(street.block);
      }
    }
    if (deposit) {
      setDepositSelected(deposit);
      if (deposit.street) {
        setStreetSelected(deposit.street);
        setBlockSelected(deposit.street.block);
      }
    }
    if (defunct) {
      setDefunctSelected(defunct);
      if (defunct.drawer) {
        setDrawerSelected(defunct.drawer);
        setDepositSelected(defunct.drawer.deposit);
        setStreetSelected(defunct.drawer.deposit.street);
        setBlockSelected(defunct.drawer.deposit.street.block);
      }
      if (defunct.ossuary) {
        setOssuarySelected(defunct.ossuary);
        setDepositSelected(defunct.ossuary.deposit);
        setStreetSelected(defunct.ossuary.deposit.street);
        setBlockSelected(defunct.ossuary.deposit.street.block);
      }
    }
  }, [block, street, deposit, defunct]);

  return (
    <ul className={classes.breadcrumb}>
      <li>
        <a href="/map">
          <Icon component="span">
            <FiMap size={14} />
          </Icon>
        </a>
      </li>
      {'id' in blockSelected && (
        <li>
          <a href={`/form/blocks/${blockSelected.id}`}>
            <Icon component="span">
              <FiMapPin size={14} />
            </Icon>
            {` ${blockSelected.name}`}
          </a>
        </li>
      )}
      {'id' in streetSelected && (
        <li>
          <a href={`/form/streets/${streetSelected.id}`}>
            <Icon component="span">
              <FiSquare size={14} />
            </Icon>
            {` ${streetSelected.name}`}
          </a>
        </li>
      )}
      {'id' in depositSelected && (
        <li>
          <a href={`/form/deposits/${depositSelected.id}`}>
            <Icon component="span">
              <FiFlag size={14} />
            </Icon>
            {` ${depositSelected.name}`}
          </a>
        </li>
      )}
      {'id' in drawerSelected && (
        <li>
          <a href={`/form/deposits/${drawerSelected.deposit.id}`}>
            <Icon component="span">
              <FiFlag size={14} />
            </Icon>
            {` ${drawerSelected.name}`}
          </a>
        </li>
      )}
      {'id' in ossuarySelected && (
        <li>
          <a href={`/form/deposits/${ossuarySelected.deposit.id}`}>
            <Icon component="span">
              <FiFlag size={14} />
            </Icon>
            {` Ossuário`}
          </a>
        </li>
      )}
      {'id' in defunctSelected && (
        <li>
          <a href={`/form/defuncts/${defunctSelected.id}`}>
            <Icon component="span">
              <FiHome size={14} />
            </Icon>
            {` ${defunctSelected.name}`}
          </a>
        </li>
      )}
    </ul>
  );
};

export default StyledBreadcrumb;
