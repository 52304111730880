import {
  Box,
  Card,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import { format, parseISO } from 'date-fns';

import React, { useCallback, useEffect, useState } from 'react';
import { FiDownload, FiPlusCircle } from 'react-icons/fi';
import ModalAddDefunctDocs from '../../../components/ModalAddDefunctDocs';
import IRequestDefunctDocs from '../../../dtos/IRequestDefunctDocs';
import api from '../../../services/api';
import LocaleText from '../../../LocaleText/index.json';

const formatDefunctDocs = (doc: IRequestDefunctDocs): IRequestDefunctDocs => {
  return {
    ...doc,
    formatedCreatedAt: format(parseISO(doc.createdAt), 'dd/MM/yyyy'),
  };
};
const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
  };
});

interface IProps {
  defunctId: string;
}

interface IRequest {
  total: number;
  defunctDocs: IRequestDefunctDocs[];
}

const DocsDetail: React.FC<IProps> = ({ defunctId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [listDocs, setListDocs] = useState<IRequestDefunctDocs[]>([]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toogleModalAdd, setToogleModalAdd] = useState<boolean>(false);

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    (async () => {
      api
        .get<IRequest>('defuncts-docs', {
          params: {
            defunctId,
            page: page + 1,
            pageSize,
          },
        })
        .then(response => {
          const { total, defunctDocs } = response.data;
          const docsFormatted = defunctDocs.map(doc => formatDefunctDocs(doc));
          if (!active) {
            return;
          }
          setListDocs(docsFormatted);
          setTotalItem(total);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [page, defunctId, enqueueSnackbar, pageSize]);

  const handleToogleModalAdd = useCallback(() => {
    setToogleModalAdd(!toogleModalAdd);
  }, [toogleModalAdd]);

  const handleAddNewItemInList = useCallback((doc: IRequestDefunctDocs) => {
    const docFormatted = formatDefunctDocs(doc);
    setListDocs(oldValue => [...oldValue, docFormatted]);
  }, []);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  const handleDownload = useCallback(
    async (file: string) => {
      try {
        const response = await api.get(`files/${file}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        enqueueSnackbar('Houve um erro ao carregar a lista, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [enqueueSnackbar],
  );

  return (
    <Container>
      <Card>
        <Box className={classes.boxHeader}>
          <ModalAddDefunctDocs
            open={toogleModalAdd}
            handleToogleModal={handleToogleModalAdd}
            addNewItemInList={handleAddNewItemInList}
            defunctId={defunctId}
          />
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Documentos
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Total: {totalItem}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={handleToogleModalAdd}
              >
                Novo Documento
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ width: '100%', marginBottom: 16 }} />
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listDocs as RowModel[]}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Nome',
                    width: 400,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{!params.row.name ? '' : `${params.row.name}`}</>
                    ),
                  },

                  {
                    field: 'date',
                    headerName: 'Criado em',
                    width: 208,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formatedCreatedAt}</>
                    ),
                  },
                  {
                    field: 'author',
                    headerName: 'Autor',
                    width: 208,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{!params.row.user.name ? '' : params.row.user.name}</>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          onClick={() => handleDownload(params.row.file)}
                        >
                          <FiDownload />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

export default DocsDetail;
