import React, {
  FormEvent,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FiX, FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { useParams } from 'react-router-dom';

import IRequestStreets from '../../dtos/IRequestStreets';
import IRequestDeposits from '../../dtos/IRequestDeposits';
import IRequestBlocks from '../../dtos/IRequestBlocks';
import api from '../../services/api';
import formatStreet from '../../utils/formatStreet';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IRequestBlock {
  blocks: IRequestBlocks[];
}

interface IRequest {
  deposit: IRequestDeposits;
  blocks: IRequestBlocks;
}

interface IProps {
  handleToggle: () => void;
  open: boolean;
  handleUpdateItem: (deposit: IRequestDeposits) => void;
  handleUpdateTotalItem: (value: number) => void;
  selectedStreet?: IRequestStreets;
}

interface IParams {
  id: string;
}

const ModalAddDepositLots: React.FC<IProps> = ({
  handleToggle,
  open,
  handleUpdateItem,
  handleUpdateTotalItem,
  selectedStreet,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { id } = useParams<IParams>();
  const [streets, setStreets] = useState<IRequestStreets[]>([]);
  const [streetSelected, setStreetSelected] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );
  const [blocks, setBlocks] = useState<IRequestBlocks[]>([]);
  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );
  const [model, setModel] = React.useState<string>('');
  const [page] = useState<number>(1);
  const [pageSize] = useState<number>(100);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [drawer, setDrawer] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [sector, setSector] = useState<string>('');
  const [status, setStatus] = useState<string>('NOTBUILT');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setModel('');
    setQuantity(0);
    setIsOpen(false);
    if (selectedStreet && selectedStreet.id) {
      setStreetSelected(selectedStreet);
      setBlockSelected(selectedStreet.block);
    } else {
      setBlockSelected({} as IRequestBlocks);
      setStreetSelected({} as IRequestStreets);
    }
  }, [open, selectedStreet]);

  useEffect(() => {
    if ('id' in blockSelected) {
      api
        .get<IRequest>(`blocks/${blockSelected.id}`, {
          params: { page, pageSize },
        })
        .then(response => {
          if (response.data.blocks.streets) {
            const streetsFormatted = response.data.blocks.streets.map(street =>
              formatStreet(street),
            );
            setStreets(streetsFormatted);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [blockSelected, enqueueSnackbar, pageSize, page]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      api
        .get<IRequest>(`deposits/${id}`)
        .then(response => {
          if (response.data.deposit.street) {
            setStreetSelected(response.data.deposit.street);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao buscar o jazigo.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [id, enqueueSnackbar]);

  useEffect(() => {
    api
      .get<IRequestBlock>(`blocks/actives`)
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao buscar o jazigo.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  const handleChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setModel((event.target as HTMLInputElement).value);
  };

  const handleChangeDrawer = (event: ChangeEvent<HTMLInputElement>): void => {
    setDrawer(event.target.checked);
  };

  const handleChangeStatus = (event: ChangeEvent<{ value: unknown }>): void => {
    setStatus(event.target.value as string);
  };

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      enqueueSnackbar(`Deseja realmente cadastrar ${quantity} JAZIGOS ?`, {
        variant: 'info',
        autoHideDuration: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        action: (
          <>
            <Button
              onClick={async () => {
                closeSnackbar();
                try {
                  const data = {
                    streetId: streetSelected.id,
                    hasOssuary: drawer,
                    model,
                    quantity,
                    isOpen,
                    status,
                    sector,
                  };

                  const schema = Yup.object().shape({
                    quantity: Yup.string().required(
                      'Quantidade de jazigos é obrigatório',
                    ),
                    streetId: Yup.string().required('Rua é obrigatório'),
                    model: Yup.string().required(
                      'Modelo do jazigo é obrigatório',
                    ),
                    hasOssuary: Yup.boolean().required(
                      'Caixa ossuária é obrigatório',
                    ),
                    isOpen: Yup.boolean().required(
                      'Jazigo es´ta aberto é obrigatório',
                    ),
                    status: Yup.string().required(
                      'Status do jazigo é obrigatório',
                    ),
                    sector: Yup.string().required(
                      'Setor do jazigo é obrigatório',
                    ),
                  });
                  await schema.validate(data, { abortEarly: false });
                  const response = await api.post(
                    '/deposits/create-lote',
                    data,
                  );
                  const responseArray = response.data.deposits;
                  responseArray.forEach((value: IRequestDeposits) => {
                    handleUpdateItem(value);
                  });
                  handleUpdateTotalItem(response.data.deposits.length);
                  handleToggle();
                  enqueueSnackbar('Jazigo adicionado com sucesso.', {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  });
                } catch (error) {
                  if (error instanceof Yup.ValidationError) {
                    error.inner.forEach(err => {
                      enqueueSnackbar(err.message, {
                        variant: 'error',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                      });
                    });
                    return;
                  }
                  enqueueSnackbar('Erro inesperado ao cadastrar o jazigo.', {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Sim
            </Button>
            <Button
              onClick={() => {
                setIsLoading(false);
                closeSnackbar();
              }}
            >
              Não
            </Button>
          </>
        ),
      });
    },
    [
      enqueueSnackbar,
      quantity,
      closeSnackbar,
      streetSelected.id,
      drawer,
      model,
      isOpen,
      status,
      sector,
      handleUpdateTotalItem,
      handleToggle,
      handleUpdateItem,
    ],
  );

  return (
    <Dialog open={open} maxWidth="md" onClose={() => handleToggle()}>
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">
          Adicionar Jazigo em Lotes
        </DialogTitle>
        <IconButton onClick={() => handleToggle()}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6} sm={6}>
              <Autocomplete
                options={blocks}
                value={blockSelected}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                disabled={!!selectedStreet}
                onChange={(event, optionClient) => {
                  if (optionClient) {
                    setBlockSelected(optionClient);
                    setStreetSelected({} as IRequestStreets);
                  } else {
                    setBlockSelected({} as IRequestBlocks);
                    setStreetSelected({} as IRequestStreets);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Quadras"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhuma quadra ativa, verifique acessando a lista"
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Autocomplete
                options={streets}
                value={streetSelected}
                disabled={!('id' in blockSelected) || !!selectedStreet}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, street) => {
                  if (street) {
                    setStreetSelected(street);
                  } else {
                    setStreetSelected({} as IRequestStreets);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Ruas"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhuma rua referente a esta quadra, selecione outra quadra"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                select
                label="Setor"
                name="sector"
                onChange={event => setSector(event.target.value as string)}
                value={sector}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton onClick={() => setSector('')}>
                          <FiX />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="D">D</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="H">H</MenuItem>
                <MenuItem value="I">I</MenuItem>
                <MenuItem value="J">J</MenuItem>
                <MenuItem value="K">K</MenuItem>
                <MenuItem value="L">L</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                type="number"
                label="Quantidade"
                required
                name="quantity"
                onChange={event => setQuantity(Number(event.target.value))}
                value={quantity}
              />
            </Grid>

            <Grid item md={6} xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={handleChangeDrawer}
                    value={drawer}
                    checked={drawer}
                  />
                }
                label="Caixa Ossuária"
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={event => setIsOpen(event.target.checked)}
                    value={isOpen}
                    checked={isOpen}
                  />
                }
                label="Jazigo Aberto"
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Modelo do Jazigo</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={model}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel
                    value="ADULT"
                    control={<Radio />}
                    label="Adulto"
                  />
                  <FormControlLabel
                    value="DOUBLE"
                    control={<Radio />}
                    label="Duplo"
                  />
                  <FormControlLabel
                    value="AMERICAN"
                    control={<Radio />}
                    label="Americano"
                  />
                  <FormControlLabel
                    value="NOBLE"
                    control={<Radio />}
                    label="Nobre"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl
                component="fieldset"
                disabled={status === 'INACTIVE'}
              >
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  aria-label="status"
                  name="status"
                  value={status}
                  onChange={handleChangeStatus}
                >
                  <FormControlLabel
                    value="NOTBUILT"
                    control={<Radio />}
                    label="Não construído"
                  />
                  <FormControlLabel
                    value="NOTSOLD"
                    control={<Radio />}
                    label="Não Vendido"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={!isUpdate ? 'primary' : 'secondary'}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : 'Adicionar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalAddDepositLots;
