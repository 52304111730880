import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiEdit, FiPlusCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import IRequestDefuncts from '../../dtos/IRequestDefuncts';
import formatDefunct from '../../utils/formatDefunct';
import formatDefunctAction from '../../utils/formatDefunctActions';
import LocaleText from '../../LocaleText/index.json';

interface IProps {
  defuncts: IRequestDefuncts[];
  clientId: string;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    editButton: {
      color: '#22863A',
    },
  };
});

const ListDefunctClients: React.FC<IProps> = ({ defuncts, clientId }) => {
  const classes = useStyles();
  const history = useHistory();

  const [listDefuncts, setListDefuncts] = useState<IRequestDefuncts[]>([]);

  useEffect(() => {
    const defunctsFormatted = defuncts.map(defunct => formatDefunct(defunct));
    setListDefuncts(defunctsFormatted);
  }, [defuncts]);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  const findSepultadoAtDate = (
    defunct: IRequestDefuncts,
  ): string | undefined => {
    const find = defunct.defunctActions.find(
      item => item.status === 'SEPULTAMENTO',
    );
    if (find) {
      return formatDefunctAction(find).formatedDateAction;
    }
    return '';
  };

  const findLocatization = (defunct: IRequestDefuncts): string | undefined => {
    if (defunct.drawer) {
      return `${defunct.drawer.deposit?.street?.block?.name}/${defunct.drawer.deposit?.street?.name}/${defunct.drawer.deposit?.name}/${defunct.drawer?.name} `;
    }

    if (defunct.ossuary) {
      return ` ${defunct.ossuary.deposit?.name}/Ossuario`;
    }

    return '';
  };

  return (
    <>
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>
                Sepultados
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={() =>
                  history.push({
                    pathname: '/form/defuncts',
                    state: { clientId },
                  })
                }
              >
                Novo Sepultado
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              localeText={LocaleText}
              rows={listDefuncts as RowModel[]}
              columns={[
                {
                  field: 'name',
                  headerName: 'Sepultado',
                  flex: 1.5,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>{!params.value ? 'Sem apelido' : params.value}</>
                  ),
                },
                {
                  field: 'localization',
                  headerName: 'Quadra/Rua/Jazigo/Gaveta',
                  flex: 1.5,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div>
                      {findLocatization(params.row as IRequestDefuncts)}
                    </div>
                  ),
                },
                {
                  field: 'sepultadoAt',
                  headerName: 'Sepultado em',
                  flex: 1,
                  align: 'left',
                  renderCell: (params: ValueFormatterParams) => (
                    <div>
                      {!params.row.defunctActions
                        ? ''
                        : `${findSepultadoAtDate(
                            params.row as IRequestDefuncts,
                          )}`}
                    </div>
                  ),
                },
                {
                  field: 'nroDeathCertificate',
                  headerName: 'Nro Obito',
                  flex: 1,
                  align: 'left',
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 150,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/form/defuncts/${params.row.id.toString()}`,
                          )
                        }
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              pageSize={10}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListDefunctClients;
