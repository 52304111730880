import React, { useCallback, useEffect, useState, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Avatar,
  Button,
  CardHeader,
  Container,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Box,
} from '@material-ui/core';
import { Autocomplete, Alert, AlertTitle } from '@material-ui/lab';
import { FiAward } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import IRequestRoles from '../../../dtos/IRequestRoles';
import api from '../../../services/api';
import IRequestUsers from '../../../dtos/IRequestUsers';

interface IRequest {
  roles: IRequestRoles[];
}

interface IParams {
  id: string;
}

interface IRequestUpdate {
  user: IRequestUsers;
}

const UserForm: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<IParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [roles, setRoles] = useState<IRequestRoles[]>([]);
  const [roleSelected, setRoleSelected] = useState<IRequestRoles>(
    {} as IRequestRoles,
  );
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    api
      .get<IRequest>('roles/active')
      .then(response => {
        setRoles(response.data.roles);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar os cargos, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      api
        .get<IRequestUpdate>(`users/${id}`)
        .then(response => {
          setName(response.data.user.name);
          setUserName(response.data.user.login);
          setEmail(response.data.user.email);
          setRoleSelected(response.data.user.role);
          setActive(response.data.user.active);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar o usuário, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [id, enqueueSnackbar]);

  const handleUpdate = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();
        setIsLoading(true);
        const data = {
          name: name.toUpperCase(),
          login: userName,
          ...(email ? { email } : {}),
          ...(password
            ? {
                password,
                confirmPassword,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          login: Yup.string().required('Login é obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string().email('Precisamos de E-mail válido'),
          password: Yup.string(),
          confirmPassword: Yup.string()
            .when('password', {
              is: val => !!val,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password')], 'Confirmação incorreta'),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put<IRequestUpdate>(`users/${id}`, data);
        enqueueSnackbar('Usuário criado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [name, password, userName, confirmPassword, email, id, enqueueSnackbar],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name: name.toUpperCase(),
          email,
          login: userName,
          password,
          confirmPassword,
          roleId: roleSelected.id,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string().required('E-mail é obrigatório'),
          login: Yup.string().required('Usuário é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
          confirmPassword: Yup.string().required('Senha é obrigatória'),
          roleId: Yup.string().required('Cargo é obrigtório'),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post('users', data);
        history.push('/users');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      email,
      userName,
      password,
      confirmPassword,
      roleSelected,
      history,
      enqueueSnackbar,
    ],
  );
  return (
    <Container>
      <h1>{isUpdate ? 'Editando Usuário' : 'Novo Usuário'}</h1>
      {isUpdate && !active && (
        <Box mb="10px">
          <Alert variant="filled" severity="warning">
            <AlertTitle>Aviso</AlertTitle>
            <Typography variant="body1">
              Esse usuário está desativado.
            </Typography>
          </Alert>
        </Box>
      )}
      <form
        autoComplete="off"
        noValidate
        onSubmit={isUpdate ? handleUpdate : handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item md={8}>
            <Paper style={{ padding: '20px' }}>
              <Grid container spacing={3}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Nome Completo"
                    required
                    value={name}
                    name="name"
                    onChange={event => setName(event.target.value)}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="E-mail"
                    value={email}
                    name="mail"
                    onChange={event => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Usuário"
                    required
                    value={userName}
                    name="userName"
                    onChange={event =>
                      setUserName(event.target.value.toUpperCase())
                    }
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    label="Senha"
                    required
                    value={password}
                    name="password"
                    onChange={event => setPassword(event.target.value)}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="password"
                    label="Confirmação da Senha"
                    required
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={event => setConfirmPassword(event.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={4}>
            <Paper>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <FiAward />
                  </Avatar>
                }
                title="Cargo do usuário"
                subheader="Selecione o cargo de acesso para o usuário"
              />
              <Grid container spacing={3} style={{ padding: '15px' }}>
                <Grid item md={12} sm={12} xs={12}>
                  <Autocomplete
                    options={roles}
                    value={roleSelected}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    style={{ width: '100%' }}
                    onChange={(event, role) => {
                      if (role) {
                        setRoleSelected(role);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        label="Cargo"
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <div style={{ marginTop: '20px' }}>
          <Button
            variant="contained"
            color={isUpdate ? 'secondary' : 'primary'}
            disableElevation
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="primary" />
            ) : (
              `${isUpdate ? 'Atualizar Usuário' : 'Novo Usuário'}`
            )}
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default UserForm;
