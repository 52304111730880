import React, { useCallback, useEffect, useState, FormEvent } from 'react';
import * as Yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Badge,
  Button,
  Card,
  Container,
  CircularProgress,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Divider,
  Typography,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';

import { parseISO } from 'date-fns';
import { ptBR } from 'date-fns/esm/locale';
import { FiArrowRight } from 'react-icons/fi';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import TabPanel from '../../../components/TabPanel';
import api from '../../../services/api';
import IRequestDefuncts from '../../../dtos/IRequestDefuncts';
import IRequestDeposits from '../../../dtos/IRequestDeposits';
import ActionDetail from './ActionDetail';
import DocsDetail from './DocsDetail';
import IRequestClient from '../../../dtos/IRequestClient';
import useQuery from '../../../hooks/useQuery';
import IRequestDrawers from '../../../dtos/IRequestDrawers';
import StyledBreadcrumb from '../../../components/StyledBreadcrumb';
import { cpfMask } from '../../../utils/cpfCnpjMask';

const useStyles = makeStyles(() => {
  return {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
  };
});

interface IParams {
  id: string;
}

interface ILocation {
  clientId?: string;
  depositId?: string;
  drawerId?: string;
}

interface IRequest {
  defunct: IRequestDefuncts;
}
interface IRequestDepositsList {
  deposits: IRequestDeposits[];
}
interface IRequestClientList {
  clients: IRequestClient[];
}
interface IRequestDrawerList {
  drawers: IRequestDrawers[];
}

interface IApiRequestClient {
  client: IRequestClient;
}

interface IApiRequestDeposit {
  deposit: IRequestDeposits;
}
interface IApiRequestDrawer {
  drawer: IRequestDrawers;
}

const FormDefunct: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const location = useLocation<ILocation>();

  const { id } = useParams<IParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [tabValue, setTabValue] = useState<number>(() => {
    const tabIndex = query.get('tabIndex');
    if (tabIndex) {
      return Number(tabIndex);
    }
    return 0;
  });

  const [originalData, setOriginalData] = useState<IRequestDefuncts>(
    {} as IRequestDefuncts,
  );
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [nroDeathCertificate, setNroDeathCertificate] = useState<string>('');
  const [dateBirth, setDateBirth] = useState<Date | null>(null);
  const [dateDeath, setDateDeath] = useState<Date | null>(null);
  const [registry, setRegistry] = useState<string>('');
  const [funerary, setFunerary] = useState<string>('');
  const [localization, setLocalization] = useState<string>('drawer');
  const [listDeposits, setListDeposits] = useState<IRequestDeposits[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<IRequestDeposits>(
    {} as IRequestDeposits,
  );
  const [listDrawers, setListDrawers] = useState<IRequestDrawers[]>([]);
  const [selectedDrawer, setSelectedDrawer] = useState<IRequestDrawers>(
    {} as IRequestDrawers,
  );
  const [listClients, setListClients] = useState<IRequestClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IRequestClient>(
    {} as IRequestClient,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setTabValue(newValue);
    query.set('tabIndex', String(newValue));
  };

  useEffect(() => {
    if (location.state && location.state.clientId) {
      api
        .get<IApiRequestClient>(`clients/${location.state.clientId}`)
        .then(response => {
          setSelectedClient(response.data.client);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar o cliente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
    if (location.state && location.state.depositId) {
      api
        .get<IApiRequestDeposit>(`deposits/${location.state.depositId}`)
        .then(response => {
          setSelectedDeposit(response.data.deposit);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar o Jazigo.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
    if (location.state && location.state.drawerId) {
      api
        .get<IApiRequestDrawer>(`drawers/${location.state.drawerId}`)
        .then(response => {
          setSelectedDrawer(response.data.drawer);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar o Gaveta.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [enqueueSnackbar, location, dateBirth, dateDeath]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      setIsUpdate(true);
      api
        .get<IRequest>(`defuncts/${id}`)
        .then(response => {
          setOriginalData(response.data.defunct);
          setName(response.data.defunct.name);
          setCpf(
            response.data.defunct.cpf === null
              ? '000.000.000-00'
              : response.data.defunct.cpf,
          );
          setDateBirth(parseISO(response.data.defunct.dateBirth));
          setDateDeath(parseISO(response.data.defunct.dateDeath));
          setRegistry(response.data.defunct.registry);
          setFunerary(response.data.defunct.funerary);
          setNroDeathCertificate(response.data.defunct.nroDeathCertificate);
          setSelectedClient(response.data.defunct.client);
          if (response.data.defunct.drawer) {
            setSelectedDeposit(response.data.defunct.drawer.deposit);
            setSelectedDrawer(response.data.defunct.drawer);
            setLocalization('drawer');
          }
          if (response.data.defunct.ossuary) {
            setSelectedDeposit(response.data.defunct.ossuary.deposit);
            setLocalization('ossuary');
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar o sepultado, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, enqueueSnackbar]);

  useEffect(() => {
    api
      .get<IRequestClientList>('clients-active')
      .then(response => {
        setListClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as clientes, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if ('id' in selectedClient) {
      api
        .get<IRequestDepositsList>(`deposits/${selectedClient.id}/clients`)
        .then(response => {
          setListDeposits(response.data.deposits);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar os jazigos, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [selectedClient, enqueueSnackbar]);

  useEffect(() => {
    if ('id' in selectedDeposit) {
      api
        .get<IRequestDrawerList>(`drawers/${selectedDeposit.id}/deposit`, {
          params: { page: 1 },
        })
        .then(response => {
          setListDrawers(response.data.drawers);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar os jazigos, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [selectedDeposit, enqueueSnackbar]);

  const handleTransfer = useCallback(async () => {
    try {
      await api.delete<IRequest>(`defuncts/transfer/${id}`);
      history.push('/defuncts');
      enqueueSnackbar('Sepultado transferido com sucesso.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
      }
    }
  }, [enqueueSnackbar, history, id]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoadingButton(true);
      try {
        const data = {
          name: name.toUpperCase(),
          cpf,
          nroDeathCertificate,
          registry,
          funerary,
          dateBirth,
          dateDeath,
          drawerId: selectedDrawer.id,
          clientId: selectedClient.id,
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do sepultado é obrigatório'),
          cpf: Yup.string().required('CPF é obrigatório'),
          nroDeathCertificate: Yup.string().required(
            'Atestado de Óbito NRO é obrigatório',
          ),
          registry: Yup.string().required('Registro é obrigatório'),
          funerary: Yup.string().required('Funerária é obrigatório'),
          dateBirth: Yup.string().required('Data de nascimento é obrigatório'),
          dateDeath: Yup.string().required('Data de falecimento é obrigatório'),
          drawerId: Yup.string().required('Jazigo é obrigatório'),
          clientId: Yup.string().required('Cliente é obrigatório'),
        });
        await schema.validate(data, { abortEarly: false });

        await api.post('defuncts', data);
        history.push('/defuncts');
        enqueueSnackbar('Sepultado adicionado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoadingButton(false);
      }
    },
    [
      name,
      cpf,
      nroDeathCertificate,
      registry,
      funerary,
      dateBirth,
      dateDeath,
      selectedDrawer.id,
      selectedClient.id,
      history,
      enqueueSnackbar,
    ],
  );

  const handleUpdate = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoadingButton(true);
        const data = {
          name,
          cpf,
          nroDeathCertificate,
          registry,
          funerary,
          dateBirth,
          dateDeath,
          clientId: selectedClient.id,
          ...(localization === 'drawer'
            ? {
                drawerId: selectedDrawer.id,
              }
            : {}),
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do sepultado é obrigatório'),
          cpf: Yup.string().required('CPF é obrigatório'),
          nroDeathCertificate: Yup.string().required(
            'Atestado de Óbito NRO é obrigatório',
          ),
          registry: Yup.string().required('Registro é obrigatório'),
          funerary: Yup.string().required('Funerária é obrigatório'),
          dateBirth: Yup.string().required('Data de nascimento é obrigatório'),
          dateDeath: Yup.string().required('Data de falecimento é obrigatório'),
          clientId: Yup.string().required('Cliente é obrigatório'),
          drawerId: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });
        await api.put(`defuncts/${id}`, data);

        if (localization === 'ossuary') {
          handleTransfer();
        }

        history.push('/defuncts');
        enqueueSnackbar('Sepultado atualizado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao editar o sepultado.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoadingButton(false);
      }
    },
    [
      name,
      cpf,
      nroDeathCertificate,
      registry,
      funerary,
      dateBirth,
      dateDeath,
      selectedClient.id,
      localization,
      selectedDrawer.id,
      id,
      history,
      enqueueSnackbar,
      handleTransfer,
    ],
  );

  const handleApplyCPFMask = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      setCpf(cpfMask(event.target.value as string));
    },
    [],
  );

  return (
    <Container>
      <StyledBreadcrumb defunct={originalData} />
      <h1>{isUpdate ? 'Atualizar Sepultado' : 'Novo Sepultado'}</h1>
      <div className={classes.root}>
        <Tabs
          onChange={handleChange}
          value={tabValue}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Geral" className={classes.tabLabel} />
          <Tab
            label={
              <Badge color="secondary" showZero>
                Ações
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!isUpdate}
          />
          <Tab
            label={
              <Badge color="secondary" showZero>
                Documentos
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!isUpdate}
          />
        </Tabs>
      </div>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <Box className={classes.boxHeader}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography className={classes.headerTitle}>
                      Sepultado
                    </Typography>
                  </Grid>
                  <Grid item>
                    {selectedClient.id && (
                      <Button
                        color="primary"
                        variant="outlined"
                        disableElevation
                        style={{ fontWeight: 700, marginLeft: '10px' }}
                        endIcon={<FiArrowRight />}
                        onClick={() =>
                          history.push(`/form/client/${selectedClient.id}`)
                        }
                      >
                        Acessar Perfil do cliente
                      </Button>
                    )}
                    {selectedDeposit.id && (
                      <Button
                        color="secondary"
                        variant="outlined"
                        disableElevation
                        style={{ fontWeight: 700, marginLeft: '10px' }}
                        endIcon={<FiArrowRight />}
                        onClick={() =>
                          history.push(`/form/deposits/${selectedDeposit.id}`)
                        }
                      >
                        Acessar Jazigo
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              {isLoading ? (
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>
                    <CircularProgress color="primary" />
                  </Grid>
                </Grid>
              ) : (
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={isUpdate ? handleUpdate : handleSubmit}
                >
                  <Grid container spacing={2} style={{ padding: '20px' }}>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Atestado de Óbito NRO"
                        required
                        name="nroDeathCertificate"
                        onChange={event =>
                          setNroDeathCertificate(event.target.value)
                        }
                        value={nroDeathCertificate}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Autocomplete
                        options={listClients}
                        value={selectedClient}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        style={{ width: '100%' }}
                        onChange={(event, client) => {
                          if (client) {
                            setSelectedClient(client);
                            setSelectedDeposit({} as IRequestDeposits);
                          } else {
                            setSelectedClient({} as IRequestClient);
                            setSelectedDeposit({} as IRequestDeposits);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label="Cliente"
                            required
                            helperText={
                              <Typography>
                                Novo Cliente,{' '}
                                <Link to="/form/clients" target="_blank">
                                  Criar
                                </Link>
                              </Typography>
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Nome"
                        required
                        name="name"
                        onChange={event =>
                          setName(event.target.value.toUpperCase())
                        }
                        value={name}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="CPF"
                        required
                        name="cpf"
                        onChange={event => handleApplyCPFMask(event)}
                        value={cpf}
                      />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Registro"
                        required
                        name="registry"
                        onChange={event => setRegistry(event.target.value)}
                        value={registry}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Funerária"
                        required
                        name="funerary"
                        onChange={event => setFunerary(event.target.value)}
                        value={funerary}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          disableFuture
                          variant="inline"
                          inputVariant="outlined"
                          InputAdornmentProps={{ position: 'end' }}
                          format="dd/MM/yyyy"
                          label="Data de Nascimento"
                          maxDate={dateDeath}
                          required
                          name="dateBirth"
                          onChange={(date: Date | null) => setDateBirth(date)}
                          value={dateBirth}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          disableFuture
                          variant="inline"
                          inputVariant="outlined"
                          InputAdornmentProps={{ position: 'end' }}
                          format="dd/MM/yyyy"
                          label="Data de Falecimento"
                          required
                          name="dateDeath"
                          onChange={(date: Date | null) => setDateDeath(date)}
                          value={dateDeath}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Autocomplete
                        options={listDeposits}
                        value={selectedDeposit}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        style={{ width: '100%' }}
                        disabled={!('id' in selectedClient)}
                        onChange={(event, deposit) => {
                          if (deposit) {
                            setSelectedDeposit(deposit);
                            setSelectedDrawer({} as IRequestDrawers);
                          } else {
                            setSelectedDeposit({} as IRequestDeposits);
                            setSelectedDrawer({} as IRequestDrawers);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label="Jazigo"
                            required
                            helperText={
                              <Typography>
                                Novo Jazigo,{' '}
                                <Link to="/form/deposits" target="_blank">
                                  Criar
                                </Link>
                              </Typography>
                            }
                          />
                        )}
                        noOptionsText="Não encontramos nenhum Jazigo ativa, verifique acessando a lista"
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      {localization === 'drawer' && (
                        <Autocomplete
                          options={listDrawers}
                          value={selectedDrawer}
                          getOptionLabel={option => option.name}
                          getOptionSelected={(option, value) =>
                            option.name === value.name
                          }
                          style={{ width: '100%' }}
                          disabled={!('id' in selectedDeposit)}
                          onChange={(event, drawer) => {
                            if (drawer) {
                              setSelectedDrawer(drawer);
                            } else {
                              setSelectedDrawer({} as IRequestDrawers);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              label="Gaveta"
                              required
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Situação</FormLabel>
                        <RadioGroup
                          value={localization}
                          onChange={event =>
                            setLocalization(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value="drawer"
                            control={<Radio />}
                            label="Gaveta"
                          />
                          <FormControlLabel
                            value="ossuary"
                            control={<Radio />}
                            label="Ossuário"
                            disabled={!isUpdate}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box className={classes.boxAction}>
                    <Button
                      variant="contained"
                      disableElevation
                      color={isUpdate ? 'secondary' : 'primary'}
                      type="submit"
                      disabled={isLoadingButton}
                    >
                      {isLoadingButton ? (
                        <CircularProgress color="primary" />
                      ) : (
                        `${isUpdate ? 'Atualizar Sepultado' : 'Novo Sepultado'}`
                      )}
                    </Button>
                  </Box>
                </form>
              )}
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ActionDetail defunctId={id} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <DocsDetail defunctId={id} />
      </TabPanel>
    </Container>
  );
};

export default FormDefunct;
