import React from 'react';

import {
  FiUsers,
  FiGlobe,
  FiMap,
  FiMapPin,
  FiAward,
  FiFlag,
  FiSquare,
  FiDollarSign,
  FiShoppingCart,
} from 'react-icons/fi';

interface ISubItem {
  id: number;
  name: string;
  roles: string[];
  icon: JSX.Element;
  route: string;
}

interface IMenuItem {
  id: number;
  name: string;
  subItems: ISubItem[];
  roles: string[];
}

const menuItem: IMenuItem[] = [
  {
    id: 1,
    name: 'Principal',
    roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
    subItems: [
      {
        id: 2,
        name: 'Dashboard',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiGlobe size={20} />,
        route: '/dashboard',
      },
    ],
  },
  {
    id: 3,
    name: 'Estrutura',
    roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
    subItems: [
      {
        id: 4,
        name: 'Quadras',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiMapPin size={20} />,
        route: '/blocks',
      },
      {
        id: 5,
        name: 'Ruas',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiSquare size={20} />,
        route: '/streets',
      },
      {
        id: 6,
        name: 'Jazigos',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiFlag size={20} />,
        route: '/deposits',
      },
      {
        id: 7,
        name: 'Mapa',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiMap size={20} />,
        route: '/map',
      },
    ],
  },
  {
    id: 9,
    name: 'Clientes',
    roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
    subItems: [
      {
        id: 10,
        name: 'Lista Clientes',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiUsers size={20} />,
        route: '/clients',
      },
    ],
  },
  {
    id: 11,
    name: 'Sepultados',
    roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
    subItems: [
      {
        id: 12,
        name: 'Lista Sepultados',
        roles: ['API_MASTER', 'API_ADMIN', 'API_USER'],
        icon: <FiUsers size={20} />,
        route: '/defuncts',
      },
    ],
  },
  {
    id: 16,
    name: 'Financeiro',
    roles: ['API_MASTER', 'API_ADMIN'],
    subItems: [
      {
        id: 17,
        name: 'Contas',
        roles: ['API_MASTER', 'API_ADMIN'],
        icon: <FiDollarSign size={20} />,
        route: '/bills',
      },
      {
        id: 18,
        name: 'Gerar Manutenção',
        roles: ['API_MASTER', 'API_ADMIN'],
        icon: <FiShoppingCart size={20} />,
        route: '/maintenance',
      },
    ],
  },
  {
    id: 13,
    name: 'Configurações',
    roles: ['API_MASTER', 'API_ADMIN'],
    subItems: [
      {
        id: 14,
        name: 'Usuários',
        roles: ['API_MASTER', 'API_ADMIN'],
        icon: <FiUsers size={20} />,
        route: '/users',
      },
      {
        id: 15,
        name: 'Cargos',
        roles: ['API_MASTER'],
        icon: <FiAward size={20} />,
        route: '/roles',
      },
    ],
  },
];

export default menuItem;
