import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Divider,
  Card,
  Badge,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Theme,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import { FiCheckCircle, FiEdit, FiTrash2, FiPlusCircle } from 'react-icons/fi';

import api from '../../../services/api';
import IRequestBlocks from '../../../dtos/IRequestBlocks';
import TabPanel from '../../../components/TabPanel';
import IRequestStreets from '../../../dtos/IRequestStreets';
import formatStreet from '../../../utils/formatStreet';
import LocaleText from '../../../LocaleText/index.json';
import StyledBreadcrumb from '../../../components/StyledBreadcrumb';
import useQuery from '../../../hooks/useQuery';

interface IParams {
  id: string;
}

interface IRequest {
  blocks: IRequestBlocks;
}

interface IRequestStatus {
  street: IRequestStreets;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const BlockForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const query = useQuery();
  const { id } = useParams<IParams>();
  const classes = useStyles();
  const [originalData, setOriginalData] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );

  const [tabValue, setTabValue] = useState<number>(() => {
    const tabIndex = query.get('tabIndex');
    if (tabIndex) {
      return Number(tabIndex);
    }
    return 0;
  });
  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [listStreets, setListStreets] = useState<IRequestStreets[]>([]);

  const [badgeStreet, setBadgeStreet] = useState<number>(0);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      api
        .get<IRequest>(`blocks/${id}`)
        .then(response => {
          setOriginalData(response.data.blocks);
          setName(response.data.blocks.name);
          if (response.data.blocks.note) {
            setNote(response.data.blocks.note);
          }

          if (response.data.blocks.streets) {
            const streetsFormatted = response.data.blocks.streets.map(street =>
              formatStreet(street),
            );
            setListStreets(streetsFormatted);
            setBadgeStreet(response.data.blocks.streets.length);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao buscar a quadra.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [id, enqueueSnackbar]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name: name.toUpperCase(),
          ...(note
            ? {
                note,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da quadra é obrigatório'),
          note: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post('blocks', data);
        history.push('/blocks');
        enqueueSnackbar('Quadra adicionada com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [name, note, enqueueSnackbar, history],
  );

  const handleUpdate = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name: name.toUpperCase(),
          ...(note
            ? {
                note,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da quadra é obrigatório'),
          note: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`blocks/${id}`, data);
        enqueueSnackbar('Quadra atualizada com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [name, note, enqueueSnackbar, id],
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setTabValue(newValue);
    query.set('tabIndex', String(newValue));
  };

  const handleUpdateStatusStreet = useCallback(
    async (streetId: string) => {
      try {
        const response = await api.patch<IRequestStatus>(
          `streets/${streetId}/status`,
        );
        const updatedList = listStreets.filter(
          street => street.id !== streetId,
        );
        const streetFormatted = formatStreet(response.data.street);
        setListStreets([...updatedList, streetFormatted]);
      } catch (error) {
        enqueueSnackbar('Erro ao atualizar a rua.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [listStreets, enqueueSnackbar],
  );

  // FIX: Bug AutoHeight DataGrid
  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div')!;
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  return (
    <Container>
      <StyledBreadcrumb block={originalData} />
      <h1>{isUpdate ? 'Atualizando Quadra' : 'Nova Quadra'}</h1>
      <div className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Geral" className={classes.tabLabel} />
          <Tab
            label={
              <Badge color="secondary" badgeContent={badgeStreet} showZero>
                Ruas
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!isUpdate}
          />
        </Tabs>
      </div>
      <TabPanel value={tabValue} index={0}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={isUpdate ? handleUpdate : handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} sm={12}>
              <Paper style={{ padding: '20px' }}>
                <Grid container spacing={3}>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Nome"
                      required
                      name="name"
                      value={name}
                      onChange={event =>
                        setName(event.target.value.toUpperCase())
                      }
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      multiline
                      rows="6"
                      variant="outlined"
                      fullWidth
                      label="Observações"
                      name="note"
                      value={note}
                      onChange={event => setNote(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <div style={{ marginTop: '20px' }}>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                `${isUpdate ? 'Atualizar Quadra' : 'Adicionar Quadra'}`
              )}
            </Button>
          </div>
        </form>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Card>
          <>
            <Box className={classes.boxHeader}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.headerTitle}>Ruas</Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    style={{ fontWeight: 700 }}
                    startIcon={<FiPlusCircle />}
                    onClick={() =>
                      history.push({
                        pathname: '/form/streets',
                        state: { blockId: id },
                      })
                    }
                  >
                    Nova Rua
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider style={{ width: '100%', marginBottom: 16 }} />

            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                pageSize={10}
                autoHeight
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listStreets as RowModel[]}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Nome',
                    width: 345,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.value ? 'Sem apelido' : params.value}
                      </div>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <Link to={`/form/streets/${params.row.id.toString()}`}>
                          <IconButton>
                            <FiEdit className={classes.editButton} />
                          </IconButton>
                        </Link>
                        <IconButton
                          onClick={() =>
                            handleUpdateStatusStreet(params.row.id.toString())
                          }
                        >
                          {params.row.active ? (
                            <FiTrash2 className={classes.trashButton} />
                          ) : (
                            <FiCheckCircle className={classes.iconActive} />
                          )}
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        </Card>
      </TabPanel>
    </Container>
  );
};

export default BlockForm;
