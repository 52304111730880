import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiCheckCircle, FiEdit, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import IRequestStreets from '../../../dtos/IRequestStreets';
import api from '../../../services/api';
import LocaleText from '../../../LocaleText/index.json';

const formatStreet = (street: IRequestStreets): IRequestStreets => {
  return {
    ...street,
    formatedCreatedAt: format(parseISO(street.createdAt), 'dd/MM/yyyy'),
  };
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

interface IRequest {
  total: number;
  streets: IRequestStreets[];
}

interface IRequestStatus {
  street: IRequestStreets;
}

const StreetList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const [listStreets, setListStreets] = useState<IRequestStreets[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    (async () => {
      api
        .get<IRequest>('streets', { params: { page: page + 1, pageSize } })
        .then(response => {
          const { total, streets } = response.data;
          const streetsFormatted = streets.map(street => formatStreet(street));
          if (!active) {
            return;
          }
          setTotalItem(total);
          setListStreets(streetsFormatted);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, enqueueSnackbar]);

  const handleUpdateStatusStreet = useCallback(
    async (id: string) => {
      try {
        const response = await api.patch<IRequestStatus>(
          `streets/${id}/status`,
        );
        const updatedList = listStreets.filter(street => street.id !== id);
        const streetFormatted = formatStreet(response.data.street);
        setListStreets([...updatedList, streetFormatted]);
      } catch (error) {
        enqueueSnackbar('Erro ao atualizar a rua.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [listStreets, enqueueSnackbar],
  );

  return (
    <Container>
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Todas Ruas
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Total: {totalItem}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={() => history.push('/form/streets')}
              >
                Nova Rua
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ width: '100%', marginBottom: 16 }} />
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listStreets as RowModel[]}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Nome',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.value ? 'Sem apelido' : params.value}
                      </div>
                    ),
                  },
                  {
                    field: 'Block',
                    headerName: 'Quadra',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.block.name
                          ? 'Sem apelido'
                          : params.row.block.name}
                      </div>
                    ),
                  },
                  {
                    field: 'DepositsLength',
                    headerName: 'Qtd de Jazigos',
                    flex: 0.5,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        {!params.row.deposits
                          ? '0'
                          : params.row.deposits.length}
                      </>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    width: 150,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/form/streets/${params.row.id.toString()}`,
                            )
                          }
                        >
                          <FiEdit className={classes.editButton} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleUpdateStatusStreet(params.row.id.toString())
                          }
                        >
                          {params.row.active ? (
                            <FiTrash2 className={classes.trashButton} />
                          ) : (
                            <FiCheckCircle className={classes.iconActive} />
                          )}
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

export default StreetList;
