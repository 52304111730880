import React from 'react';
import { ReactElement } from 'react';
import { FiDollarSign, FiInbox, FiShoppingBag, FiX } from 'react-icons/fi';
import { IconType } from 'react-icons/lib';

type TIconsStatus = {
  [key: string]: ReactElement<IconType>;
};

export function getIconStatus(status: string): ReactElement<IconType> {
  const depositState: TIconsStatus = {
    INACTIVE: <FiX style={{ color: '#C70039' }} size={22} />,
    NOTBUILT: <FiInbox style={{ color: '#5850EC' }} size={22} />,
    NOTSOLD: <FiShoppingBag style={{ color: '#22863a' }} size={22} />,
    SOLD: <FiDollarSign style={{ color: '#22863a' }} size={22} />,
  };

  return depositState[status];
}

type TTextString = {
  [key: string]: string;
};

export function getTextStatus(status: string): string {
  const depositState: TTextString = {
    INACTIVE: 'Inativo',
    NOTBUILT: 'Não construído',
    NOTSOLD: 'Não vendido',
    SOLD: 'Vendido',
  };

  return depositState[status];
}

export function getTextModel(model: string): string {
  const depositModel: TTextString = {
    ADULT: 'Adulto',
    DOUBLE: 'Duplo',
    AMERICAN: 'Americano',
    NOBLE: 'Nobre',
  };

  return depositModel[model];
}
