import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';

import notFoundSvg from '../../assets/notFound.svg';

const NotFound: React.FC = () => {
  return (
    <Container>
      <Grid container alignItems="center" justify="center" direction="column">
        <Grid item>
          <img src={notFoundSvg} alt="404" width="450" />
        </Grid>
        <Grid item>
          <Typography variant="h5">Página não encontrada</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
