import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/esm/locale';
import { parseISO } from 'date-fns';
import TabPanel from '../../../components/TabPanel';
import api from '../../../services/api';
import IRequestClient from '../../../dtos/IRequestClient';
import ListClientPhones from '../../../components/ListClientPhones';
import IRequestPhone from '../../../dtos/IRequestPhone';
import IRequestAddress from '../../../dtos/IRequestAddress';
import ListClientAddress from '../../../components/ListClientAddress';
import IRequestContact from '../../../dtos/IRequestContact';
import ListClientContacts from '../../../components/ListClientContacts';
import ListClientHistoric from '../../../components/ListClientHistoric/Index';
import IRequestHistoric from '../../../dtos/IRequestHistoric';
import ListDepositClients from '../../../components/ListDepositClients';
import IRequestDeposits from '../../../dtos/IRequestDeposits';
import ListDefunctClients from '../../../components/ListDefunctClients';
import IRequestDefuncts from '../../../dtos/IRequestDefuncts';
import { cnpjMask, cpfMask, rgMask } from '../../../utils/cpfCnpjMask';
import ListClientContract from '../../../components/ListClientContracts';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
  };
});

interface IParams {
  id: string;
}

interface IRequest {
  client: IRequestClient;
}

const ClientFormEdit: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<IParams>();

  const [value, setValue] = useState<number>(0);
  const [phones, setPhones] = useState<IRequestPhone[]>([]);
  const [addresses, setAddresses] = useState<IRequestAddress[]>([]);
  const [contacts, setContacts] = useState<IRequestContact[]>([]);
  const [historics, setHistorics] = useState<IRequestHistoric[]>([]);
  const [deposits, setDeposits] = useState<IRequestDeposits[]>([]);
  const [defuncts, setDefuncts] = useState<IRequestDefuncts[]>([]);
  const [accessCode, setAccessCode] = useState<string>('');
  const [hasUpdatePassword, setHasUpdatePassword] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [typeSubscription, setTypeSubscription] = useState<string>('FISICA');
  const [document, setDocument] = useState<string>('');
  const [rg, setRg] = useState<string>('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      api
        .get<IRequest>(`clients/${id}`)
        .then(response => {
          setName(response.data.client.name);
          setEmail(response.data.client.email);
          setBirthDate(parseISO(response.data.client.birthDate));
          setAccessCode(response.data.client.accessCode);
          setTypeSubscription(response.data.client.typeSubscription);
          setHasUpdatePassword(response.data.client.hasUpdatePassword);
          if (
            response.data.client.typeSubscription === 'FISICA' &&
            response.data.client.cpf
          ) {
            setDocument(response.data.client.cpf);
            if (response.data.client.rg) {
              setRg(response.data.client.rg);
            }
          }
          if (
            response.data.client.typeSubscription === 'JURIDICA' &&
            response.data.client.cnpj
          ) {
            setDocument(response.data.client.cnpj);
          }
          if (response.data.client.phones) {
            setPhones(
              response.data.client.phones.sort((a, b) => {
                if (a.nickname > b.nickname) {
                  return 1;
                }
                if (a.nickname < b.nickname) {
                  return -1;
                }
                return 0;
              }),
            );
          }
          if (response.data.client.addresses) {
            setAddresses(
              response.data.client.addresses.sort((a, b) => {
                if (a.nickname > b.nickname) {
                  return 1;
                }
                if (a.nickname < b.nickname) {
                  return -1;
                }
                return 0;
              }),
            );
          }

          if (response.data.client.contacts) {
            setContacts(response.data.client.contacts);
          }

          if (response.data.client.historic) {
            setHistorics(response.data.client.historic);
          }

          if (response.data.client.deposits) {
            setDeposits(
              response.data.client.deposits.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              }),
            );
          }

          if (response.data.client.defuncts) {
            setDefuncts(
              response.data.client.defuncts.sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              }),
            );
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao cadastrar o cliente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  }, [id, enqueueSnackbar]);

  const handleChangeSubscription = (
    event: ChangeEvent<{ value: unknown }>,
  ): void => {
    setTypeSubscription(event.target.value as string);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name,
          ...(email
            ? {
                email,
              }
            : {}),
          typeSubscription,
          ...(typeSubscription === 'FISICA'
            ? {
                cpf: document,
                rg,
              }
            : {
                cnpj: document,
              }),
          birthDate,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string().email('Precisamos de E-mail válido'),
          birthDate: Yup.string().required('Data de nascimento é obrigatório'),
          typeSubscription: Yup.string().required(
            'Tipo da inscrição é obrigatório',
          ),
          cpf: Yup.string().when('typeSubscription', {
            is: val => val === 'FISICA',
            then: Yup.string().required('CPF é obrigatório'),
            otherwise: Yup.string(),
          }),
          rg: Yup.string().when('typeSubscription', {
            is: val => val === 'FISICA',
            then: Yup.string().required('RG é obrigatório'),
            otherwise: Yup.string(),
          }),
          cnpj: Yup.string().when('typeSubscription', {
            is: val => val === 'JURIDICA',
            then: Yup.string().required('CNPJ é obrigatório'),
            otherwise: Yup.string(),
          }),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put<IRequest>(`/clients/${id}`, data);
        enqueueSnackbar('Dados atualizados com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao cadastrar o cliente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      email,
      typeSubscription,
      document,
      rg,
      birthDate,
      id,
      enqueueSnackbar,
    ],
  );

  const handleApplyMask = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      if (typeSubscription === 'FISICA') {
        setDocument(cpfMask(event.target.value as string));
      } else {
        setDocument(cnpjMask(event.target.value as string));
      }
    },
    [typeSubscription],
  );

  const handleApplyRgMask = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      setRg(rgMask(event.target.value as string));
    },
    [],
  );

  return (
    <Container>
      <h2>Atualizando Cliente</h2>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
      >
        <Tab label="Geral" className={classes.tabLabel} />
        <Tab label="Endereço" className={classes.tabLabel} />
        <Tab label="Telefone" className={classes.tabLabel} />
        <Tab label="Contatos" className={classes.tabLabel} />
        <Tab label="Histórico" className={classes.tabLabel} />
        <Tab label="Contratos" className={classes.tabLabel} />
      </Tabs>
      {isLoading ? (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item>
            <CircularProgress color="primary" style={{ marginTop: '45px' }} />
          </Grid>
        </Grid>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Card>
                <Box className={classes.boxHeader}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography className={classes.headerTitle}>
                        Geral
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.headerTitle}>
                        {!hasUpdatePassword &&
                          ` Código de acesso: ${accessCode}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <CardContent>
                  <Grid container justify="center" alignItems="center">
                    <Grid item md={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={4} xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Nome Completo"
                            required
                            name="name"
                            value={name}
                            onChange={event =>
                              setName(event.target.value.toUpperCase())
                            }
                          />
                        </Grid>
                        <Grid item md={6} sm={4} xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Email"
                            name="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={6}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptBR}
                          >
                            <KeyboardDatePicker
                              fullWidth
                              autoOk
                              disableFuture
                              variant="inline"
                              inputVariant="outlined"
                              InputAdornmentProps={{ position: 'end' }}
                              format="dd/MM/yyyy"
                              label="Data de Nascimento"
                              required
                              name="birthDate"
                              onChange={(date: Date | null) =>
                                setBirthDate(date)
                              }
                              value={birthDate}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3} sm={4} xs={6}>
                          <FormControl
                            fullWidth
                            required
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel
                              id="subscription-label"
                              className={classes.InputLabel}
                            >
                              Tipo de Inscrição
                            </InputLabel>
                            <Select
                              labelId="subscription-label"
                              id="subscription-select"
                              label="Status"
                              required
                              variant="outlined"
                              value={typeSubscription}
                              onChange={handleChangeSubscription}
                            >
                              <MenuItem value="FISICA" selected>
                                Física
                              </MenuItem>
                              <MenuItem value="JURIDICA">Jurídica</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label={
                              typeSubscription === 'FISICA' ? 'CPF' : 'CNPJ'
                            }
                            required
                            name="document"
                            value={document}
                            onChange={event => handleApplyMask(event)}
                          />
                        </Grid>

                        {typeSubscription === 'FISICA' && (
                          <Grid item md={3} sm={4} xs={6}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label="Rg"
                              required
                              name="document"
                              value={rg}
                              onChange={event => handleApplyRgMask(event)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box className={classes.boxAction}>
                  <Button
                    variant="contained"
                    disableElevation
                    type="submit"
                    color="secondary"
                  >
                    {isLoading ? <CircularProgress /> : 'Editar Cliente'}
                  </Button>
                </Box>
              </Card>
            </form>
            <div style={{ marginTop: '15px' }}>
              <ListDepositClients deposits={deposits} clientName={name} />
            </div>
            <div style={{ marginTop: '15px' }}>
              <ListDefunctClients defuncts={defuncts} clientId={id} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListClientAddress addresses={addresses} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ListClientPhones phones={phones} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ListClientContacts contacts={contacts} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ListClientHistoric historics={historics} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ListClientContract clientId={id} />
          </TabPanel>
        </>
      )}
    </Container>
  );
};

export default ClientFormEdit;
