import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiCheckCircle, FiEdit, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import api from '../../../services/api';
import IRequestRoles from '../../../dtos/IRequestRoles';
import LocaleText from '../../../LocaleText/index.json';

interface IRequest {
  total: number;
  roles: IRequestRoles[];
}

interface IRequestStatus {
  deactivateRole: IRequestRoles;
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});
const formatRole = (role: IRequestRoles): IRequestRoles => {
  return {
    ...role,
    formatedCreatedAt: format(parseISO(role.createdAt), 'dd/MM/yyyy'),
  };
};

const RoleList: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [listRoles, setListRoles] = useState<IRequestRoles[]>([]);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    (async () => {
      api
        .get<IRequest>('roles', {
          params: {
            page: page + 1,
            pageSize,
          },
        })
        .then(response => {
          const { roles, total } = response.data;
          const rolesFormatted = roles.map(role => formatRole(role));

          if (!active) {
            return;
          }

          setTotalItem(total);
          setListRoles(rolesFormatted);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar a lista, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, enqueueSnackbar]);

  const handleUpdateStatusRole = useCallback(
    async (id: string) => {
      try {
        setInProgress(true);
        const response = await api.patch<IRequestStatus>(`roles/${id}/status`);
        const updatedList = listRoles.filter(user => user.id !== id);
        const roleFormatted = formatRole(response.data.deactivateRole);
        setListRoles([...updatedList, roleFormatted]);
      } catch (error) {
        enqueueSnackbar('Erro ao atualizar a role.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setInProgress(false);
      }
    },
    [listRoles, enqueueSnackbar],
  );

  return (
    <Container>
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Todos Cargos
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Total: {totalItem}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={() => history.push('/form/roles')}
              >
                Novo Cargo
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ width: '100%', marginBottom: 16 }} />

        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listRoles as RowModel[]}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Nome',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{!params.value ? 'Sem apelido' : params.value}</>
                    ),
                  },
                  {
                    field: 'date',
                    headerName: 'Criado em',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formatedCreatedAt}</>
                    ),
                  },
                  {
                    field: 'author',
                    headerName: 'Autor',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.user.name ? '' : params.row.user.name}
                      </div>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    width: 150,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/form/roles/${params.row.id.toString()}`,
                            )
                          }
                          disabled={params.row.name === 'API_MASTER'}
                        >
                          <FiEdit className={classes.editButton} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleUpdateStatusRole(params.row.id.toString())
                          }
                          disabled={
                            inProgress || params.row.name === 'API_MASTER'
                          }
                        >
                          {params.row.active ? (
                            <FiTrash2 className={classes.trashButton} />
                          ) : (
                            <FiCheckCircle className={classes.iconActive} />
                          )}
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

export default RoleList;
