import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Chip,
  Container,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { FiArrowRight, FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import api from '../../services/api';

const useStyles = makeStyles((theme: Theme) => {
  return {
    drawer: {
      width: 500,
      marginTop: '40px',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: 300,
      },
    },
  };
});

interface IPropsSearch {
  open: boolean;
  toggle: () => void;
}

interface IClientsRequest {
  id: string;
  name: string;
}

interface IDefunctRequest {
  id: string;
  name: string;
}

interface IRequest {
  clients: IClientsRequest[];
  defuncts: IDefunctRequest[];
}
const ClientSearch: React.FC<IPropsSearch> = ({ open, toggle }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [inputSearch, setInputSearch] = useState<string>('');

  const [resultsClients, setResultsClients] = useState<IClientsRequest[]>([]);
  const [resultsDefuncts, setResultsDefuncts] = useState<IDefunctRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    toggle();
    setResultsClients([]);
    setResultsDefuncts([]);
  }, [toggle]);

  const handleSearchClient = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value.toLocaleUpperCase();
      try {
        setIsLoading(true);
        setInputSearch(query);
        if (query.length >= 3) {
          const response = await api.get<IRequest>('filter-clients/name', {
            params: {
              name: query,
            },
          });
          if (response.data) {
            setResultsDefuncts(response.data.defuncts);
            setResultsClients(response.data.clients);
          }
        } else {
          setResultsClients([]);
          setResultsDefuncts([]);
        }
      } catch {
        enqueueSnackbar(
          'Houve um erro ao carregar o client, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleFullSearch = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keyPress = event.key;
      try {
        setIsLoading(true);
        if (inputSearch.length >= 3) {
          if (keyPress === 'Enter') {
            event.preventDefault();
            history.push({
              pathname: `/results`,
              state: { searchQuery: inputSearch },
            });
            handleClose();
          } else {
            setResultsClients([]);
            setResultsDefuncts([]);
          }
        }
      } catch {
        enqueueSnackbar(
          'Houve um erro ao carregar o client, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [inputSearch, history, handleClose, enqueueSnackbar],
  );

  return (
    <div>
      <Drawer
        elevation={2}
        open={open}
        anchor="right"
        variant="temporary"
        BackdropProps={{ invisible: true }}
      >
        <Container className={classes.drawer}>
          <Grid
            container
            spacing={3}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1">Buscar Cliente/Sepultado</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <FiXCircle />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nome"
                required
                value={inputSearch}
                helperText="Digite no mínimo 3 caracteres/ Aperte 'Enter' para uma busca mais completa"
                onChange={handleSearchClient}
                onKeyPress={handleFullSearch}
              />
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              {resultsClients.length > 0 && (
                <List
                  style={{ width: '100%' }}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Clientes
                    </ListSubheader>
                  }
                >
                  {resultsClients.map(client => (
                    <ListItem
                      key={client.id}
                      button
                      onClick={() => {
                        history.push(`/form/client/${client.id}`);
                        handleClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            {client.name}{' '}
                            <Chip label="Cliente" color="secondary" />
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <FiArrowRight size="24px" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}

              {resultsDefuncts.length > 0 && (
                <List
                  style={{ width: '100%' }}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      Sepultados
                    </ListSubheader>
                  }
                >
                  {resultsDefuncts.map(defunct => (
                    <ListItem
                      key={defunct.id}
                      button
                      onClick={() => {
                        history.push(`/form/defuncts/${defunct.id}`);
                        handleClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <>
                            {defunct.name}{' '}
                            <Chip label="Sepultado" color="primary" />
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <FiArrowRight size="24px" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </Container>
      </Drawer>
    </div>
  );
};

export default ClientSearch;
