import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  FiArchive,
  FiCheck,
  FiClock,
  FiDollarSign,
  FiPlus,
  FiPlusCircle,
  FiPrinter,
  FiX,
} from 'react-icons/fi';
import { FaBarcode } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { SnackbarKey, useSnackbar } from 'notistack';
import IRequestContracts from '../../../dtos/IRequestContracts';
import { IRequestBill, IRequestBillBoleto } from '../../../dtos/IRequestBill';
import { formatBills } from '../../../utils/formatBills';
import api from '../../../services/api';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    actionBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    list: {
      overflow: 'auto',
      maxHeight: 625,
    },
    listText: {
      fontWeight: 700,
    },
  };
});

type TIconsStatus = {
  [key: string]: ReactElement<IconType>;
};

function getIconStatus(status: string): ReactElement<IconType> {
  const billState: TIconsStatus = {
    CREATED: <FiPlus style={{ color: '#5850EC' }} size={24} />,
    WAITING: <FiClock style={{ color: '#5850EC' }} size={24} />,
    PAID: <FiCheck style={{ color: '#22863a' }} size={24} />,
    NOT_PAID: <FiX style={{ color: '#C70039' }} size={24} />,
    REVERTED: <FiArchive style={{ color: '#C70039' }} size={24} />,
  };

  return billState[status];
}

type TTextStatus = {
  [key: string]: string;
};

function getTextStatus(status: string): string {
  const billState: TTextStatus = {
    CREATED: 'Criado',
    WAITING: 'Aguardando',
    PAID: 'Pago',
    NOT_PAID: 'Não pago',
    REVERTED: 'Revertido',
  };

  return billState[status];
}

interface IListBillsProps {
  contracts: IRequestContracts[];
}

interface IAPIBillRequest {
  bill: IRequestBill;
}

interface IAPIBoletoBillRequest {
  billBoleto: IRequestBillBoleto;
}

const ListBillsInDeposit: React.FC<IListBillsProps> = ({ contracts }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [lastbills, setlastBills] = useState<IRequestBill[]>([]);
  const [activeContract, setActiveContract] = useState<IRequestContracts>();

  useEffect(() => {
    const activeItem = contracts.find(contract => contract.active === true);
    if (activeItem) {
      setActiveContract(activeItem);
      const billsFormatted = activeItem.bills
        .map(bill => formatBills(bill))
        .sort((a, b) => {
          return a.expirationDate < b.expirationDate ? 1 : -1;
        });

      setlastBills(billsFormatted);
    }
  }, [contracts]);

  function handleUpdateList(item: IRequestBill): void {
    const updatedOldList = lastbills.filter(olditem => olditem.id !== item.id);

    setlastBills(
      [...updatedOldList, item].sort((a, b) => {
        return a.expirationDate < b.expirationDate ? 1 : -1;
      }),
    );
  }

  async function handlePayBill(
    item: IRequestBill,
    key: SnackbarKey,
  ): Promise<void> {
    try {
      const response = await api.patch<IAPIBillRequest>(`bills/${item.id}/pay`);

      const formattedBill = formatBills(response.data.bill);

      handleUpdateList(formattedBill);
      enqueueSnackbar('Item Atualizado com sucesso.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (err) {
      enqueueSnackbar('Houve um erro ao atualizar o item, tente novamente.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } finally {
      closeSnackbar(key);
    }
  }

  function handlePayBillConfirm(item: IRequestBill): void {
    enqueueSnackbar(
      `Deseja realmente quitar esse item: ${item.items[0].product} ${item.formattedExpirationDate}?`,
      {
        variant: 'info',
        autoHideDuration: null,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },

        action: key => (
          <>
            <Button onClick={() => handlePayBill(item, key)}>Sim</Button>
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Não
            </Button>
          </>
        ),
      },
    );
  }

  async function generateBillBoleto(item: IRequestBill): Promise<void> {
    try {
      const response = await api.post<IAPIBoletoBillRequest>(
        `bills/${item.id}/boleto`,
      );

      const updatedOldList = lastbills.filter(
        olditem => olditem.id !== item.id,
      );

      // eslint-disable-next-line no-param-reassign
      item.billBoleto = response.data.billBoleto;

      setlastBills(
        [...updatedOldList, item].sort((a, b) => {
          return a.expirationDate < b.expirationDate ? 1 : -1;
        }),
      );

      enqueueSnackbar('Boleto Gerado com sucesso.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (err) {
      enqueueSnackbar('Houve um erro ao gerar boleto, tente novamente.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  }

  async function handleCreateNewBill(): Promise<void> {
    try {
      if (activeContract) {
        const { data } = await api.post<IAPIBillRequest>('bills/next', {
          contractId: activeContract.id,
        });
        setlastBills(oldData => [...oldData, formatBills(data.bill)]);
      }
    } catch (error) {
      enqueueSnackbar(
        'Houve um erro ao gerar nova Cobrança, tente novamente.',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
      );
    }
  }

  return (
    <Card>
      <Box className={classes.boxHeader}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.headerTitle}>Cobranças</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider variant="fullWidth" />
      <List className={classes.list}>
        {lastbills.length > 0 ? (
          lastbills.map(item => (
            <ListItem>
              <ListItemAvatar>
                <Tooltip title={getTextStatus(item.status)}>
                  <Icon>{getIconStatus(item.status)}</Icon>
                </Tooltip>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.listText}
                    >
                      {item.items[0].product}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.listText}
                    >
                      {item.formattedExpirationDate}
                    </Typography>
                  </div>
                }
                secondary={<>{item.formattedAmount}</>}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Quitar Cobrança">
                  <IconButton
                    onClick={() => handlePayBillConfirm(item)}
                    disabled={item.status === 'PAID'}
                  >
                    <FiDollarSign />
                  </IconButton>
                </Tooltip>
                {item.billBoleto ? (
                  <Tooltip title="IMPRIMIR BOLETO">
                    <IconButton
                      onClick={() => window.open(item.billBoleto.printHref)}
                    >
                      <FiPrinter />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="GERAR BOLETO ">
                    <IconButton
                      onClick={() => generateBillBoleto(item)}
                      disabled={item.status === 'PAID'}
                    >
                      <FaBarcode />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <Typography variant="body1">Não possui nenhuma Cobrança</Typography>
          </ListItem>
        )}
      </List>
      <Divider variant="fullWidth" />
      <CardActions className={classes.actionBox}>
        <Button
          color="primary"
          variant="contained"
          disabled={contracts.filter(item => item.active).length === 0}
          disableElevation
          style={{ fontWeight: 700 }}
          startIcon={<FiPlusCircle />}
          onClick={handleCreateNewBill}
        >
          Próxima Cobrança
        </Button>
      </CardActions>
    </Card>
  );
};

export default ListBillsInDeposit;
