import React, { useState, useCallback, ChangeEvent } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { shade } from 'polished';
import Axios from 'axios';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  makeStyles,
  createStyles,
  Grid,
  TextField,
  Button,
  Theme,
  CircularProgress,
  Backdrop,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';

import api from '../../services/api';
import IRequestAddress from '../../dtos/IRequestAddress';

interface IPropsModal {
  handleToggleModal: () => void;
  handleUpdateList: (newAddress: IRequestAddress) => void;
  clientId: string;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeIcon: {
      fontSize: '35px',
      color: '#333',
    },
    success: {
      color: '#fff',
      fontWeight: 'bold',
      backgroundColor: `${theme.palette.success.main}`,
      '&:hover': {
        backgroundColor: `${shade(0.2, theme.palette.success.main)}`,
      },
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

interface IRequest {
  address: IRequestAddress;
}
const ModalAddAddress: React.FC<IPropsModal> = ({
  handleToggleModal,
  handleUpdateList,
  open,
  clientId,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [nickname, setNickname] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [district, setDistrict] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [main, setMain] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const toogleModal = useCallback(() => {
    setNickname('');
    setCep('');
    setUf('');
    setCity('');
    setStreet('');
    setDistrict('');
    setNumber('');
    setMain(false);
    handleToggleModal();
  }, [handleToggleModal]);

  const handleAddAddress = useCallback(
    async (event: React.FormEvent): Promise<void> => {
      try {
        event.preventDefault();
        const dataAddress = {
          zipcode: cep,
          state: uf,
          city,
          street,
          district,
          number,
          nickname,
          main,
        };
        const schema = Yup.object().shape({
          zipcode: Yup.string().required('CEP é obrigatório'),
          nickname: Yup.string().required('Descrição é obrigatório'),
          street: Yup.string().required('Endereço é obrigatório'),
          number: Yup.string().required('Número é obrigatório'),
          district: Yup.string().required('Bairro é obrigatório'),
          city: Yup.string().required('Cidade é obrigatório'),
          state: Yup.string().required('Estado é obrigatório'),
          complement: Yup.string(),
          main: Yup.boolean().required('Principal é obrigatório'),
        });
        await schema.validate(dataAddress, { abortEarly: false });
        const response = await api.post<IRequest>(
          `/addresses/${clientId}`,
          dataAddress,
        );
        handleUpdateList(response.data.address);
        enqueueSnackbar(`Endereço adicionado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        toogleModal();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            enqueueSnackbar(`Erro: ${error.message}`, {
              variant: 'error',
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao cadastrar o endereço', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [
      cep,
      uf,
      city,
      street,
      district,
      number,
      nickname,
      main,
      clientId,
      handleUpdateList,
      enqueueSnackbar,
      toogleModal,
    ],
  );

  const handleSearchCep = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
      try {
        setLoading(true);
        event.preventDefault();
        const response = await Axios.get(
          `https://viacep.com.br/ws/${event.target.value}/json/`,
        );
        setCity(response.data?.localidade);
        setUf(response.data?.uf);
        setStreet(response.data?.logradouro);
        setDistrict(response.data?.bairro);
      } catch {
        enqueueSnackbar(`Não encontramos o CEP, tente novamente!`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleChangeMain = (event: ChangeEvent<HTMLInputElement>): void => {
    setMain(event.target.checked);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        onClose={toogleModal}
      >
        <header className={classes.headerModal}>
          <DialogTitle id="form-dialog-title">Novo Endereço</DialogTitle>
          <IconButton onClick={handleToggleModal}>
            <FiXCircle />
          </IconButton>
        </header>
        <form onSubmit={handleAddAddress}>
          <DialogContent dividers>
            {loading && (
              <div className={classes.loading}>
                <Backdrop className={classes.backdrop} open={loading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Descrição"
                  required
                  margin="normal"
                  value={nickname}
                  onChange={event => setNickname(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="CEP"
                  required
                  margin="normal"
                  value={cep}
                  onChange={event => setCep(event.target.value)}
                  onBlur={handleSearchCep}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Estado"
                  required
                  margin="normal"
                  value={uf}
                  onChange={event => setUf(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Cidade"
                  required
                  margin="normal"
                  value={city}
                  onChange={event => setCity(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Bairro"
                  required
                  margin="normal"
                  value={district}
                  onChange={event => setDistrict(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Rua"
                  required
                  margin="normal"
                  value={street}
                  onChange={event => setStreet(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Numero"
                  required
                  margin="normal"
                  value={number}
                  onChange={event => setNumber(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={main}
                      onChange={handleChangeMain}
                      name="main"
                      color="primary"
                    />
                  }
                  label="Tornar esse Endereço como Principal?"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="secondary">
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ModalAddAddress;
