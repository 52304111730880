import { format, parseISO } from 'date-fns';
import IRequestItemContract from '../dtos/IRequestItemContract';
import { formartedPrice } from './formatPriceBeforeSend';

const formatItemContract = (
  item: IRequestItemContract,
): IRequestItemContract => {
  return {
    ...item,
    formatedMaintenanceDate: format(parseISO(item.maintenanceDate), 'dd/MM'),
    formatedCreatedAt: format(parseISO(item.createdAt), 'dd/MM/yyyy'),
    formatedPrice: formartedPrice(item.price),
  };
};

export default formatItemContract;
