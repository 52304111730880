import axios, { AxiosError, AxiosResponse } from 'axios';

import getNewToken from '../utils/refreshToken';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  async (error: AxiosError): Promise<AxiosError | AxiosResponse> => {
    const accessToken = localStorage.getItem('@Parque:token');

    if (error.response?.status === 401 && accessToken) {
      const orginalRequest = error.config;
      getNewToken()
        .then(response => {
          localStorage.setItem('@Parque:token', response);
          api.defaults.headers.authorization = `Bearer ${response}`;
        })
        .then(() => {
          if (error.config.headers) {
            const newToken = localStorage.getItem('@Parque:token');
            orginalRequest.headers.authorization = `Bearer ${newToken}`;
            alert("A página precisa ser atualizada, aperte a tecla 'F5'");
          }
        });
    }
    return Promise.reject(error);
  },
);

export default api;
