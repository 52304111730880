import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';

import IRequestDefuncts from '../../dtos/IRequestDefuncts';
import IRequestDrawers from '../../dtos/IRequestDrawers';
import api from '../../services/api';
import IRequestDeposits from '../../dtos/IRequestDeposits';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IRequestDepositsList {
  deposits: IRequestDeposits[];
}

interface IRequestDrawerList {
  drawers: IRequestDrawers[];
}

interface IProps {
  defunct: IRequestDefuncts;
  handleToggle: () => void;
  open: boolean;
}

const ModalLinkDefunct: React.FC<IProps> = ({
  handleToggle,
  open,
  defunct,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [listDeposits, setListDeposits] = useState<IRequestDeposits[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<IRequestDeposits>(
    {} as IRequestDeposits,
  );

  const [listDrawers, setListDrawers] = useState<IRequestDrawers[]>([]);
  const [selectedDrawer, setSelectedDrawer] = useState<IRequestDrawers>(
    {} as IRequestDrawers,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (defunct.client) {
      api
        .get<IRequestDepositsList>(`deposits/${defunct.client.id}/clients`)
        .then(response => {
          setListDeposits(response.data.deposits);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar os jazigos, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [defunct, enqueueSnackbar]);

  useEffect(() => {
    if ('id' in selectedDeposit) {
      api
        .get<IRequestDrawerList>(`drawers/${selectedDeposit.id}/deposit`, {
          params: { page: 1 },
        })
        .then(response => {
          setListDrawers(response.data.drawers);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as gavetas, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [selectedDeposit, enqueueSnackbar]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent): Promise<void> => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          drawerId: selectedDrawer.id,
        };
        const schema = Yup.object().shape({
          drawerId: Yup.string()
            .required('Gaveta é obrigatório')
            .notOneOf(
              [defunct.drawer?.id],
              'Não é possivel transferir para mesma gaveta',
            ),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post(`defuncts/transfer/${defunct.id}`, data);
        handleToggle();
        enqueueSnackbar('Sepultado transferido com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao cadastrar o jazigo.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [selectedDrawer, defunct, handleToggle, enqueueSnackbar],
  );

  return (
    <Dialog open={open} maxWidth="lg" onClose={() => handleToggle()}>
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Transladar Sepultado </DialogTitle>
        <IconButton onClick={() => handleToggle()}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <DialogContentText>
            O sepultado sera vinculado nesta gaveta.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Autocomplete
                options={listDeposits}
                value={selectedDeposit}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, deposit) => {
                  if (deposit) {
                    setSelectedDeposit(deposit);
                  } else {
                    setSelectedDeposit({} as IRequestDeposits);
                    setSelectedDrawer({} as IRequestDrawers);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Jazigo"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhum Jazigo ativa, verifique acessando a lista"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Autocomplete
                options={listDrawers}
                value={selectedDrawer}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                disabled={!('id' in selectedDeposit)}
                onChange={(event, drawer) => {
                  if (drawer) {
                    setSelectedDrawer(drawer);
                  } else {
                    setSelectedDrawer({} as IRequestDrawers);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Gaveta"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : 'Vincular'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalLinkDefunct;
