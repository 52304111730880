import React from 'react';
import {
  makeStyles,
  createStyles,
  Card,
  Grid,
  Button,
  Box,
  Typography,
  Divider,
  CardContent,
} from '@material-ui/core';
import { FiLayers, FiMap, FiUserPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '13px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '18px',
    },
    shortcutButton: {
      margin: 10,
      padding: 10,
      width: '80%',
    },
  }),
);

const Shortcuts: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div>
      <Card
        variant="outlined"
        style={{
          padding: '0',
        }}
      >
        <Box className={classes.boxHeader}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>Atalhos</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="fullWidth" />
        <CardContent>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => history.push('/form/clients')}
            className={classes.shortcutButton}
            startIcon={<FiUserPlus />}
          >
            Adicionar Cliente
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => history.push('/bills')}
            className={classes.shortcutButton}
            startIcon={<FiLayers />}
          >
            Ver Boletos
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.shortcutButton}
            startIcon={<FiMap />}
            onClick={() => history.push('/map')}
          >
            Abrir Mapa
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Shortcuts;
