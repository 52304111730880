import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';

import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { format, parseISO } from 'date-fns';
import { FiArrowRight, FiChevronDown, FiSearch } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import IRequestDefuncts from '../../../dtos/IRequestDefuncts';
import IRequestClient from '../../../dtos/IRequestClient';

import api from '../../../services/api';

interface IRequest {
  clients: IRequestClient[];
  defuncts: IRequestDefuncts[];
}

// interface IRequestStatus {
//   client: IRequestClient;
// }

const formatDefunct = (defunct: IRequestDefuncts): IRequestDefuncts => {
  return {
    ...defunct,
    formatedCreatedAt: format(parseISO(defunct.createdAt), 'dd/MM/yyyy'),
  };
};

const formatClient = (client: IRequestClient): IRequestClient => {
  return {
    ...client,
    formatedCreatedAt: format(parseISO(client.createdAt), 'dd/MM/yyyy'),
  };
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  };
});

interface ILocation {
  searchQuery?: string;
}

const SearchList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation<ILocation>();

  const [resultsClients, setResultsClients] = useState<IRequestClient[]>([]);
  const [resultsDefuncts, setResultsDefuncts] = useState<IRequestDefuncts[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<string>('');
  // const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  // const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (location.state && location.state.searchQuery) {
      setSearchValue(location.state.searchQuery);
    }
  }, [location, setSearchValue]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      setIsLoading(true);
      api
        .get<IRequest>('filter-clients/name', {
          params: {
            name: searchValue,
          },
        })
        .then(response => {
          setResultsDefuncts(
            response.data.defuncts.map(item => formatDefunct(item)),
          );
          setResultsClients(
            response.data.clients.map(item => formatClient(item)),
          );
        })
        .catch(() => {
          enqueueSnackbar('Erro ao buscar resultados, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [searchValue, enqueueSnackbar]);

  const handleSearchClient = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value.toLocaleUpperCase();
      try {
        setIsLoading(true);
        setSearchValue(query);
        history.replace({
          state: { searchQuery: query },
        });
        if (query.length >= 3) {
          const response = await api.get<IRequest>('filter-clients/name', {
            params: {
              name: query,
            },
          });

          if (response.data) {
            setResultsDefuncts(response.data.defuncts);
            setResultsClients(response.data.clients);
          }
        } else {
          setResultsClients([]);
          setResultsDefuncts([]);
        }
      } catch {
        enqueueSnackbar(
          'Houve um erro ao carregar o client, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, history],
  );

  const handleSelect = (id: string): void => {
    if (selectedIndex === id) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(id);
    }
  };

  const findLocatization = (defunct: IRequestDefuncts): string | undefined => {
    if (defunct.drawer) {
      return ` ${defunct.drawer.deposit?.street?.block?.name}/${defunct.drawer.deposit?.street?.name}/${defunct.drawer.deposit?.name}/${defunct.drawer?.name}`;
    }

    if (defunct.ossuary) {
      return `${defunct.ossuary.deposit?.name}/Ossuario `;
    }

    return '';
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={3} sm={12} xs={12}>
          <h2>Resultados da Busca</h2>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Nome"
            required
            value={searchValue}
            helperText="Digite no mínimo 3 caracteres"
            onChange={handleSearchClient}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>
                    <FiSearch />
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box className={classes.boxHeader}>
              <Grid container spacing={3} justify="space-between">
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                    Clientes
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider style={{ width: '100%', marginBottom: 5 }} />
            {isLoading ? (
              <Grid container spacing={3} alignItems="center" justify="center">
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
              </Grid>
            ) : (
              <>
                {resultsClients.length > 0 && (
                  <List style={{ width: '100%' }} dense disablePadding>
                    {resultsClients.map(client => (
                      <>
                        <ListItem
                          key={client.id}
                          button
                          onClick={() => handleSelect(client.id)}
                          dense
                          divider
                          disableGutters
                        >
                          <Tooltip title="Expandir">
                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]:
                                  selectedIndex === client.id,
                              })}
                              onClick={() => handleSelect(client.id)}
                              aria-expanded={selectedIndex === client.id}
                              aria-label="show more"
                            >
                              <FiChevronDown />
                            </IconButton>
                          </Tooltip>

                          <ListItemText
                            primary={`#${client.clientCod} ${client.name}`}
                            secondary={`Qtd de Jazigos: ${client.deposits?.length} | Qtd de Sepultados: ${client.defuncts?.length} `}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title="Acessar">
                              <IconButton
                                onClick={() => {
                                  history.push(`/form/client/${client.id}`);
                                }}
                              >
                                <FiArrowRight size="24px" />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse
                          in={selectedIndex === client.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List
                            style={{ width: '90%', marginLeft: '10%' }}
                            dense
                            disablePadding
                          >
                            {client.defuncts?.map(defunct => (
                              <ListItem key={defunct.id} dense divider>
                                <ListItemText
                                  primary={`${defunct.name}`}
                                  secondary={`Localização: ${findLocatization(
                                    defunct,
                                  )}`}
                                />
                                <ListItemSecondaryAction>
                                  <Tooltip title="Acessar">
                                    <IconButton
                                      onClick={() => {
                                        history.push(
                                          `/form/defuncts/${defunct.id}`,
                                        );
                                      }}
                                    >
                                      <FiArrowRight size="24px" />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    ))}
                  </List>
                )}
              </>
            )}
          </Card>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Card>
            <Box className={classes.boxHeader}>
              <Grid container spacing={3} justify="space-between">
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                    Sepultados
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider style={{ width: '100%', marginBottom: 8 }} />
            {isLoading ? (
              <Grid container spacing={3} alignItems="center" justify="center">
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
              </Grid>
            ) : (
              <>
                {resultsDefuncts.length > 0 && (
                  <List style={{ width: '100%' }} dense disablePadding>
                    {resultsDefuncts.map(defunct => (
                      <>
                        <ListItem key={defunct.id} dense divider>
                          <ListItemText
                            primary={`${defunct.name} (Cliente: ${defunct.client.name})`}
                            secondary={`Localização: ${findLocatization(
                              defunct,
                            )}`}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title="Acessar">
                              <IconButton
                                onClick={() => {
                                  history.push(`/form/defuncts/${defunct.id}`);
                                }}
                              >
                                <FiArrowRight size="24px" />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </>
                    ))}
                  </List>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SearchList;
