import React, { useCallback, useEffect, useState } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import IRequestHistoric from '../../dtos/IRequestHistoric';
import formatHistoric from '../../utils/formateHistoric';
import ModalAddHistoric from '../ModalAddHistoric';
import api from '../../services/api';
import ModalEditHistoric from '../ModalEditHistoric';

const useStyles = makeStyles((theme: Theme) => ({
  boxAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '7px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  editButton: {
    color: '#22863A',
  },
  trashButton: {
    color: '#87222B',
  },
}));

interface IProps {
  historics: IRequestHistoric[];
}

interface IParams {
  id: string;
}

const ListClientHistoric: React.FC<IProps> = ({ historics }) => {
  const classes = useStyles();
  const { id } = useParams<IParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [isProgressDelete, setIsProgressDelete] = useState<boolean>(false);
  const [listHistoric, setListHistoric] = useState<IRequestHistoric[]>([]);
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [editingHistoric, setEditingHistoric] = useState<IRequestHistoric>(
    {} as IRequestHistoric,
  );

  useEffect(() => {
    const historicsFormatted = historics.map(historic =>
      formatHistoric(historic),
    );

    setListHistoric(historicsFormatted);
  }, [historics]);

  const handleToggleModal = useCallback(() => {
    setOpenModalAdd(!openModalAdd);
  }, [openModalAdd]);

  const handleToggleModalEdit = useCallback(() => {
    setOpenModalEdit(!openModalEdit);
  }, [openModalEdit]);

  const handleUpdateItem = useCallback(
    (updatedHistoric: IRequestHistoric) => {
      const updatedList = listHistoric.filter(
        item => item.id !== updatedHistoric.id,
      );
      const historicsFormatted = formatHistoric(updatedHistoric);

      setListHistoric([...updatedList, historicsFormatted]);
    },
    [listHistoric],
  );

  const handleEditingHistoric = useCallback(
    (historic: IRequestHistoric) => {
      setEditingHistoric(historic);
      handleToggleModalEdit();
    },
    [handleToggleModalEdit],
  );

  const handleDeleteHistoric = useCallback(
    async (historicId: string) => {
      try {
        setIsProgressDelete(true);
        await api.delete(`historic/${historicId}`);
        const updatedList = listHistoric.filter(
          historic => historic.id !== historicId,
        );

        setListHistoric(updatedList);
        enqueueSnackbar('Histórico deletado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsProgressDelete(false);
      }
    },
    [listHistoric, enqueueSnackbar],
  );
  const handleUpdateList = useCallback((newHistoric: IRequestHistoric) => {
    const historicsFormatted = formatHistoric(newHistoric);

    setListHistoric(oldValue => [...oldValue, historicsFormatted]);
  }, []);

  return (
    <div>
      <ModalAddHistoric
        open={openModalAdd}
        handleToggleModal={handleToggleModal}
        clientId={id}
        handleUpdateItem={handleUpdateList}
      />
      <ModalEditHistoric
        open={openModalEdit}
        editingHistoric={editingHistoric}
        handleToggleModal={handleToggleModalEdit}
        handleUpdateItem={handleUpdateItem}
      />
      <Grid container direction="row-reverse">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            style={{ fontWeight: 700 }}
            startIcon={<FiPlusCircle />}
            onClick={handleToggleModal}
          >
            Novo Comentário
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <Grid item md={12} lg={12} sm={12}>
          <Timeline align="alternate">
            {listHistoric.map(historic => (
              <TimelineItem key={historic.id}>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="secondary" />
                  <TimelineConnector className={classes.secondaryTail} />
                </TimelineSeparator>
                <TimelineContent>
                  <Card elevation={3}>
                    <CardContent>
                      <Typography variant="h6" component="h1">
                        {historic.note}
                      </Typography>
                      <Typography color="textSecondary">
                        {`Criado Por: ${
                          historic.user?.name
                            ? historic.user.name
                            : 'Usuário desconhecidos'
                        }, criado: ${
                          historic.formatedCreatedAt
                            ? historic.formatedCreatedAt
                            : 'Data não encontrada'
                        }`}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <Box className={classes.boxAction}>
                      <IconButton
                        onClick={() => handleDeleteHistoric(historic.id)}
                        disabled={isProgressDelete}
                      >
                        <FiTrash2 className={classes.trashButton} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleEditingHistoric(historic)}
                        disabled={isProgressDelete}
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                    </Box>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Grid>
      </Grid>
    </div>
  );
};

export default ListClientHistoric;
