import React, { useCallback, useEffect, useState } from 'react';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPlusCircle, FiStar, FiTrash2 } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import IRequestPhone from '../../dtos/IRequestPhone';
import api from '../../services/api';
import ModalAddPhones from '../ModalAddPhones';
import ModalEditPhones from '../ModalEditPhone';
import LocaleText from '../../LocaleText/index.json';

interface IProps {
  phones: IRequestPhone[];
}

interface IParams {
  id: string;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const ListClientPhones: React.FC<IProps> = ({ phones }) => {
  const { id } = useParams<IParams>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [listPhones, setListPhones] = useState<IRequestPhone[]>([]);
  const [isProgressDelete, setIsProgressDelete] = useState<boolean>(false);
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [editingPhone, setEditingPhone] = useState<IRequestPhone>(
    {} as IRequestPhone,
  );

  useEffect(() => {
    setListPhones(phones);
  }, [phones]);

  const handleDeleteAddress = useCallback(
    async (phoneId: string) => {
      try {
        setIsProgressDelete(true);
        await api.delete(`phones/${phoneId}`);
        const updatedList = listPhones.filter(phone => phone.id !== phoneId);

        setListPhones(updatedList);
        enqueueSnackbar('Telefone deletado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsProgressDelete(false);
      }
    },
    [listPhones, enqueueSnackbar],
  );

  const handleToggle = useCallback(() => {
    setOpenModalAdd(!openModalAdd);
  }, [openModalAdd]);

  const handleUpdateList = useCallback((newPhone: IRequestPhone) => {
    setListPhones(oldValue => [...oldValue, newPhone]);
  }, []);

  const handleToggleEdit = useCallback(() => {
    setOpenModalEdit(!openModalEdit);
  }, [openModalEdit]);

  const handleEditingPhone = useCallback(
    (phone: IRequestPhone) => {
      setEditingPhone(phone);
      handleToggleEdit();
    },
    [handleToggleEdit],
  );

  const handleUpdateItemInList = useCallback(
    (updatedPhone: IRequestPhone) => {
      const updatedList = listPhones.filter(
        item => item.id !== updatedPhone.id,
      );
      setListPhones([...updatedList, updatedPhone]);
    },
    [listPhones],
  );

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  return (
    <>
      <ModalAddPhones
        clientId={id}
        open={openModalAdd}
        handleToggleModal={handleToggle}
        handleUpdateItem={handleUpdateList}
      />
      <ModalEditPhones
        editingPhone={editingPhone}
        handleToggleModal={handleToggleEdit}
        open={openModalEdit}
        handleUpdateItem={handleUpdateItemInList}
      />
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>Telefones</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={handleToggle}
              >
                Novo Telefone
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              disableSelectionOnClick
              disableColumnSelector
              disableColumnMenu
              localeText={LocaleText}
              rows={listPhones as RowModel[]}
              columns={[
                {
                  field: 'nickname',
                  headerName: 'Apelido',
                  flex: 0.8,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div className={classes.ellipsis}>
                      {!params.value ? 'Sem apelido' : params.value}
                      {!params.row.main ? (
                        ' '
                      ) : (
                        <Tooltip title="Principal">
                          <FiStar
                            color="#22863A"
                            size="1.5em"
                            strokeWidth="3"
                          />
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
                {
                  field: 'type',
                  headerName: 'Tipo',
                  flex: 1,
                  renderCell: (params: ValueFormatterParams) => (
                    <>{params.value === 'CELLPHONE' ? 'Celular' : 'Telefone'}</>
                  ),
                },
                { field: 'areaCode', headerName: 'DDD', flex: 0.3 },
                { field: 'phone', headerName: 'Telefone', flex: 1 },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 150,
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <IconButton
                        onClick={() =>
                          handleEditingPhone(params.row as IRequestPhone)
                        }
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteAddress(params.row.id.toString())
                        }
                        disabled={isProgressDelete}
                      >
                        <FiTrash2 className={classes.trashButton} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              pageSize={10}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListClientPhones;
