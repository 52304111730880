import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel';
import { IRequestBill } from '../../dtos/IRequestBill';
import IRequestContracts from '../../dtos/IRequestContracts';

interface IAPIResponseBill extends IRequestBill {
  error: boolean;
}

interface IProps {
  handleToggle: () => void;
  open: boolean;
  itemsSelected: IRequestContracts[];
  itemsReceived: IAPIResponseBill[];
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    successIcon: {
      color: theme.palette.primary.main,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
  };
});

const ModalLoading: React.FC<IProps> = ({
  handleToggle,
  open,
  itemsSelected,
  itemsReceived,
}) => {
  const classes = useStyles();
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    setLoadingProgress((itemsReceived.length / itemsSelected.length) * 100);
  }, [
    itemsReceived,
    itemsReceived.length,
    itemsSelected,
    itemsSelected.length,
  ]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>Enviando Lote de cobranças</DialogTitle>
      <LinearProgressWithLabel variant="determinate" value={loadingProgress} />

      <DialogContent dividers>
        <List dense style={{ minHeight: '70vh' }}>
          {itemsSelected.map(item => (
            <>
              <ListItem key={`${item.id}`} divider>
                <ListItemText
                  primary={`Contrato nº ${item.contractNumber}  de  ${item.contractor.name}`}
                />
                <Icon>
                  {(() => {
                    if (
                      itemsReceived.find(
                        received =>
                          received.contractId === item.id &&
                          received.error === false,
                      )
                    ) {
                      return <FiCheckCircle className={classes.successIcon} />;
                    }
                    if (
                      itemsReceived.find(
                        received =>
                          received.contractId === item.id &&
                          received.error === true,
                      )
                    ) {
                      return <FiXCircle className={classes.errorIcon} />;
                    }
                    return <CircularProgress size="100%" />;
                  })()}
                </Icon>
              </ListItem>
            </>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          style={{ fontWeight: 700 }}
          disabled={loadingProgress < 100}
          onClick={handleToggle}
        >
          Finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalLoading;
