import axios from 'axios';

const getNewToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('@Parque:refreshToken');
    axios
      .post(`${process.env.REACT_APP_API_URL}authenticate/refresh-token`, {
        refreshToken,
      })
      .then(response => {
        resolve(response.data.token);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default getNewToken;
