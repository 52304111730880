import React, {
  FormEvent,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Divider,
  Card,
  Badge,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Theme,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';

import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { FiCheckCircle, FiEdit, FiTrash2, FiPlusCircle } from 'react-icons/fi';

import api from '../../../services/api';
import IRequestBlocks from '../../../dtos/IRequestBlocks';
import IRequestStreets from '../../../dtos/IRequestStreets';
import IRequestDeposits from '../../../dtos/IRequestDeposits';
import TabPanel from '../../../components/TabPanel';
import formatDeposit from '../../../utils/formatDeposit';
import LocaleText from '../../../LocaleText/index.json';
import StyledBreadcrumb from '../../../components/StyledBreadcrumb';
import {
  getIconStatus,
  getTextModel,
  getTextStatus,
} from '../../../utils/FormatIconStatus';

interface IRequest {
  blocks: IRequestBlocks[];
}

interface IApiRequestBlock {
  blocks: IRequestBlocks;
}

interface IUpdateRequest {
  street: IRequestStreets;
}

interface IParams {
  id: string;
}

interface IRequestStatus {
  deposit: IRequestDeposits;
}

interface ILocation {
  blockId?: string;
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const FormStreet: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation<ILocation>();

  const { id } = useParams<IParams>();
  const { enqueueSnackbar } = useSnackbar();

  const [originalData, setOriginalData] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );

  const [blocks, setBlocks] = useState<IRequestBlocks[]>([]);
  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );
  const [value, setValue] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [listDeposits, setListDeposits] = useState<IRequestDeposits[]>([]);
  const [badgeDeposit, setBadgeDeposit] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [generateAutomaticName, setGenerateAutomaticName] = useState<boolean>(
    true,
  );

  useEffect(() => {
    if (location.state && location.state.blockId) {
      api
        .get<IApiRequestBlock>(`blocks/${location.state.blockId}`)

        .then(response => {
          setBlockSelected(response.data.blocks);
        })
        .catch(() => {
          enqueueSnackbar('Erro inesperado ao buscar o Quadra.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [enqueueSnackbar, location]);

  useEffect(() => {
    api
      .get<IRequest>('blocks/actives')
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      api
        .get<IUpdateRequest>(`streets/${id}`)
        .then(response => {
          setOriginalData(response.data.street);
          setName(response.data.street.name);
          setBlockSelected(response.data.street.block);
          if (response.data.street.note) {
            setNote(response.data.street.note);
          }
          if (response.data.street.deposits) {
            const depositsFormatted = response.data.street.deposits
              .map(deposit => formatDeposit(deposit))
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            setListDeposits(depositsFormatted);
            setBadgeDeposit(response.data.street.deposits.length);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao buscar a rua.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [id, enqueueSnackbar]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          ...(generateAutomaticName
            ? { generateAutomaticName }
            : { name: name.toUpperCase() }),
          blockId: blockSelected.id,
          ...(note
            ? {
                note,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          generateAutomaticName: Yup.boolean(),
          name: Yup.string().when('generateAutomaticName', {
            is: val => !val,
            then: Yup.string().required('Nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          blockId: Yup.string().required('Quadra é obrigatório'),
          note: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        await api.post('streets', data);
        history.push(`/form/blocks/${blockSelected.id}?tabIndex=1`);
        enqueueSnackbar('Rua adicionada com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      note,
      enqueueSnackbar,
      history,
      blockSelected,
      generateAutomaticName,
    ],
  );

  const handleUpdate = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name: name.toUpperCase(),
          blockId: blockSelected.id,
          ...(note
            ? {
                note,
              }
            : {}),
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da quadra é obrigatório'),
          blockId: Yup.string().required('Quadra é obrigatório'),
          note: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`streets/${id}`, data);

        enqueueSnackbar('Rua atualizada com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [name, note, enqueueSnackbar, blockSelected, id],
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  const handleChangeGenerateName = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setGenerateAutomaticName(event.target.checked);
  };

  const handleUpdateStatusDeposit = useCallback(
    async (idDeposit: string) => {
      try {
        const response = await api.patch<IRequestStatus>(
          `deposits/${idDeposit}/active`,
        );
        const updatedList = listDeposits.filter(
          deposit => deposit.id !== idDeposit,
        );
        const deposit = formatDeposit(response.data.deposit);
        setListDeposits([...updatedList, deposit]);
      } catch {
        enqueueSnackbar(
          'Houve um erro ao atualizar o jazigo, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      }
    },
    [listDeposits, enqueueSnackbar],
  );

  // FIX: Bug AutoHeight DataGrid
  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  return (
    <Container>
      <StyledBreadcrumb street={originalData} />
      <h1>{isUpdate ? 'Atualizando Rua' : 'Nova Rua'}</h1>
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Geral" className={classes.tabLabel} />
          <Tab
            label={
              <Badge color="secondary" badgeContent={badgeDeposit} showZero>
                Jazigos
              </Badge>
            }
            className={classes.tabLabel}
            disabled={!isUpdate}
          />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={isUpdate ? handleUpdate : handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} sm={12}>
              <Paper style={{ padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Nome"
                      required
                      name="name"
                      value={name}
                      onChange={event => setName(event.target.value)}
                      disabled={generateAutomaticName && !isUpdate}
                      helperText={
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              onChange={handleChangeGenerateName}
                              value={generateAutomaticName}
                              checked={generateAutomaticName}
                              disabled={isUpdate}
                            />
                          }
                          label="Gerar nome automático"
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Autocomplete
                      options={blocks}
                      value={blockSelected}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, val) =>
                        option.name === val.name
                      }
                      style={{ width: '100%' }}
                      onChange={(event, block) => {
                        if (block) {
                          setBlockSelected(block);
                        } else {
                          setBlockSelected({} as IRequestBlocks);
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Quadra"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Observações"
                      name="note"
                      value={note}
                      onChange={event => setNote(event.target.value)}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <div style={{ marginTop: '20px' }}>
            <Button
              variant="contained"
              disableElevation
              color={isUpdate ? 'secondary' : 'primary'}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress color="primary" />
              ) : (
                `${isUpdate ? 'Autalizar Rua' : 'Nova Rua'}`
              )}
            </Button>
          </div>
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card>
          <>
            <Box className={classes.boxHeader}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.headerTitle}>
                    Jazigos
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    style={{ fontWeight: 700 }}
                    startIcon={<FiPlusCircle />}
                    onClick={() =>
                      history.push({
                        pathname: '/form/deposits',
                        state: { blockId: blockSelected.id, streetId: id },
                      })
                    }
                  >
                    Novo Jazigo
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider style={{ width: '100%', marginBottom: 16 }} />
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                disableColumnSelector
                disableSelectionOnClick
                disableColumnMenu
                localeText={LocaleText}
                rows={listDeposits as RowModel[]}
                columns={[
                  {
                    field: 'Avatar',
                    headerName: 'Situação',
                    width: 100,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <Tooltip
                        title={
                          <Typography variant="subtitle2">
                            {getTextStatus(params.row.status)}
                          </Typography>
                        }
                        placement="top"
                        arrow
                      >
                        <div>{getIconStatus(params.row.status)}</div>
                      </Tooltip>
                    ),
                  },
                  {
                    field: 'ccoContract',
                    headerName: 'CCO',
                    flex: 0.5,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {params.row.ccoContract ? params.row.ccoContract : ''}
                      </div>
                    ),
                  },
                  {
                    field: 'name',
                    headerName: 'Nome',
                    flex: 0.5,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.value ? 'Sem apelido' : params.value}
                      </div>
                    ),
                  },
                  {
                    field: 'modelo.name',
                    headerName: 'Modelo',
                    flex: 0.5,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {getTextModel(params.row.model)}
                      </div>
                    ),
                  },
                  {
                    field: 'client',
                    headerName: 'Cliente',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {params.row.client?.name
                          ? params.row.client.name
                          : 'Não possui cliente'}
                      </div>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    width: 150,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/form/deposits/${params.row.id.toString()}`,
                            )
                          }
                        >
                          <FiEdit className={classes.editButton} />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleUpdateStatusDeposit(params.row.id.toString())
                          }
                        >
                          {params.row.active ? (
                            <FiTrash2 className={classes.trashButton} />
                          ) : (
                            <FiCheckCircle className={classes.iconActive} />
                          )}
                        </IconButton>
                      </>
                    ),
                  },
                ]}
                pageSize={10}
              />
            </div>
          </>
        </Card>
      </TabPanel>
    </Container>
  );
};

export default FormStreet;
