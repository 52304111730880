import { format, parseISO } from 'date-fns';

import { IRequestBill, IRequestBillItems } from '../dtos/IRequestBill';
import { formartedPrice } from './formatPriceBeforeSend';

type TTextStatus = {
  [key: string]: string;
};

function getTextStatus(status: string): string {
  const actionState: TTextStatus = {
    CREATED: 'Criado',
    WAITING: 'Aguardando',
    PAID: 'Pago',
    NOT_PAID: 'Não Pago',
    REVERTED: 'Revertido',
  };

  return actionState[status];
}

export const formatBills = (data: IRequestBill): IRequestBill => {
  return {
    ...data,
    formattedCreatedAt: format(parseISO(data.createdAt), 'dd/MM/yyyy'),
    formattedUpdatedAt: format(parseISO(data.updatedAt), 'dd/MM/yyyy HH:mm'),
    formattedExpirationDate: format(
      parseISO(data.expirationDate),
      'dd/MM/yyyy',
    ),
    formattedAmount: formartedPrice(data.amount),
    formattedStatus: getTextStatus(data.status),
    items: data.items && data.items.map(item => formatBillItem(item)),
  };
};

export const formatBillItem = (data: IRequestBillItems): IRequestBillItems => {
  return {
    ...data,
    formattedCreatedAt: format(parseISO(data.createdAt), 'dd/MM/yyyy'),
    formattedUpdatedAt: format(parseISO(data.updatedAt), 'dd/MM/yyyy'),
    formattedPrice: formartedPrice(data.price),
  };
};
