import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiEdit } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import IRequestDefuncts from '../../dtos/IRequestDefuncts';
import formatDefunct from '../../utils/formatDefunct';
import LocaleText from '../../LocaleText/index.json';

interface IProps {
  depositId: string;
}

interface IRequest {
  total: number;
  defuncts: IRequestDefuncts[];
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
  };
});

const ListDepositOssuary: React.FC<IProps> = ({ depositId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const [listDefuncts, setListDefuncts] = useState<IRequestDefuncts[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    (async () => {
      api
        .get<IRequest>(`ossuary/${depositId}`, {
          params: { page: page + 1, pageSize },
        })
        .then(response => {
          const { total, defuncts } = response.data;

          const drawersFormatted = defuncts.map(defunct =>
            formatDefunct(defunct),
          );

          if (!active) {
            return;
          }
          setListDefuncts(drawersFormatted);
          setTotalItem(total);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [enqueueSnackbar, depositId, page, pageSize]);

  return (
    <>
      <Card>
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <CardContent>
            <Box className={classes.boxHeader}>
              <Grid container spacing={3} justify="space-between">
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                    Todos Sepultados na caixa Ossuaria
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ marginLeft: 4, marginTop: 4 }}
                  >
                    Total: {totalItem}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider style={{ width: '100%', marginBottom: 16 }} />

            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listDefuncts as RowModel[]}
                columns={[
                  {
                    field: 'registry',
                    headerName: 'Registro',
                    width: 120,
                    align: 'left',
                  },
                  {
                    field: 'name',
                    headerName: 'Sepultado',
                    width: 208,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.value ? 'Sem apelido' : params.value}
                      </div>
                    ),
                  },
                  {
                    field: 'client',
                    headerName: 'Cliente',
                    width: 208,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {params.row.client?.name ? params.row.client.name : ''}
                      </div>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <Tooltip title="Editar">
                          <IconButton
                            onClick={() =>
                              history.push(
                                `/form/defuncts/${params.row.id.toString()}`,
                              )
                            }
                          >
                            <FiEdit className={classes.editButton} />
                          </IconButton>
                        </Tooltip>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </CardContent>
        )}
      </Card>
    </>
  );
};

export default ListDepositOssuary;
