import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  CircularProgress,
  Grid,
  List,
  TextField,
  ListItem,
  CardContent,
  makeStyles,
  Box,
  Divider,
  Typography,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/esm/locale';
import { useSnackbar } from 'notistack';
import { parseISO, format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';

import { FiPrinter } from 'react-icons/fi';
import IRequestDefunctsActions from '../../dtos/IRequestDefunctsActions';
import api from '../../services/api';
import noDataSvg from '../../assets/noData.svg';

interface IRequest {
  total: number;
  actions: IRequestDefunctsActions[];
}

type TTextStatus = {
  [key: string]: string;
};

function getTextStatus(status: string): string {
  const actionState: TTextStatus = {
    SEPULTAMENTO: 'Sepultamento',
    EXUMAÇÃO: 'Exumação',
    TRANSLADADO: 'Transladado',
  };

  return actionState[status];
}

const formatDefunctsAction = (
  defunctsAction: IRequestDefunctsActions,
): IRequestDefunctsActions => {
  return {
    ...defunctsAction,
    formatedCreatedAt: format(
      parseISO(defunctsAction.createdAt),
      "dd/MM/yyyy 'ás' HH:mm",
    ),
  };
};

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
  };
});

const ListActions: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [page, setPage] = useState<number>(1);
  const [countPages, setCountPages] = useState<number>(0);

  const [day, setDay] = useState<Date | null>(new Date());
  const [status, setStatus] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listDefunctsActions, setListDefunctsActions] = useState<
    IRequestDefunctsActions[]
  >([]);
  const [totalItem, setTotalItem] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);
    api
      .get<IRequest>('all-actions', {
        params: {
          day,
          page,
          ...(status
            ? {
                status,
              }
            : {}),
        },
      })
      .then(response => {
        const { total, actions } = response.data;

        const defunctsActionsFormatted = actions.map(action =>
          formatDefunctsAction(action),
        );

        setListDefunctsActions(defunctsActionsFormatted);
        setTotalItem(total);
        setCountPages(Math.ceil(total / 5));
      })
      .catch(() => {
        enqueueSnackbar('Houve um erro ao carregar a lista, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [day, enqueueSnackbar, status, page]);

  return (
    <Container>
      <Grid container alignItems="center" justify="center">
        <Grid item md={8}>
          <Card>
            <Box className={classes.boxHeader}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography className={classes.headerTitle}>
                    Filtros {totalItem}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton>
                    <FiPrinter />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <CardContent>
              <form noValidate>
                <Grid container spacing={3} alignItems="center">
                  <Grid item md={6} xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <KeyboardDatePicker
                        fullWidth
                        autoOk
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        label="Data"
                        onChange={(date: Date | null) => setDay(date)}
                        value={day}
                        name="dateAction"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6} xs={12} sm={6}>
                    <Autocomplete
                      options={['EXUMAÇÃO', 'SEPULTAMENTO', 'TRANSLADADO']}
                      value={status}
                      getOptionLabel={option => option}
                      style={{ width: '100%' }}
                      onChange={(event, actionStatus) => {
                        if (actionStatus) {
                          setStatus(actionStatus);
                        } else {
                          setStatus(null);
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Status"
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item>
            <CircularProgress color="primary" style={{ marginTop: '25px' }} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ marginTop: '25px' }}
        >
          <Grid item md={8}>
            {!isLoading && (
              <List>
                {listDefunctsActions.length > 0 ? (
                  <>
                    {listDefunctsActions.map(action => (
                      <>
                        <ListItem
                          button
                          key={action.id}
                          onClick={() => {
                            history.push(
                              `/form/defuncts/${action.defunct.id}?tabIndex=1`,
                            );
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography variant="h6">
                                {`${action.nroRegister} - ${
                                  action.defunct.name
                                } - ${getTextStatus(action.status)}/ ${
                                  action.defunct.drawer?.deposit.name
                                    ? action.defunct.drawer?.deposit.name
                                    : ''
                                }/ ${
                                  action.defunct.drawer?.deposit.street?.name
                                    ? action.defunct.drawer?.deposit.street.name
                                    : ''
                                }/ ${
                                  action.defunct.drawer?.deposit.street?.block
                                    ? action.defunct.drawer?.deposit.street
                                        .block.name
                                    : ''
                                }
                                `}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                {`${action.formatedCreatedAt} - Criado por ${action.user.name}`}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider variant="fullWidth" component="li" />
                      </>
                    ))}
                  </>
                ) : (
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                  >
                    <Grid item>
                      <img src={noDataSvg} alt="no_data" width="250" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        Nenhuma ação cadastrada para hoje
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </List>
            )}
            <Pagination
              count={countPages}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
              color="primary"
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default ListActions;
