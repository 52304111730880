import { format, parseISO } from 'date-fns';

import IRequestContracts from '../dtos/IRequestContracts';
import { formatBills } from './formatBills';
import formatItemContract from './formatItemContract';

export const formatContracts = (
  contract: IRequestContracts,
): IRequestContracts => {
  return {
    ...contract,
    formattedCreatedAt: format(parseISO(contract.createdAt), 'dd/MM/yyyy'),
    formattedUpdatedAt: format(parseISO(contract.updatedAt), 'dd/MM/yyyy'),
    formattedInitialDate: format(parseISO(contract.initialDate), 'dd/MM/yyyy'),
    formattedIssueDate: format(parseISO(contract.issueDate), 'dd/MM/yyyy'),
    formattedStatus: contract.active ? 'Ativo' : 'Inativo',
    items: contract.items.map(item => formatItemContract(item)),
    bills: contract.bills.map(item => formatBills(item)),
  };
};
