import React, { useCallback, useEffect, useState } from 'react';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPrinter, FiTrash2 } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import LocaleText from '../../LocaleText/index.json';
import IRequestContracts from '../../dtos/IRequestContracts';
import { formatContracts } from '../../utils/formatContracts';
import ModalContractDepositItem from '../ModalContractDepositItem';
import IRequestDeposits from '../../dtos/IRequestDeposits';

interface IProps {
  clientId: string;
}

interface IRequest {
  contracts: IRequestContracts[];
  total: number;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const ListClientContracts: React.FC<IProps> = ({ clientId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [listContracts, setListContracts] = useState<IRequestContracts[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openModalEdit, setOpenModal] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IRequestContracts>(
    {} as IRequestContracts,
  );

  const handleToggle = useCallback(() => {
    setOpenModal(!openModalEdit);
  }, [openModalEdit]);

  const handleSelectContract = useCallback(
    (contract: IRequestContracts) => {
      setSelectedContract(contract);
      handleToggle();
    },
    [handleToggle],
  );

  useEffect(() => {
    api
      .get<IRequest>(`contracts/${clientId}/contractor`, {
        params: { page: page + 1, pageSize },
      })
      .then(response => {
        const { total, contracts } = response.data;

        const formatedContract = contracts.map(contract =>
          formatContracts(contract),
        );

        setListContracts(formatedContract);
        setTotalItem(total);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao buscar o contrato.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, pageSize, enqueueSnackbar, clientId]);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  const findLocatization = (deposit: IRequestDeposits): string | undefined => {
    if (deposit) {
      return `${deposit.name}/ ${deposit.street.name}/ ${deposit.street.block.name}`;
    }

    return '';
  };

  return (
    <>
      <Card>
        <ModalContractDepositItem
          open={openModalEdit}
          contract={selectedContract}
          handleToggleModal={handleToggle}
        />
        <Box className={classes.boxHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>Contratos</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              loading={isLoading}
              rowCount={totalItem}
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageChange={params => {
                setPage(params.page);
              }}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={params => {
                setPageSize(params.pageSize);
              }}
              disableColumnSelector
              disableSelectionOnClick
              localeText={LocaleText}
              rows={listContracts as RowModel[]}
              columns={[
                {
                  field: 'cod',
                  headerName: 'Codigo',
                  flex: 0.5,
                  align: 'left',
                },
                {
                  field: 'deposit.name',
                  headerName: 'Jazigo',
                  flex: 1,
                  align: 'left',
                  renderCell: (params: ValueFormatterParams) => (
                    <>{findLocatization(params.row.deposit)}</>
                  ),
                },
                {
                  field: 'formattedInitialDate',
                  headerName: 'Data inicial',
                  flex: 1,
                  align: 'left',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      {!params.row.formattedInitialDate
                        ? ''
                        : params.row.formattedInitialDate}
                    </>
                  ),
                },
                {
                  field: 'formattedExpirationDate',
                  headerName: 'Data de Expiração',
                  flex: 1,
                  align: 'left',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      {!params.row.formattedExpirationDate
                        ? ''
                        : params.row.formattedExpirationDate}
                    </>
                  ),
                },

                {
                  field: 'formattedStatus',
                  headerName: 'Status',
                  flex: 1,
                  align: 'left',
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 150,
                  disableColumnMenu: true,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <IconButton
                        onClick={() => {
                          handleSelectContract(params.row as IRequestContracts);
                        }}
                      >
                        <FiPrinter />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          history.push(`/contract/${params.row.id.toString()}`)
                        }
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                      <IconButton
                        disabled={!params.row.active}
                        onClick={() =>
                          history.push(`/cancel/${params.row.id.toString()}`)
                        }
                      >
                        <FiTrash2 className={classes.trashButton} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListClientContracts;
