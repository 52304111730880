import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Grid,
  makeStyles,
  DialogContent,
  FormControl,
  FormLabel,
  Box,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import IRequestClient from '../../dtos/IRequestClient';
import api from '../../services/api';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    box: {
      gap: '0px',
      alignItems: 'center',
      paddingTop: '24px',
      paddingBottom: '24px',
    },
  }),
);

interface IPropsModal {
  open: boolean;
  handleToggleModalFilter(): void;
  handleApplyFilter(
    model: string[],
    status: string[],
    hasOssuary: boolean,
    clientId: string,
    isOpen: boolean,
  ): void;
}

interface IRequestClients {
  clients: IRequestClient[];
}
const Filter: React.FC<IPropsModal> = ({
  open,
  handleToggleModalFilter,
  handleApplyFilter,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [modelDeposit, setModelDeposit] = useState<string[]>([]);
  const [statusDeposit, setStatusDeposit] = useState<string[]>([]);
  const [hasOssuary, setHasOssuary] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openAutocomplete, setOpenAutocomplete] = useState<boolean>(false);
  const [options, setOptions] = useState<IRequestClient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [client, setClient] = useState<IRequestClient>({} as IRequestClient);

  useEffect(() => {
    if (!loading) {
      setLoading(false);
    }

    api
      .get<IRequestClients>('clients/active')
      .then(response => {
        setOptions(response.data.clients);
        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar ao clientes, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar, loading]);

  useEffect(() => {
    if (!openAutocomplete) {
      setOptions([]);
    }
  }, [openAutocomplete]);

  const handleCheckedModel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const findInList = modelDeposit.find(item => item === event.target.name);

      if (!findInList) {
        setModelDeposit([...modelDeposit, event.target.name]);
      } else {
        const listFilter = modelDeposit.filter(
          item => item !== event.target.name,
        );
        setModelDeposit(listFilter);
      }
    },
    [modelDeposit],
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      handleApplyFilter(
        modelDeposit,
        statusDeposit,
        hasOssuary,
        client.id,
        isOpen,
      );
      handleToggleModalFilter();
    },
    [
      handleApplyFilter,
      modelDeposit,
      statusDeposit,
      hasOssuary,
      client.id,
      isOpen,
      handleToggleModalFilter,
    ],
  );

  const handleClearFilter = useCallback(() => {
    setStatusDeposit([]);
    setHasOssuary(false);
    setClient({} as IRequestClient);
    setModelDeposit([]);
  }, []);

  const handleCheckedStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const findInList = statusDeposit.find(item => item === event.target.name);

      if (!findInList) {
        setStatusDeposit([...statusDeposit, event.target.name]);
      } else {
        const listFilter = statusDeposit.filter(
          item => item !== event.target.name,
        );
        setStatusDeposit(listFilter);
      }
    },
    [statusDeposit],
  );

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" scroll="paper">
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Filtros</DialogTitle>
        <IconButton onClick={() => handleToggleModalFilter()}>
          <FiXCircle />
        </IconButton>
      </header>

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <FormLabel
                component="legend"
                style={{
                  display: 'block',
                  paddingTop: '22px',
                  fontWeight: 700,
                  marginBottom: '10px',
                }}
              >
                Buscar pelo Cliente
              </FormLabel>
              <Autocomplete
                open={openAutocomplete}
                value={client}
                onOpen={() => {
                  setOpenAutocomplete(true);
                }}
                onClose={() => {
                  setOpenAutocomplete(false);
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, optionClient) => {
                  if (optionClient) {
                    setClient(optionClient);
                  } else {
                    setClient({} as IRequestClient);
                  }
                }}
                getOptionLabel={option => option.name}
                options={options}
                loading={loading}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl component="fieldset">
                <Box style={{ marginTop: '22px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasOssuary}
                          onChange={event =>
                            setHasOssuary(event.target.checked)
                          }
                        />
                      }
                      label="Caixa Ossuária"
                    />
                  </FormGroup>
                </Box>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControl component="fieldset">
                <Box style={{ marginTop: '22px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isOpen}
                          onChange={event => setIsOpen(event.target.checked)}
                        />
                      }
                      label="Jazigo Aberto"
                    />
                  </FormGroup>
                </Box>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Divider />
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    display: 'block',
                    paddingTop: '22px',
                    fontWeight: 700,
                  }}
                >
                  Tipos de Jazigos
                </FormLabel>
                <Box style={{ marginTop: '22px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="ADULT"
                          onChange={handleCheckedModel}
                          checked={
                            !!modelDeposit.find(item => item === 'ADULT')
                          }
                        />
                      }
                      label="Adulto"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="AMERICAN"
                          onChange={handleCheckedModel}
                          checked={
                            !!modelDeposit.find(item => item === 'AMERICAN')
                          }
                        />
                      }
                      label="Americano"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="DUBLE"
                          onChange={handleCheckedModel}
                          checked={
                            !!modelDeposit.find(item => item === 'DUBLE')
                          }
                        />
                      }
                      label="Duplo"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="NOBLE"
                          onChange={handleCheckedModel}
                          checked={
                            !!modelDeposit.find(item => item === 'NOBLE')
                          }
                        />
                      }
                      label="Nobre"
                    />
                  </FormGroup>
                </Box>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Divider />
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{
                    display: 'block',
                    paddingTop: '22px',
                    fontWeight: 700,
                  }}
                >
                  Estado do Jazgio
                </FormLabel>
                <Box style={{ marginTop: '22px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="NOTBUILT"
                          onChange={handleCheckedStatus}
                          checked={
                            !!statusDeposit.find(item => item === 'NOTBUILT')
                          }
                        />
                      }
                      label="Não construido"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="NOTSOLD"
                          onChange={handleCheckedStatus}
                          checked={
                            !!statusDeposit.find(item => item === 'NOTSOLD')
                          }
                        />
                      }
                      label="Não vendido"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="SOLD"
                          onChange={handleCheckedStatus}
                          checked={
                            !!statusDeposit.find(item => item === 'SOLD')
                          }
                        />
                      }
                      label="Vendido"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="INACTIVE"
                          onChange={handleCheckedStatus}
                          checked={
                            !!statusDeposit.find(item => item === 'INACTIVE')
                          }
                        />
                      }
                      label="Inativo"
                    />
                  </FormGroup>
                </Box>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClearFilter}
                type="button"
              >
                Limpar Filtros
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" type="submit">
                Apilicar Filtros
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Filter;
