import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import { FiInbox } from 'react-icons/fi';

interface INoDataContent {
  title: string;
  link?: string;
}

const NoDataContent: React.FC<INoDataContent> = ({ title, link }) => {
  return (
    <Container>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <FiInbox size={42} color="" />
        <Typography variant="subtitle1">{title}</Typography>
        {link && (
          <Link to={link} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="secondary" disableElevation>
              Criar Agora
            </Button>
          </Link>
        )}
      </Grid>
    </Container>
  );
};

export default NoDataContent;
