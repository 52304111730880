import { format, parseISO } from 'date-fns';
import IRequestStreets from '../dtos/IRequestStreets';

const formatStreet = (street: IRequestStreets): IRequestStreets => {
  return {
    ...street,
    formatedCreatedAt: format(parseISO(street.createdAt), 'dd/MM/yyyy'),
  };
};

export default formatStreet;
