import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/esm/locale';
import axios from 'axios';
import IRequestClient from '../../dtos/IRequestClient';
import api from '../../services/api';
import { cpfMask, cnpjMask, rgMask } from '../../utils/cpfCnpjMask';
import IRequestAddress from '../../dtos/IRequestAddress';
import IRequestPhone from '../../dtos/IRequestPhone';
import IRequestContact from '../../dtos/IRequestContact';

const useStyles = makeStyles(() => {
  return {
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    confirmationTitle: {
      fontWeight: 600,
    },
  };
});

interface IAPIClientRequest {
  client: IRequestClient;
}

interface IAPIAddressRequest {
  address: IRequestAddress;
}

interface IAPIPhoneRequest {
  phone: IRequestPhone;
}

interface IAPIContactRequest {
  contact: IRequestContact;
}

interface IProps {
  handleToggle(): void;
  handleSelectClient(client: IRequestClient): void;
  open: boolean;
}

function getSteps(): string[] {
  return [
    'Cadastrar cliente',
    'Cadastrar Enderenço',
    'Cadastrar Telefone',
    'Cadastrar Contato',
    'Finalizar',
  ];
}

const ModalAddFullClient: React.FC<IProps> = ({
  handleToggle,
  open,
  handleSelectClient,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  // Stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();

  // ClientForm
  const [clientData, setClientData] = useState<IRequestClient>(
    {} as IRequestClient,
  );
  const [clientId, setClientId] = useState<string>('');
  const [clientTypeSubscription, setClientTypeSubscription] = useState<string>(
    'FISICA',
  );
  const [clientName, setClientName] = useState<string>('');
  const [clientEmail, setClientEmail] = useState<string>('');
  const [clientDocument, setClientDocument] = useState<string>('');
  const [clientRg, setClientRg] = useState<string>('');
  const [clientBirthDate, setClientBirthDate] = useState<Date | null>(null);
  const [clientIsLoading, setClientIsLoading] = useState<boolean>(false);

  // AddressForm
  const [addressId, setAddressId] = useState<string>('');
  const [addressNickname, setAddressNickname] = useState<string>('');
  const [addressCep, setAddressCep] = useState<string>('');
  const [addressUf, setAddressUf] = useState<string>('');
  const [addressCity, setAddressCity] = useState<string>('');
  const [addressStreet, setAddressStreet] = useState<string>('');
  const [addressDistrict, setAddressDistrict] = useState<string>('');
  const [addressNumber, setAddressNumber] = useState<string>('');
  const [addressComplement, setAddressComplement] = useState<string>('');
  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  // PhoneForm
  const [phoneId, setPhoneId] = useState<string>('');
  const [phoneNickName, setPhoneNickName] = useState<string>('');
  const [phoneType, setPhoneType] = useState<string>('CELLPHONE');
  const [phoneAreaCode, setPhoneAreaCode] = useState<string>('44');
  const [phone, setPhone] = useState<string>('');
  const [phoneLoading, setPhoneLoading] = useState<boolean>(false);

  // ContactForm
  const [contactId, setContactId] = useState<string>('');
  const [contactName, setContactName] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactCoalition, setContactCoalition] = useState<string>('');
  const [contactPhoneType, setContactPhoneType] = useState<string>('CELLPHONE');
  const [contactPhoneAreaCode, setContactPhoneAreaCode] = useState<string>(
    '44',
  );
  const [contactPhone, setContactPhone] = useState<string>('');
  const [contactLoading, setContactLoading] = useState<boolean>(false);

  // Stepper Functions
  const isStepSkipped = (step: number): boolean => {
    return skipped.has(step);
  };

  const handleNext = async (): Promise<void> => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    try {
      switch (activeStep) {
        case 0:
          await handleClientSubmit();
          break;

        case 1:
          await handleAddressSubmit();
          break;

        case 2:
          await handlePhoneSubmit();
          break;

        case 3:
          await handleContactSubmit();
          break;
        case 4:
          await handleConcludeSubmit();
          break;
        default:
          break;
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function getStepContent(step: number): JSX.Element {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item md={6} sm={4} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nome Completo"
                required
                name="clientName"
                value={clientName}
                onChange={event =>
                  setClientName(event.target.value.toUpperCase())
                }
              />
            </Grid>
            <Grid item md={6} sm={4} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                name="clientEmail"
                value={clientEmail}
                onChange={event => setClientEmail(event.target.value)}
              />
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  disableFuture
                  variant="inline"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: 'end' }}
                  format="dd/MM/yyyy"
                  label="Data de Nascimento"
                  required
                  name="clientBirthDate"
                  onChange={(date: Date | null) => setClientBirthDate(date)}
                  value={clientBirthDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <FormControl
                fullWidth
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel
                  id="subscription-label"
                  className={classes.InputLabel}
                >
                  Tipo de inscrição
                </InputLabel>
                <Select
                  labelId="subscription-label"
                  id="subscription-select"
                  label="Status"
                  required
                  variant="outlined"
                  value={clientTypeSubscription}
                  onChange={event =>
                    setClientTypeSubscription(event.target.value as string)
                  }
                >
                  <MenuItem value="FISICA" selected>
                    Física
                  </MenuItem>
                  <MenuItem value="JURIDICA">Jurídica</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                label={clientTypeSubscription === 'FISICA' ? 'CPF' : 'CNPJ'}
                required
                name="clientDocument"
                value={clientDocument}
                onChange={event => {
                  if (clientTypeSubscription === 'FISICA') {
                    setClientDocument(cpfMask(event.target.value as string));
                  } else {
                    setClientDocument(cnpjMask(event.target.value as string));
                  }
                }}
              />
            </Grid>

            {clientTypeSubscription === 'FISICA' && (
              <Grid item md={3} sm={4} xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Rg"
                  required
                  name="clientDocument"
                  value={clientRg}
                  onChange={event =>
                    setClientRg(rgMask(event.target.value as string))
                  }
                />
              </Grid>
            )}
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Descrição"
                required
                value={addressNickname}
                onChange={event => setAddressNickname(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="CEP"
                required
                value={addressCep}
                onChange={event => setAddressCep(event.target.value)}
                onBlur={handleSearchCep}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Estado"
                required
                value={addressUf}
                onChange={event => setAddressUf(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Cidade"
                required
                value={addressCity}
                onChange={event => setAddressCity(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Bairro"
                required
                value={addressDistrict}
                onChange={event => setAddressDistrict(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Rua"
                required
                value={addressStreet}
                onChange={event => setAddressStreet(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Numero"
                required
                value={addressNumber}
                onChange={event => setAddressNumber(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Complemento"
                value={addressComplement}
                onChange={event => setAddressComplement(event.target.value)}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Descrição"
                required
                value={phoneNickName}
                onChange={event => setPhoneNickName(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="subscription-label">Tipo</InputLabel>
                <Select
                  labelId="subscription-label"
                  id="subscription-select"
                  label="Status"
                  required
                  variant="outlined"
                  value={phoneType}
                  onChange={event => {
                    setPhoneType(event.target.value as string);
                  }}
                >
                  <MenuItem value="TELEPHONE" selected>
                    Telefone
                  </MenuItem>
                  <MenuItem value="CELLPHONE">Celular</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={4}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="DDD"
                value={phoneAreaCode}
                onChange={event =>
                  setPhoneAreaCode(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 2 }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Número"
                value={phone}
                onChange={event =>
                  setPhone(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 9 }}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nome"
                required
                value={contactName}
                onChange={event =>
                  setContactName(event.target.value.toUpperCase())
                }
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                value={contactEmail}
                onChange={event => setContactEmail(event.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Coligação"
                required
                value={contactCoalition}
                onChange={event => setContactCoalition(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="subscription-label">Tipo</InputLabel>
                <Select
                  labelId="subscription-label"
                  id="subscription-select"
                  label="Status"
                  required
                  variant="outlined"
                  value={contactPhoneType}
                  onChange={event => {
                    setContactPhoneType(event.target.value as string);
                  }}
                >
                  <MenuItem value="TELEPHONE" selected>
                    Telefone
                  </MenuItem>
                  <MenuItem value="CELLPHONE">Celular</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={4}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="DDD"
                value={contactPhoneAreaCode}
                onChange={event =>
                  setContactPhoneAreaCode(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 2 }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Número"
                value={contactPhone}
                onChange={event =>
                  setContactPhone(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 9 }}
              />
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Typography variant="h3" gutterBottom>
                Confirmação:
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.confirmationTitle}
              >
                Cliente
              </Typography>
              <Divider />
              <Typography variant="h6" gutterBottom>
                <b>Name</b>: {clientName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Email</b>: {clientEmail}
              </Typography>
              {clientTypeSubscription === 'FISICA' ? (
                <>
                  <Typography variant="h6" gutterBottom>
                    <b>CPF</b>: {clientDocument}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    <b>RG</b>: {clientRg}
                  </Typography>
                </>
              ) : (
                <Typography variant="h6" gutterBottom>
                  <b>CNPJ</b>: {clientDocument}
                </Typography>
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.confirmationTitle}
              >
                Endereço
              </Typography>
              <Divider />
              <Typography variant="h6" gutterBottom>
                <b>Descrição</b>: {addressNickname}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>CEP</b>: {addressCep}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>UF</b>: {`${addressCity}/${addressUf}`}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Endereço</b>:{' '}
                {`${addressDistrict} - ${addressStreet} - ${addressNumber}`}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Complemento</b>: {addressComplement}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.confirmationTitle}
              >
                Telefone
              </Typography>
              <Divider />
              <Typography variant="h6" gutterBottom>
                <b>Descrição</b>: {phoneNickName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Telefone</b>: {`(${phoneAreaCode}) ${phone}`}
              </Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.confirmationTitle}
              >
                Contato
              </Typography>
              <Divider />
              <Typography variant="h6" gutterBottom>
                <b>Nome</b>: {contactName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Email</b>: {contactEmail}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Coligação</b>: {contactCoalition}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Telefone</b>: {`(${contactPhoneAreaCode}) ${contactPhone}`}
              </Typography>
            </Grid>
          </Grid>
        );
    }
  }

  // ClientForm Functions

  const handleClientSubmit = useCallback(async () => {
    try {
      setClientIsLoading(true);
      const data = {
        name: clientName,
        email: clientEmail,
        typeSubscription: clientTypeSubscription,
        ...(clientTypeSubscription === 'FISICA'
          ? {
              cpf: clientDocument,
              rg: clientRg,
            }
          : {
              cnpj: clientDocument,
            }),
        birthDate: clientBirthDate,
      };
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        email: Yup.string()
          .email('Precisamos de E-mail válido')
          .required('E-mail é obrigatório'),
        birthDate: Yup.string().required('Data de nascimento é obrigatório'),
        typeSubscription: Yup.string().required(
          'Tipo da inscrição é obrigatório',
        ),
        cpf: Yup.string().when('clientTypeSubscription', {
          is: val => val === 'FISICA',
          then: Yup.string().required('CPF é obrigatório'),
          otherwise: Yup.string(),
        }),
        rg: Yup.string().when('clientTypeSubscription', {
          is: val => val === 'FISICA',
          then: Yup.string().required('RG é obrigatório'),
          otherwise: Yup.string(),
        }),
        cnpj: Yup.string().when('clientTypeSubscription', {
          is: val => val === 'JURIDICA',
          then: Yup.string().required('CNPJ é obrigatório'),
          otherwise: Yup.string(),
        }),
      });
      await schema.validate(data, { abortEarly: false });
      if (clientId) {
        const response = await api.put<IAPIClientRequest>(
          `/clients/${clientId}`,
          data,
        );
        setClientId(response.data.client.id);
        setClientData(response.data.client);
        enqueueSnackbar('Cliente Atualizado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } else {
        const response = await api.post<IAPIClientRequest>('/clients', data);
        setClientId(response.data.client.id);
        setClientData(response.data.client);
        enqueueSnackbar('Cliente cadastrado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
      } else {
        enqueueSnackbar('Erro inesperado ocorreu', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
      throw new Error(error);
    } finally {
      setClientIsLoading(false);
    }
  }, [
    clientName,
    clientEmail,
    clientTypeSubscription,
    clientDocument,
    clientRg,
    clientBirthDate,
    clientId,
    enqueueSnackbar,
  ]);
  // AddressForm Functions
  const handleSearchCep = useCallback(
    async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
      try {
        setAddressLoading(true);
        event.preventDefault();
        const response = await axios.get(
          `https://viacep.com.br/ws/${event.target.value}/json/`,
        );
        setAddressCity(response.data?.localidade);
        setAddressUf(response.data?.uf);
        setAddressStreet(response.data?.logradouro);
        setAddressDistrict(response.data?.bairro);
      } catch {
        enqueueSnackbar(`Não encontramos o CEP, tente novamente!`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      } finally {
        setAddressLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  const handleAddressSubmit = useCallback(async (): Promise<void> => {
    try {
      setAddressLoading(true);
      const dataAddress = {
        zipcode: addressCep,
        state: addressUf,
        city: addressCity,
        street: addressStreet,
        district: addressDistrict,
        number: addressNumber,
        nickname: addressNickname,
        ...(addressComplement ? { complement: addressComplement } : {}),
        main: true,
      };
      const schema = Yup.object().shape({
        zipcode: Yup.string().required('CEP é obrigatório'),
        nickname: Yup.string().required('Descrição é obrigatório'),
        street: Yup.string().required('Endereço é obrigatório'),
        number: Yup.string().required('Número é obrigatório'),
        district: Yup.string().required('Bairro é obrigatório'),
        city: Yup.string().required('Cidade é obrigatório'),
        state: Yup.string().required('Estado é obrigatório'),
        complement: Yup.string(),
      });
      await schema.validate(dataAddress, { abortEarly: false });

      if (addressId) {
        const response = await api.put<IAPIAddressRequest>(
          `/addresses/${addressId}`,
          dataAddress,
        );
        setAddressId(response.data.address.id);
        enqueueSnackbar(`Endereço atualizado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } else {
        const response = await api.post<IAPIAddressRequest>(
          `/addresses/${clientId}`,
          dataAddress,
        );
        setAddressId(response.data.address.id);
        enqueueSnackbar(`Endereço adicionado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
      } else {
        enqueueSnackbar('Erro inesperado ocorreu', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
      throw new Error(error);
    } finally {
      setAddressLoading(false);
    }
  }, [
    addressCep,
    addressUf,
    addressCity,
    addressStreet,
    addressDistrict,
    addressNumber,
    addressNickname,
    addressComplement,
    addressId,
    enqueueSnackbar,
    clientId,
  ]);
  // PhoneForm Functions
  const handlePhoneSubmit = useCallback(async () => {
    try {
      setPhoneLoading(true);
      const dataPhone = {
        nickname: phoneNickName,
        type: phoneType,
        areaCode: phoneAreaCode,
        phone,
        main: true,
      };
      const schema = Yup.object().shape({
        nickname: Yup.string().required('Descrição é obrigatório'),
        type: Yup.string().required('Tipo é obrigatório'),
        phone: Yup.string().required('Telefone é obrigatório'),
        areaCode: Yup.string().required('DDD é obrigatório'),
      });
      await schema.validate(dataPhone, { abortEarly: false });

      if (phoneId) {
        const response = await api.put<IAPIPhoneRequest>(
          `/phones/${phoneId}`,
          dataPhone,
        );
        setPhoneId(response.data.phone.id);
        enqueueSnackbar(`Telefone Atualizado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } else {
        const response = await api.post<IAPIPhoneRequest>(
          `/phones/${clientId}`,
          dataPhone,
        );
        setPhoneId(response.data.phone.id);
        enqueueSnackbar(`Telefone adicionado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
      } else {
        enqueueSnackbar('Erro inesperado ocorreu', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
      throw new Error(error);
    } finally {
      setPhoneLoading(false);
    }
  }, [
    phoneNickName,
    phoneType,
    phoneAreaCode,
    phone,
    phoneId,
    enqueueSnackbar,
    clientId,
  ]);

  // ContactForm Functions
  const handleContactSubmit = useCallback(async () => {
    try {
      setContactLoading(true);
      const dataContact = {
        name: contactName,
        phone: contactPhoneAreaCode + contactPhone,
        ...(contactEmail ? { email: contactEmail } : {}),
        coalition: contactCoalition,
        main: true,
      };
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        email: Yup.string().email('Precisamos de um Email valido'),
        phone: Yup.string().required('Telefone é obrigatório'),
        coalition: Yup.string().required('Coligação é obrigatório'),
      });
      await schema.validate(dataContact, { abortEarly: false });

      if (contactId) {
        const response = await api.put<IAPIContactRequest>(
          `/contacts/${contactId}`,
          dataContact,
        );
        setContactId(response.data.contact.id);
        enqueueSnackbar(`Contato atualizado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } else {
        const response = await api.post<IAPIContactRequest>(
          `/contacts/${clientId}`,
          dataContact,
        );
        setContactId(response.data.contact.id);
        enqueueSnackbar(`Contato adicionado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach(err => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
      } else {
        enqueueSnackbar('Erro inesperado ocorreu', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
      throw new Error(error);
    } finally {
      setContactLoading(false);
    }
  }, [
    contactName,
    contactPhoneAreaCode,
    contactPhone,
    contactEmail,
    contactCoalition,
    contactId,
    enqueueSnackbar,
    clientId,
  ]);

  // conclude functions

  const handleConcludeSubmit = useCallback(() => {
    try {
      handleSelectClient(clientData);
      handleToggle();
      enqueueSnackbar('Cliente Adicionado com sucesso.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } catch (error) {
      enqueueSnackbar('Erro inesperado ao cadastrar o cliente.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      throw new Error(error);
    }
  }, [clientData, enqueueSnackbar, handleSelectClient, handleToggle]);

  return (
    <Dialog open={open} onClose={handleToggle} maxWidth="lg" fullWidth>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <DialogContent style={{ minHeight: '50vh', maxHeight: '50vh' }} dividers>
        {getStepContent(activeStep)}
      </DialogContent>
      <DialogActions>
        {clientIsLoading || addressLoading || phoneLoading || contactLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              disabled={activeStep === 0}
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              Voltar
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddFullClient;
