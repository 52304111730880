import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  CircularProgress,
  Card,
  Grid,
  Box,
  Typography,
  Divider,
  CardContent,
  Paper,
  Theme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Line } from 'react-chartjs-2';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '13px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '18px',
    },
    paperBoxPagos: {
      width: '50%',
      padding: '1em',
      fontSize: '1.2em',
      fontWeight: 700,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    paperBoxPendente: {
      width: '50%',
      padding: '1em',
      fontSize: '1.2em',
      fontWeight: 700,
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
  }),
);

const data = {
  labels: [
    'jan',
    'fev',
    'mar',
    'mai',
    'abr',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
  ],
  datasets: [
    {
      label: 'Pago',
      data: [12, 19, 3, 5, 2, 16, 2, 10, 2, 3],
      fill: false,
      backgroundColor: 'rgb(34, 134, 58)',
      borderColor: 'rgba(34, 134, 58,0.2)',
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const FinancialBox: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    setIsLoading(false);
  }, [enqueueSnackbar]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      ) : (
        <Card
          variant="outlined"
          style={{
            padding: '0',
          }}
        >
          <Box className={classes.boxHeader}>
            <Grid container alignItems="center">
              <Grid item>
                <Typography className={classes.headerTitle}>
                  Financeiro
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="fullWidth" />
          <CardContent>
            <Grid container>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Paper elevation={1} className={classes.paperBoxPendente}>
                  Boletos Pendentes: 300
                </Paper>
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Paper className={classes.paperBoxPagos}>
                  Boletos pagos: 150
                </Paper>
              </Grid>
            </Grid>
            <Divider variant="fullWidth" style={{ margin: 10 }} />
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Line type="line" data={data} options={options} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default FinancialBox;
