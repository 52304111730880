import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Dashboard from '../layouts/Dashboard';
import SignIn from '../pages/SignIn';
import Auth from '../layouts/Auth';
import ListUsers from '../pages/Users/List';
import UserForm from '../pages/Users/Form';
import Profile from '../pages/Profile';
import RoleList from '../pages/Roles/List';
import RoleForm from '../pages/Roles/Form';
import BlocksList from '../pages/Blocks/List';
import BlockForm from '../pages/Blocks/Form';
import StreetList from '../pages/Streets/List';
import FormStreet from '../pages/Streets/Form';
import DepositsList from '../pages/Deposits/List';
import DepositForm from '../pages/Deposits/Form';
import FormDefunct from '../pages/Defuncts/Form';
import DefunctList from '../pages/Defuncts/List';
import ClientForm from '../pages/Clients/Form';
import ClientFormEdit from '../pages/Clients/Edit';
import ClientList from '../pages/Clients/List';
import Home from '../pages/Dashboard';
import ListActions from '../pages/Actions';
import ResultSearch from '../pages/Search/List';
import NotFound from '../pages/NotFound';
import Map from '../pages/Map';
import ContractForm from '../pages/Contract/Form';
import Cancel from '../pages/Contract/Cancel';
import BillList from '../pages/Bills/BillList';
import GenerateMaintenceBillLots from '../pages/Bills/GenerateMaintenceBillLots';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route Layout={Auth} path="/" exact component={SignIn} />
      <Route Layout={Dashboard} path="/dashboard" component={Home} isPrivate />
      <Route Layout={Dashboard} path="/users" component={ListUsers} isPrivate />
      <Route
        Layout={Dashboard}
        path="/form/users/:id?"
        component={UserForm}
        isPrivate
      />
      <Route Layout={Dashboard} path="/profile" component={Profile} isPrivate />
      <Route Layout={Dashboard} path="/roles" component={RoleList} isPrivate />
      <Route
        Layout={Dashboard}
        path="/form/roles/:id?"
        component={RoleForm}
        isPrivate
      />
      <Route
        Layout={Dashboard}
        path="/blocks"
        component={BlocksList}
        isPrivate
      />
      <Route
        Layout={Dashboard}
        path="/form/blocks/:id?"
        component={BlockForm}
        isPrivate
      />
      <Route
        Layout={Dashboard}
        path="/streets"
        component={StreetList}
        isPrivate
      />
      <Route
        Layout={Dashboard}
        path="/form/streets/:id?"
        component={FormStreet}
        isPrivate
      />
      <Route
        Layout={Dashboard}
        path="/deposits"
        isPrivate
        component={DepositsList}
      />
      <Route
        Layout={Dashboard}
        path="/form/deposits/:id?"
        isPrivate
        component={DepositForm}
      />
      <Route
        Layout={Dashboard}
        path="/defuncts"
        isPrivate
        component={DefunctList}
      />
      <Route
        Layout={Dashboard}
        path="/form/defuncts/:id?"
        isPrivate
        component={FormDefunct}
      />
      <Route
        Layout={Dashboard}
        path="/form/clients"
        isPrivate
        component={ClientForm}
      />
      <Route
        Layout={Dashboard}
        path="/clients"
        isPrivate
        component={ClientList}
      />
      <Route
        Layout={Dashboard}
        path="/form/client/:id"
        isPrivate
        component={ClientFormEdit}
      />
      <Route Layout={Dashboard} path="/bills" component={BillList} isPrivate />
      <Route
        Layout={Dashboard}
        path="/maintenance"
        component={GenerateMaintenceBillLots}
        isPrivate
      />
      <Route
        Layout={Dashboard}
        path="/actions"
        isPrivate
        component={ListActions}
      />
      <Route
        Layout={Dashboard}
        path="/results"
        isPrivate
        component={ResultSearch}
      />
      <Route Layout={Dashboard} path="/map" isPrivate component={Map} />
      <Route
        Layout={Dashboard}
        path="/contract/:id?"
        isPrivate
        component={ContractForm}
      />
      <Route
        Layout={Dashboard}
        path="/cancel/:contractId"
        isPrivate
        component={Cancel}
      />

      <Route Layout={Dashboard} isPrivate component={NotFound} />
    </Switch>
  );
};

export default Routes;
