import { format, parseISO } from 'date-fns';
import IRequestDefuncts from '../dtos/IRequestDefuncts';

const formatDefunct = (defunct: IRequestDefuncts): IRequestDefuncts => {
  return {
    ...defunct,
    formatedCreatedAt: format(parseISO(defunct.createdAt), 'dd/MM/yyyy'),
  };
};

export default formatDefunct;
