import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import api from '../../services/api';
import IRequestHistoric from '../../dtos/IRequestHistoric';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IRequest {
  historic: IRequestHistoric;
}

interface IPropsHistoric {
  editingHistoric: IRequestHistoric;
  handleUpdateItem: (historic: IRequestHistoric) => void;
  handleToggleModal: () => void;
  open: boolean;
}

const ModalEditHistoric: React.FC<IPropsHistoric> = ({
  editingHistoric,
  handleUpdateItem,
  open,
  handleToggleModal,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [note, setNote] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setNote(editingHistoric.note);
  }, [editingHistoric]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          note,
        };
        const schema = Yup.object().shape({
          note: Yup.string().required('Cometário é obrigatório'),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put<IRequest>(
          `/historic/${editingHistoric.id}`,
          data,
        );
        handleUpdateItem(response.data.historic);
        handleToggleModal();
        enqueueSnackbar(`Comentário atualizado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            enqueueSnackbar(`Erro: ${error.message}`, {
              variant: 'error',
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao atualizar, tente novamente', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      note,
      handleUpdateItem,
      enqueueSnackbar,
      editingHistoric,
      handleToggleModal,
    ],
  );

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg">
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Atualizando Comentário</DialogTitle>
        <IconButton onClick={handleToggleModal}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers style={{ width: '500px' }}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Comentário*"
                margin="normal"
                value={note}
                onChange={event => setNote(event.target.value)}
                multiline
                rows="12"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading}
          >
            {!isLoading ? 'Atualizar' : <CircularProgress />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditHistoric;
