import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiTrash2, FiShoppingCart } from 'react-icons/fi';
import IRequestItemContract from '../../../../dtos/IRequestItemContract';
import LocaleText from '../../../../LocaleText/index.json';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

interface IProps {
  items: IRequestItemContract[];
  handleDeleteItem(itemId: string): void;
  handleSelectItem(item: IRequestItemContract): void;
}

const ListItem: React.FC<IProps> = ({
  items,
  handleDeleteItem,
  handleSelectItem,
}) => {
  const classes = useStyles();

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  return (
    <Card style={{ marginTop: '25px' }}>
      <Box className={classes.boxHeader}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <Typography className={classes.headerTitle}>
              Itens do contrato
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <CardContent>
        <div ref={gridWrapperRef} style={{ padding: 16 }}>
          <DataGrid
            autoHeight
            disableColumnSelector
            disableSelectionOnClick
            localeText={LocaleText}
            rows={items as RowModel[]}
            columns={[
              {
                field: 'product',
                headerName: 'Objeto',
                flex: 1,
                align: 'center',
                renderCell: (params: ValueFormatterParams) => (
                  <div className={classes.ellipsis}>{params.row.product}</div>
                ),
              },
              {
                field: 'price',
                headerName: 'Valor',
                flex: 1,
                align: 'center',
                renderCell: (params: ValueFormatterParams) => (
                  <div className={classes.ellipsis}>
                    {`${params.row.formatedPrice}`}
                  </div>
                ),
              },
              {
                field: 'MaintenanceDate',
                headerName: 'Data de Cobrança Padrão',
                flex: 1,
                align: 'center',
                renderCell: (params: ValueFormatterParams) => (
                  <div className={classes.ellipsis}>
                    {`${params.row.formatedMaintenanceDate}`}
                  </div>
                ),
              },
              {
                field: 'actions',
                headerName: 'Ações',
                width: 150,
                disableColumnMenu: true,
                align: 'center',
                renderCell: (params: ValueFormatterParams) => (
                  <>
                    <IconButton
                      onClick={() =>
                        handleSelectItem(params.row as IRequestItemContract)
                      }
                    >
                      <FiShoppingCart />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteItem(params.row.id.toString())}
                    >
                      <FiTrash2 />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default ListItem;
