import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  Typography,
  Card,
  Grid,
  CircularProgress,
  Container,
  Button,
  CardContent,
  Divider,
  Box,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Pagination,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { format, parseISO } from 'date-fns';
import {
  FiEdit,
  FiPlusCircle,
  FiSlack,
  FiSunrise,
  FiSunset,
  FiTrash2,
} from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { IconType } from 'react-icons/lib';
import api from '../../../services/api';
import IRequestDefunctsActions from '../../../dtos/IRequestDefunctsActions';
import ModalAddDefunctAction from '../../../components/ModalAddDefunctAction';
import ModalEditDefunctAction from '../../../components/ModalEditDefunctAction';

const formatDefunctAction = (
  action: IRequestDefunctsActions,
): IRequestDefunctsActions => {
  return {
    ...action,
    formatedCreatedAt: format(parseISO(action.createdAt), 'dd/MM/yyyy'),
    formatedDateAction: format(parseISO(action.dateAction), 'dd/MM/yyyy'),
  };
};

const useStyles = makeStyles(() => {
  return {
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '7px',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

type TIconsStatus = {
  [key: string]: ReactElement<IconType>;
};

function getIconStatus(status: string): ReactElement<IconType> {
  const actionState: TIconsStatus = {
    SEPULTAMENTO: <FiSunset style={{ color: '#5850EC' }} size={20} />,
    EXUMAÇÃO: <FiSunrise style={{ color: '#22863a' }} size={20} />,
    TRANSLADADO: <FiSlack style={{ color: '#C70039' }} size={20} />,
  };

  return actionState[status];
}

type TTextStatus = {
  [key: string]: string;
};

function getTextStatus(status: string): string {
  const actionState: TTextStatus = {
    SEPULTAMENTO: 'Sepultamento',
    EXUMAÇÃO: 'Exumação',
    TRANSLADADO: 'Transladado',
  };

  return actionState[status];
}

interface IProps {
  defunctId: string;
}

interface IRequest {
  total: number;
  actions: IRequestDefunctsActions[];
}

const ActionDetail: React.FC<IProps> = ({ defunctId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [listDefunctsActions, setListDefunctsActions] = useState<
    IRequestDefunctsActions[]
  >([]);
  const [isProgressDelete, setIsProgressDelete] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [countPages, setCountPages] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [toogleModalAdd, setToogleModalAdd] = useState<boolean>(false);
  const [toogleModalEdit, setToogleModalEdit] = useState<boolean>(false);
  const [editingAction, setEditingAction] = useState<IRequestDefunctsActions>(
    {} as IRequestDefunctsActions,
  );

  useEffect(() => {
    setIsLoading(true);
    api
      .get<IRequest>('defuncts-actions', {
        params: {
          defunctId,
          page,
        },
      })
      .then(response => {
        const { total, actions } = response.data;
        const actionsFormatted = actions.map(action =>
          formatDefunctAction(action),
        );
        setListDefunctsActions(actionsFormatted);
        setTotalItem(total);
        setCountPages(Math.ceil(total / 5));
      })
      .catch(() => {
        enqueueSnackbar('Houve um erro ao carregar a lista, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, defunctId, enqueueSnackbar]);

  const handleToogleModalAdd = useCallback(() => {
    setToogleModalAdd(!toogleModalAdd);
  }, [toogleModalAdd]);

  const handleAddNewItemInList = useCallback(
    (action: IRequestDefunctsActions) => {
      const actionFormatted = formatDefunctAction(action);
      setListDefunctsActions(oldValue => [...oldValue, actionFormatted]);
    },
    [],
  );

  const handleDeleteAction = useCallback(
    async (actionId: string) => {
      try {
        setIsProgressDelete(true);
        await api.delete(`defuncts-actions/${actionId}`);
        const updatedList = listDefunctsActions.filter(
          action => action.id !== actionId,
        );

        setListDefunctsActions(updatedList);
        enqueueSnackbar('Ação deletada com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsProgressDelete(false);
      }
    },
    [listDefunctsActions, enqueueSnackbar],
  );

  const handleToggleModalEdit = useCallback(() => {
    setToogleModalEdit(!toogleModalEdit);
  }, [toogleModalEdit]);

  const handleUpdateItem = useCallback(
    (updateAction: IRequestDefunctsActions) => {
      const updatedList = listDefunctsActions.filter(
        item => item.id !== updateAction.id,
      );
      const actionFormatted = formatDefunctAction(updateAction);
      setListDefunctsActions([...updatedList, actionFormatted]);
    },
    [listDefunctsActions],
  );

  const handleEditingAction = useCallback(
    (action: IRequestDefunctsActions) => {
      setEditingAction(action);
      handleToggleModalEdit();
    },
    [handleToggleModalEdit],
  );

  return (
    <Container>
      <ModalAddDefunctAction
        open={toogleModalAdd}
        handleToogleModal={handleToogleModalAdd}
        addNewItemInList={handleAddNewItemInList}
        defunctId={defunctId}
      />
      <ModalEditDefunctAction
        editingAction={editingAction}
        handleToogleModal={handleToggleModalEdit}
        open={toogleModalEdit}
        addNewItemInList={handleUpdateItem}
      />
      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <Typography variant="h3" style={{ fontWeight: 'bold' }}>
            Ações
          </Typography>
          <Typography variant="body1">Total: {totalItem}</Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            style={{ fontWeight: 700 }}
            startIcon={<FiPlusCircle />}
            onClick={handleToogleModalAdd}
          >
            Nova ação
          </Button>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Timeline align="alternate">
            {listDefunctsActions.map(action => (
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {action.formatedDateAction}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot>{getIconStatus(action.status)}</TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Card elevation={3}>
                    <CardContent>
                      <Typography variant="h6" component="h1">
                        {`${action.nroRegister} - ${getTextStatus(
                          action.status,
                        )}`}
                      </Typography>
                      <Typography>{action.note}</Typography>
                    </CardContent>
                    <Divider />
                    <Box className={classes.boxAction}>
                      <IconButton
                        onClick={() => handleDeleteAction(action.id)}
                        disabled={isProgressDelete}
                      >
                        <FiTrash2 className={classes.trashButton} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleEditingAction(action)}
                        disabled={isProgressDelete}
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                    </Box>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          <Pagination
            count={countPages}
            page={page}
            onChange={(event, value) => {
              setPage(value);
            }}
            color="primary"
          />
        </>
      )}
    </Container>
  );
};

export default ActionDetail;
