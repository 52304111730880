import React, { useCallback, useEffect, useState } from 'react';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPlusCircle, FiStar, FiTrash2 } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import IRequestContact from '../../dtos/IRequestContact';
import api from '../../services/api';
import ModalAddContacts from '../ModalAddContacts';
import ModalEditContacts from '../ModalEditContact';
import LocaleText from '../../LocaleText/index.json';

interface IProps {
  contacts: IRequestContact[];
}

interface IParams {
  id: string;
}

interface IApiRequestDeleteContact {
  newMainContact?: IRequestContact;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const ListClientContacts: React.FC<IProps> = ({ contacts }) => {
  const { id } = useParams<IParams>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [listContacts, setListContacts] = useState<IRequestContact[]>([]);
  const [isProgressDelete, setIsProgressDelete] = useState<boolean>(false);
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [editingContact, setEditingContact] = useState<IRequestContact>(
    {} as IRequestContact,
  );
  useEffect(() => {
    setListContacts(contacts);
  }, [contacts]);

  const handleRemoveMainInList = useCallback(() => {
    const oldMain = listContacts.find(item => item.main === true);

    if (oldMain) {
      oldMain.main = false;
      const updatedOldList = listContacts.filter(
        item => item.id !== oldMain.id,
      );

      setListContacts(
        [...updatedOldList, oldMain].sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
      );
    }
  }, [listContacts]);

  const handleUpdateList = useCallback(
    (newContact: IRequestContact) => {
      if (newContact.main === true) {
        handleRemoveMainInList();
      }
      setListContacts(oldValue =>
        [...oldValue, newContact].sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
      );
    },
    [handleRemoveMainInList],
  );

  const handleUpdateItemInList = useCallback(
    (updatedContact: IRequestContact) => {
      const updatedList = listContacts.filter(
        item => item.id !== updatedContact.id,
      );

      if (updatedContact.main === true) {
        handleRemoveMainInList();
      }

      setListContacts(
        [...updatedList, updatedContact].sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
      );
    },
    [handleRemoveMainInList, listContacts],
  );

  const handleDeleteContact = useCallback(
    async (contactId: string) => {
      try {
        setIsProgressDelete(true);
        const response = await api.delete<IApiRequestDeleteContact>(
          `contacts/${contactId}`,
        );

        const updatedList = listContacts.filter(
          contact => contact.id !== contactId,
        );
        if (response.data.newMainContact) {
          const deleteList = updatedList.filter(
            contact => contact.id !== response.data.newMainContact?.id,
          );
          setListContacts(
            [...deleteList, response.data.newMainContact].sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            }),
          );
        } else {
          setListContacts(updatedList);
        }

        enqueueSnackbar('Contato deletado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsProgressDelete(false);
      }
    },
    [listContacts, enqueueSnackbar],
  );

  const handleToggleModal = useCallback(() => {
    setOpenModalAdd(!openModalAdd);
  }, [openModalAdd]);

  const handleToggleEdit = useCallback(() => {
    setOpenModalEdit(!openModalEdit);
  }, [openModalEdit]);

  const handleEditingContact = useCallback(
    (contact: IRequestContact) => {
      setEditingContact(contact);
      handleToggleEdit();
    },
    [handleToggleEdit],
  );

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  return (
    <>
      <ModalAddContacts
        handleToggleModal={handleToggleModal}
        handleUpdateItem={handleUpdateList}
        open={openModalAdd}
        clientId={id}
      />
      <ModalEditContacts
        open={openModalEdit}
        editingContact={editingContact}
        handleUpdateItem={handleUpdateItemInList}
        handleToggleModal={handleToggleEdit}
      />
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>Contatos</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={handleToggleModal}
              >
                Novo Contato
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              localeText={LocaleText}
              rows={listContacts as RowModel[]}
              columns={[
                {
                  field: 'name',
                  headerName: 'Nome',
                  flex: 1,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div className={classes.ellipsis}>
                      {!params.value ? 'Sem apelido' : params.value}
                      {!params.row.main ? (
                        ' '
                      ) : (
                        <Tooltip title="Principal">
                          <FiStar
                            color="#22863A"
                            size="1.5em"
                            strokeWidth="3"
                          />
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
                {
                  field: 'coalition',
                  headerName: 'Coligação',
                  flex: 0.5,
                  align: 'left',
                },
                {
                  field: 'email',
                  headerName: 'E-mail',
                  flex: 1,
                  align: 'left',
                },

                {
                  field: 'phone',
                  headerName: 'Telefone',
                  flex: 0.5,
                  align: 'left',
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 150,
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <IconButton
                        onClick={() =>
                          handleEditingContact(params.row as IRequestContact)
                        }
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteContact(params.row.id.toString())
                        }
                        disabled={isProgressDelete}
                      >
                        <FiTrash2 className={classes.trashButton} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              pageSize={10}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListClientContacts;
