import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FiFolderPlus, FiPlusCircle } from 'react-icons/fi';
import IRequestDeposits from '../../dtos/IRequestDeposits';
import IRequestStreets from '../../dtos/IRequestStreets';
import DepositCard from './DepositCard';
import noDataSvg from '../../assets/noData.svg';

interface IDepositProps {
  deposits: IRequestDeposits[];
  selectedStreet: IRequestStreets;
  total: number;
  page: number;
  countPages: number;
  setPage(value: number): void;
  handleToggleModal(): void;
  handleToggleAddlots(): void;
}

const DepositList: React.FC<IDepositProps> = ({
  deposits,
  selectedStreet,
  total,
  page,
  countPages,
  setPage,
  handleToggleModal,
  handleToggleAddlots,
}) => {
  return (
    <Card>
      <Box style={{ padding: '16px' }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">{`Jazigos da ${selectedStreet.name} - Total: ${total}`}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<FiPlusCircle />}
                  onClick={() => handleToggleModal()}
                >
                  Novo Jazigo
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<FiFolderPlus />}
                  onClick={() => handleToggleAddlots()}
                >
                  Jazigo em Lote
                </Button>
              </Grid>
              <Grid item>
                <Pagination
                  count={countPages}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <CardContent>
        {deposits.length > 0 ? (
          <div>
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fill, 55px)"
              gridGap="0.15em"
            >
              {deposits.map(deposit => (
                <DepositCard deposit={deposit} />
              ))}
            </Box>
          </div>
        ) : (
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Grid item>
              <img src={noDataSvg} alt="no_data" width="250" />
            </Grid>
            <Grid item>
              <Typography>Nunhum Jazigos encontrado nessa rua</Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
      <Divider />
    </Card>
  );
};

export default DepositList;
