import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import IRequestPhone from '../../dtos/IRequestPhone';
import api from '../../services/api';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IRequest {
  phone: IRequestPhone;
}

interface IPropsPhone {
  editingPhone: IRequestPhone;
  handleUpdateItem: (phone: IRequestPhone) => void;
  handleToggleModal: () => void;
  open: boolean;
}

const ModalEditPhones: React.FC<IPropsPhone> = ({
  editingPhone,
  handleUpdateItem,
  open,
  handleToggleModal,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [nickName, setNickName] = useState<string>('');
  const [phoneType, setPhoneType] = useState<string>('');
  const [areaCode, setAreaCode] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [main, setMain] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setNickName(editingPhone.nickname);
    setPhoneType(editingPhone.type);
    setPhone(editingPhone.phone);
    setAreaCode(editingPhone.areaCode);
    setMain(editingPhone.main);
  }, [editingPhone]);

  const handleChangeSubscription = (
    event: ChangeEvent<{ value: unknown }>,
  ): void => {
    setPhoneType(event.target.value as string);
  };

  const handleChangeMain = (event: ChangeEvent<HTMLInputElement>): void => {
    setMain(event.target.checked);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          nickname: nickName,
          type: phoneType,
          phone,
          areaCode,
          main,
        };
        const schema = Yup.object().shape({
          nickname: Yup.string().required('Descrição é obrigatório'),
          type: Yup.string().required('Tipo é obrigatório'),
          phone: Yup.string().required('Telefone é obrigatório'),
          areaCode: Yup.string().required('DDD é obrigatório'),
          main: Yup.boolean().required(),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put<IRequest>(
          `/phones/${editingPhone.id}`,
          data,
        );
        handleUpdateItem(response.data.phone);
        enqueueSnackbar(`Telefone atualizado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            enqueueSnackbar(`Erro: ${error.message}`, {
              variant: 'error',
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao cadastrar o endereço', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      nickName,
      phoneType,
      phone,
      areaCode,
      main,
      editingPhone.id,
      handleUpdateItem,
      enqueueSnackbar,
    ],
  );

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg">
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Novo Telefone</DialogTitle>
        <IconButton onClick={handleToggleModal}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Descrição"
                required
                margin="normal"
                value={nickName}
                onChange={event => setNickName(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl
                fullWidth
                margin="normal"
                required
                variant="outlined"
              >
                <InputLabel id="subscription-label">Tipo</InputLabel>
                <Select
                  labelId="subscription-label"
                  id="subscription-select"
                  label="Status"
                  required
                  variant="outlined"
                  value={phoneType}
                  onChange={handleChangeSubscription}
                >
                  <MenuItem value="TELEPHONE" selected>
                    Telefone
                  </MenuItem>
                  <MenuItem value="CELLPHONE">Celular</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1} xs={4}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="DDD"
                margin="normal"
                value={areaCode}
                onChange={event =>
                  setAreaCode(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 2 }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Número"
                margin="normal"
                value={phone}
                onChange={event =>
                  setPhone(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 9 }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={main}
                    onChange={handleChangeMain}
                    name="main"
                    color="primary"
                  />
                }
                label="Tornar esse telefone como Principal?"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading}
          >
            {!isLoading ? 'Atualizar' : <CircularProgress />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditPhones;
