import React, { useCallback, useEffect, useState } from 'react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import api from '../../services/api';
import IRequestBlocks from '../../dtos/IRequestBlocks';
import IRequestStreets from '../../dtos/IRequestStreets';
import BlockList from './BlockList';
import DepositList from './DepositList';
import IRequestDeposits from '../../dtos/IRequestDeposits';
import streetSvg from '../../assets/street.svg';
import Filter from './Filter';
import ModalAddDeposit from '../../components/ModalAddDeposit';
import ModalAddDepositLots from '../../components/ModaAddDepositLots';

interface IRequest {
  blocks: IRequestBlocks[];
}

interface IRequestDeposit {
  deposits: IRequestDeposits[];
  total: number;
}

const Map: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [blocksList, setBlockList] = useState<IRequestBlocks[]>([]);
  const [depositList, setDepositList] = useState<IRequestDeposits[]>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [arrayModelFilter, setArrayModelFilter] = useState<string[]>([]);
  const [arrayStatusFilter, setArrayStatusFilter] = useState<string[]>([]);
  const [hasOssuaryFilter, setHasOssuaryFilter] = useState<boolean>(false);
  const [depositIsOpenFilter, setDepositIsOpenFilter] = useState<boolean>(
    false,
  );
  const [clientFilter, setClientFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [openModalDeposit, setOpenModalDeposit] = useState<boolean>(false);
  const [countPages, setCountPages] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [inProgges, setInProgges] = useState<boolean>(false);
  const [selectedStreet, setSelectedStreet] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );
  const [openModalAddlots, setOpenModalAddlots] = useState<boolean>(false);

  useEffect(() => {
    api
      .get<IRequest>('blocks/actives')
      .then(response => {
        setBlockList(response.data.blocks);
        enqueueSnackbar('Quadaras e Ruas encontradas com sucesso.', {
          variant: 'success',
          autoHideDuration: 1000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .catch(() => {
        enqueueSnackbar('Erro ao buscar as Quadaras e Ruas.', {
          variant: 'error',
          autoHideDuration: 1000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (selectedStreet.id) {
      setInProgges(true);
      api
        .get<IRequestDeposit>(`/deposits-street/${selectedStreet.id}`, {
          params: {
            page,
            ...(hasOssuaryFilter ? { hasOssuary: hasOssuaryFilter } : {}),
            ...(arrayModelFilter ? { modelArray: arrayModelFilter } : {}),
            ...(arrayStatusFilter ? { statusArray: arrayStatusFilter } : {}),
            ...(clientFilter ? { clientId: clientFilter } : {}),
            ...(depositIsOpenFilter ? { isOpen: depositIsOpenFilter } : {}),
          },
        })
        .then(response => {
          setDepositList(response.data.deposits);
          setTotalItem(response.data.total);
          setCountPages(Math.ceil(response.data.total / 100));
          if (response.data.deposits.length === 0) {
            enqueueSnackbar(
              'Não econtramos jazigos nessa rua, verifique os filtros e a rua selecionada.',
              {
                variant: 'info',
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              },
            );
            return;
          }
          enqueueSnackbar('Jazigos encontrados com sucesso.', {
            variant: 'success',
            autoHideDuration: 1000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        })
        .catch(() => {
          enqueueSnackbar('Erro ao buscar os Jazgios.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        })
        .finally(() => {
          setInProgges(false);
        });
    }
  }, [
    selectedStreet,
    page,
    arrayModelFilter,
    arrayStatusFilter,
    hasOssuaryFilter,
    clientFilter,
    enqueueSnackbar,
    depositIsOpenFilter,
  ]);

  const handleSelectedStreet = useCallback((street: IRequestStreets) => {
    setSelectedStreet(street);
  }, []);

  const handleToggleModalFilter = useCallback(() => {
    setOpenFilter(!openFilter);
  }, [openFilter]);

  const handleApplyFilter = useCallback(
    (
      model: string[],
      status: string[],
      hasOssuary: boolean,
      clientId: string,
      isOpen: boolean,
    ) => {
      setArrayModelFilter(model);
      setArrayStatusFilter(status);
      setHasOssuaryFilter(hasOssuary);
      setClientFilter(clientId);
      setDepositIsOpenFilter(isOpen);
    },
    [],
  );

  const handleToggleModalDeposit = useCallback(() => {
    setOpenModalDeposit(!openModalDeposit);
  }, [openModalDeposit]);

  const handleToggleAddlots = useCallback(() => {
    setOpenModalAddlots(!openModalAddlots);
  }, [openModalAddlots]);

  const handleUpdateTotalItem = useCallback(
    (newTotal: number) => {
      setTotalItem(newTotal + totalItem);
    },
    [totalItem],
  );

  const handleAddNewDepositInList = useCallback((deposit: IRequestDeposits) => {
    setDepositList(oldValue => [...oldValue, deposit]);
  }, []);

  const handleAddBlockInList = useCallback((block: IRequestBlocks) => {
    setBlockList(oldValue => [...oldValue, block]);
  }, []);

  const handleAddStreetInList = useCallback(
    (street: IRequestStreets) => {
      const findedBlock = blocksList.find(item => item.id === street.block.id);

      if (findedBlock) {
        setBlockList(oldValue =>
          oldValue.map(item => {
            return item.id === findedBlock.id
              ? { ...findedBlock, streets: findedBlock.streets.concat(street) }
              : item;
          }),
        );
      }
    },
    [blocksList],
  );

  return (
    <div>
      <ModalAddDeposit
        open={openModalDeposit}
        selectedStreet={selectedStreet}
        handleAddNewDepositInList={handleAddNewDepositInList}
        handleToggleModal={handleToggleModalDeposit}
      />
      <ModalAddDepositLots
        handleToggle={handleToggleAddlots}
        open={openModalAddlots}
        handleUpdateItem={handleAddNewDepositInList}
        handleUpdateTotalItem={handleUpdateTotalItem}
        selectedStreet={selectedStreet}
      />
      <Filter
        open={openFilter}
        handleToggleModalFilter={handleToggleModalFilter}
        handleApplyFilter={handleApplyFilter}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item md={3} xs={12} sm={12}>
          <BlockList
            blocks={blocksList}
            handleSelectedStreet={handleSelectedStreet}
            handleToggleModalFilter={handleToggleModalFilter}
            handleAddBlockInList={handleAddBlockInList}
            handleAddStreetInList={handleAddStreetInList}
          />
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          {selectedStreet.id ? (
            <>
              {!inProgges ? (
                <>
                  <DepositList
                    deposits={depositList}
                    selectedStreet={selectedStreet}
                    page={page}
                    setPage={setPage}
                    countPages={countPages}
                    total={totalItem}
                    handleToggleModal={handleToggleModalDeposit}
                    handleToggleAddlots={handleToggleAddlots}
                  />
                </>
              ) : (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                >
                  <Grid item>
                    <Typography variant="h6">Buscando Jazigos</Typography>
                  </Grid>
                  <Grid item>
                    <LinearProgress
                      color="secondary"
                      style={{ maxWidth: '100%', width: '300px' }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Grid item>
                <img src={streetSvg} alt="no_data" width="300" />
              </Grid>
              <Grid item>
                <Typography>Comece selecionando a rua.</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Map;
