import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPlusCircle, FiStar, FiTrash2 } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import IRequestAddress from '../../dtos/IRequestAddress';
import ModalAddAddress from '../ModalAddAddress';
import api from '../../services/api';
import ModalEditAddress from '../ModalEditAddress';
import LocaleText from '../../LocaleText/index.json';

interface IProps {
  addresses: IRequestAddress[];
}

interface IParams {
  id: string;
}

interface IApiRequestDeleteAddress {
  newMainAdress?: IRequestAddress;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const ListClientAddress: React.FC<IProps> = ({ addresses }) => {
  const { id } = useParams<IParams>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [listAddress, setListAddress] = useState<IRequestAddress[]>([]);
  const [isProgressDelete, setIsProgressDelete] = useState<boolean>(false);
  const [editingAddress, setEditingAddress] = useState<IRequestAddress>(
    {} as IRequestAddress,
  );

  const handleToggleModalAdd = useCallback(() => {
    setOpenModalAdd(!openModalAdd);
  }, [openModalAdd]);

  const handleToggleModalEdit = useCallback(() => {
    setOpenModalEdit(!openModalEdit);
  }, [openModalEdit]);

  useEffect(() => {
    setListAddress(addresses);
  }, [addresses]);

  const handleRemoveMainInList = useCallback(() => {
    const oldMain = listAddress.find(item => item.main === true);

    if (oldMain) {
      oldMain.main = false;
      const updatedOldList = listAddress.filter(item => item.id !== oldMain.id);

      setListAddress(
        [...updatedOldList, oldMain].sort((a, b) => {
          if (a.nickname > b.nickname) {
            return 1;
          }
          if (a.nickname < b.nickname) {
            return -1;
          }
          return 0;
        }),
      );
    }
  }, [listAddress]);

  const handleUpdateList = useCallback(
    (newAddress: IRequestAddress) => {
      if (newAddress.main === true) {
        handleRemoveMainInList();
      }
      setListAddress(oldValue =>
        [...oldValue, newAddress].sort((a, b) => {
          if (a.nickname > b.nickname) {
            return 1;
          }
          if (a.nickname < b.nickname) {
            return -1;
          }
          return 0;
        }),
      );
    },
    [handleRemoveMainInList],
  );

  const handleUpdateItemInList = useCallback(
    (updatedAddress: IRequestAddress) => {
      const updatedList = listAddress.filter(
        item => item.id !== updatedAddress.id,
      );

      if (updatedAddress.main === true) {
        handleRemoveMainInList();
      }

      setListAddress(
        [...updatedList, updatedAddress].sort((a, b) => {
          if (a.nickname > b.nickname) {
            return 1;
          }
          if (a.nickname < b.nickname) {
            return -1;
          }
          return 0;
        }),
      );
    },
    [handleRemoveMainInList, listAddress],
  );

  const handleEditing = useCallback(
    (address: IRequestAddress) => {
      setEditingAddress(address);
      handleToggleModalEdit();
    },
    [handleToggleModalEdit],
  );

  const handleDeleteAddress = useCallback(
    async (addressId: string) => {
      try {
        setIsProgressDelete(true);
        const response = await api.delete<IApiRequestDeleteAddress>(
          `addresses/${addressId}`,
        );
        const updatedList = listAddress.filter(
          address => address.id !== addressId,
        );
        if (response.data.newMainAdress) {
          const deleteList = updatedList.filter(
            address => address.id !== response.data.newMainAdress?.id,
          );
          setListAddress(
            [...deleteList, response.data.newMainAdress].sort((a, b) => {
              if (a.nickname > b.nickname) {
                return 1;
              }
              if (a.nickname < b.nickname) {
                return -1;
              }
              return 0;
            }),
          );
        } else {
          setListAddress(updatedList);
        }

        enqueueSnackbar('Endereço deletado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Erro ao deletar, tente novamente mais tarde.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsProgressDelete(false);
      }
    },
    [listAddress, enqueueSnackbar],
  );

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  return (
    <>
      <ModalAddAddress
        clientId={id}
        open={openModalAdd}
        handleToggleModal={handleToggleModalAdd}
        handleUpdateList={handleUpdateList}
      />
      <ModalEditAddress
        handleUpdateItemInList={handleUpdateItemInList}
        handleToggleModal={handleToggleModalEdit}
        address={editingAddress}
        open={openModalEdit}
      />
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>Endereços</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={handleToggleModalAdd}
              >
                Novo Endereço
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              localeText={LocaleText}
              rows={listAddress as RowModel[]}
              columns={[
                {
                  field: 'nickname',
                  headerName: 'Apelido',
                  flex: 0.8,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div className={classes.ellipsis}>
                      {!params.value ? 'Sem apelido' : params.value}
                      {!params.row.main ? (
                        ' '
                      ) : (
                        <Tooltip title="Principal">
                          <FiStar
                            color="#22863A"
                            size="1.5em"
                            strokeWidth="3"
                          />
                        </Tooltip>
                      )}
                    </div>
                  ),
                },
                {
                  field: 'zipcode',
                  headerName: 'CEP',
                  flex: 0.8,
                },
                { field: 'street', headerName: 'Rua', flex: 1 },
                { field: 'number', headerName: 'Nº', flex: 0.5 },
                { field: 'district', headerName: 'Bairro', flex: 1 },
                {
                  field: 'city',
                  headerName: 'Cidade',
                  flex: 1,
                },

                {
                  field: 'state',
                  headerName: 'Estado',
                  flex: 0.5,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',

                  width: 150,
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <IconButton
                        onClick={() => {
                          handleEditing(params.row as IRequestAddress);
                        }}
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteAddress(params.row.id.toString())
                        }
                        disabled={isProgressDelete}
                      >
                        <FiTrash2 className={classes.trashButton} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              pageSize={10}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListClientAddress;
