import React, {
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
  FormEvent,
} from 'react';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Theme,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';

import { FiX, FiXCircle } from 'react-icons/fi';
import ModalAddFullClient from '../ModalAddFullClient';
import IRequestBlocks from '../../dtos/IRequestBlocks';
import IRequestClient from '../../dtos/IRequestClient';
import IRequestStreets from '../../dtos/IRequestStreets';
import FieldNumberFormatCustom from '../FieldNumberFormatCustom';
import api from '../../services/api';
import IRequestDeposits from '../../dtos/IRequestDeposits';

interface IRequestBlock {
  blocks: IRequestBlocks[];
}

interface IRequestClients {
  clients: IRequestClient[];
}

interface IRequestStreet {
  streets: IRequestStreets[];
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    link: {
      margin: 0,
      padding: 0,
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  };
});

interface IProps {
  open: boolean;
  selectedStreet: IRequestStreets;
  handleToggleModal(): void;
  handleAddNewDepositInList(deposit: IRequestDeposits): void;
}

const ModalAddDeposit: React.FC<IProps> = ({
  selectedStreet,
  open,
  handleToggleModal,
  handleAddNewDepositInList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [openModalClient, setOpenModalClient] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [blocks, setBlocks] = useState<IRequestBlocks[]>([]);
  const [sector, setSector] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [valueSold, setValueSold] = useState<string>('');
  const [standardValue, setStandardValue] = useState<string>('');
  const [maintenance, setMaintenance] = useState<string>('');
  const [streets, setStreets] = useState<IRequestStreets[]>([]);
  const [generateAutomaticName, setGenerateAutomaticName] = useState<boolean>(
    true,
  );
  const [hasOssuary, setHasOssuary] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [streetSelected, setStreetSelected] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );

  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );
  const [clients, setClients] = useState<IRequestClient[]>([]);
  const [clientSelected, setClientSelected] = useState<IRequestClient>(
    {} as IRequestClient,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleToggleModalAddFullClient = useCallback(() => {
    setOpenModalClient(!openModalClient);
  }, [openModalClient]);

  const handleSelectClient = useCallback((client: IRequestClient) => {
    setClientSelected(client);
  }, []);

  useEffect(() => {
    setName('');
    setModel('');
    setIsOpen(false);
    setClientSelected({} as IRequestClient);
    if (selectedStreet.id) {
      setBlockSelected(selectedStreet.block);
      setStreetSelected(selectedStreet);
    } else {
      setBlockSelected({} as IRequestBlocks);
      setStreetSelected({} as IRequestStreets);
    }
    setHasOssuary(false);
    setStatus('');
    setGenerateAutomaticName(true);
    setValueSold('');
    setStandardValue('');
    setMaintenance('');
  }, [open, selectedStreet]);

  useEffect(() => {
    api
      .get<IRequestClients>('clients/active')
      .then(response => {
        setClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar ao clientes, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    api
      .get<IRequestBlock>('blocks/actives')
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (blockSelected?.id) {
      api
        .get<IRequestStreet>(`streets/${blockSelected.id}/actives`)
        .then(response => {
          setStreets(response.data.streets);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as ruas, tente novamente.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [blockSelected, enqueueSnackbar, selectedStreet]);

  useEffect(() => {
    api
      .get<IRequestClients>('clients/active')
      .then(response => {
        setClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar ao clientes, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  const handleChangeHasOssuary = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setHasOssuary(event.target.checked);
  };

  const handleChangeGenerateName = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setGenerateAutomaticName(event.target.checked);
  };

  const handleChangeStatus = (event: ChangeEvent<{ value: unknown }>): void => {
    setStatus(event.target.value as string);
  };

  const handleChangeModel = (event: ChangeEvent<{ value: unknown }>): void => {
    setModel(event.target.value as string);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          ...(note
            ? {
                note,
              }
            : {}),
          hasOssuary,
          ...(generateAutomaticName
            ? { generateAutomaticName }
            : { name: name.toUpperCase() }),
          ...(sector
            ? {
                sector,
              }
            : {}),
          status,
          model,
          ...(valueSold
            ? {
                valueSold,
              }
            : {}),
          ...(standardValue
            ? {
                standardValue,
              }
            : {}),
          ...(maintenance
            ? {
                maintenance,
              }
            : {}),
          streetId: streetSelected.id,
          ...(clientSelected.id
            ? {
                clientId: clientSelected.id,
              }
            : {}),
          isOpen,
        };
        const schema = Yup.object().shape({
          generateAutomaticName: Yup.boolean(),
          name: Yup.string().when('generateAutomaticName', {
            is: val => !val,
            then: Yup.string().required('Nome é obrigatório'),
            otherwise: Yup.string(),
          }),
          sector: Yup.string(),
          streetId: Yup.string().required('Rua é obrigatório'),
          clientId: Yup.string().when('status', {
            is: val => val === 'SOLD',
            then: Yup.string().required('Cliente é obrigatório'),
            otherwise: Yup.string(),
          }),
          hasOssuary: Yup.boolean().required('Gaveta é obrigatório'),
          note: Yup.string(),
          valueSold: Yup.number(),
          standardValue: Yup.number(),
          maintenance: Yup.number(),
          status: Yup.string().required('Status é obrigatório'),
          model: Yup.string().required('Modelo é obrigatório'),
          isOpen: Yup.boolean().required('Jazigo es´ta aberto é obrigatório'),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.post('deposits', data);
        handleToggleModal();
        handleAddNewDepositInList(response.data);
        enqueueSnackbar('Jazigo adicionado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      note,
      hasOssuary,
      generateAutomaticName,
      name,
      sector,
      status,
      model,
      valueSold,
      standardValue,
      maintenance,
      streetSelected.id,
      clientSelected.id,
      isOpen,
      handleToggleModal,
      handleAddNewDepositInList,
      enqueueSnackbar,
    ],
  );

  return (
    <Dialog open={open} maxWidth="lg" onClose={handleToggleModal}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={handleToggleModal}
          >
            <FiXCircle />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Novo Jazigo
          </Typography>
          <Button
            variant="contained"
            disableElevation
            type="submit"
            color="primary"
            onClick={handleSubmit}
          >
            {isLoading ? <CircularProgress color="primary" /> : 'Salvar'}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nome"
                required
                name="name"
                onChange={event => setName(event.target.value)}
                value={name}
                disabled={generateAutomaticName}
                helperText={
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB"
                        color="primary"
                        onChange={handleChangeGenerateName}
                        value={generateAutomaticName}
                        checked={generateAutomaticName}
                        disabled={false}
                      />
                    }
                    label="Gerar nome automático"
                  />
                }
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Autocomplete
                options={clients}
                value={clientSelected}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, val) => option.name === val.name}
                style={{ width: '100%' }}
                disabled={status === 'INACTIVE'}
                onChange={(event, client) => {
                  if (client) {
                    setClientSelected(client);
                    setStatus('SOLD');
                  } else {
                    setClientSelected({} as IRequestClient);
                    setStatus('');
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Cliente"
                    helperText={
                      <Typography>
                        Novo Cliente,
                        <Button
                          className={classes.link}
                          color="primary"
                          onClick={handleToggleModalAddFullClient}
                        >
                          Criar
                        </Button>
                      </Typography>
                    }
                  />
                )}
                noOptionsText="Não encontramos nenhum cliente ativo, verifique acessando a lista"
              />
            </Grid>

            <Grid item md={4} sm={12} xs={12}>
              <Autocomplete
                options={blocks}
                value={blockSelected}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, val) => option.name === val.name}
                style={{ width: '100%' }}
                disabled={!!selectedStreet}
                onChange={(event, block) => {
                  if (block) {
                    setBlockSelected(block);
                    setStreetSelected({} as IRequestStreets);
                  } else {
                    setBlockSelected({} as IRequestBlocks);
                    setStreetSelected({} as IRequestStreets);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Quadras"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhuma quadra ativa, verifique acessando a lista"
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Autocomplete
                options={streets}
                value={streetSelected}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, val) => option.name === val.name}
                style={{ width: '100%' }}
                disabled={!!selectedStreet}
                onChange={(event, street) => {
                  if (street) {
                    setStreetSelected(street);
                  } else {
                    setStreetSelected({} as IRequestStreets);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Ruas"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhuma rua ativa, verifique acessando a lista"
              />
            </Grid>

            <Grid item md={4} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                select
                label="Setor"
                name="sector"
                onChange={event => setSector(event.target.value as string)}
                value={sector}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton onClick={() => setSector('')}>
                          <FiX />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="D">D</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="H">H</MenuItem>
                <MenuItem value="I">I</MenuItem>
                <MenuItem value="J">J</MenuItem>
                <MenuItem value="K">K</MenuItem>
                <MenuItem value="L">L</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ padding: '10px' }}>
            <Grid item md={6} sm={12} xs={12}>
              <FormControl
                component="fieldset"
                disabled={status === 'INACTIVE'}
              >
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  aria-label="status"
                  name="status"
                  value={status}
                  onChange={handleChangeStatus}
                >
                  <FormControlLabel
                    value="NOTBUILT"
                    control={<Radio />}
                    label="Não construído"
                  />
                  <FormControlLabel
                    value="NOTSOLD"
                    control={<Radio />}
                    label="Não Vendido"
                  />
                  <FormControlLabel
                    value="SOLD"
                    control={<Radio disabled={!clientSelected.id} />}
                    label="Vendido"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <FormControl
                component="fieldset"
                disabled={status === 'INACTIVE'}
              >
                <FormLabel component="legend">Modelo</FormLabel>
                <RadioGroup
                  row
                  aria-label="Modelo"
                  name="Modelo"
                  value={model}
                  onChange={handleChangeModel}
                >
                  <FormControlLabel
                    value="ADULT"
                    control={<Radio />}
                    label="Adulto"
                  />
                  <FormControlLabel
                    value="DOUBLE"
                    control={<Radio />}
                    label="Duplo"
                  />
                  <FormControlLabel
                    value="AMERICAN"
                    control={<Radio />}
                    label="Americano"
                  />
                  <FormControlLabel
                    value="NOBLE"
                    control={<Radio />}
                    label="Nobre"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={handleChangeHasOssuary}
                    value={hasOssuary}
                    checked={hasOssuary}
                  />
                }
                label="Possui Ossuário"
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={event => setIsOpen(event.target.checked)}
                    value={isOpen}
                    checked={isOpen}
                  />
                }
                label="Jazigo Aberto"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Observação"
                name="note"
                multiline
                rows="4"
                onChange={event => setNote(event.target.value)}
                value={note}
                disabled={status === 'INACTIVE'}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Valor Padrão"
                name="name"
                onChange={event => setStandardValue(event.target.value)}
                value={standardValue}
                InputProps={{
                  inputComponent: FieldNumberFormatCustom as any,
                }}
                disabled={status === 'INACTIVE'}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Valor Vendindo"
                name="valueSold"
                onChange={event => setValueSold(event.target.value)}
                value={valueSold}
                InputProps={{
                  inputComponent: FieldNumberFormatCustom as any,
                }}
                disabled={status === 'INACTIVE'}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Manutenção"
                name="name"
                onChange={event => setMaintenance(event.target.value)}
                value={maintenance}
                InputProps={{
                  inputComponent: FieldNumberFormatCustom as any,
                }}
                disabled={status === 'INACTIVE'}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <ModalAddFullClient
        open={openModalClient}
        handleToggle={handleToggleModalAddFullClient}
        handleSelectClient={handleSelectClient}
      />
    </Dialog>
  );
};

export default ModalAddDeposit;
