import { format, parseISO } from 'date-fns';
import IRequestDefunctsActions from '../dtos/IRequestDefunctsActions';

const formatDefunctActions = (
  action: IRequestDefunctsActions,
): IRequestDefunctsActions => {
  return {
    ...action,
    formatedCreatedAt: format(parseISO(action.createdAt), 'dd/MM/yyyy'),
    formatedDateAction: format(parseISO(action.dateAction), 'dd/MM/yyyy'),
  };
};

export default formatDefunctActions;
