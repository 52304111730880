import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import api from '../../services/api';
import IRequestContact from '../../dtos/IRequestContact';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IRequest {
  contact: IRequestContact;
}

interface IPropsContacts {
  editingContact: IRequestContact;
  handleUpdateItem: (contact: IRequestContact) => void;
  handleToggleModal: () => void;
  open: boolean;
}

const ModalEditContacts: React.FC<IPropsContacts> = ({
  editingContact,
  handleUpdateItem,
  open,
  handleToggleModal,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [coalition, setCoalition] = useState<string>('');
  const [main, setMain] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [areaCode, setAreaCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setName(editingContact.name);
    setEmail(editingContact.email);
    if (editingContact.phone) {
      setAreaCode(editingContact.phone.slice(0, 2));
      setPhone(editingContact.phone.slice(2, editingContact.phone.length));
    }
    setCoalition(editingContact.coalition);
    setMain(editingContact.main);
  }, [editingContact]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name,
          phone: areaCode + phone,
          email,
          coalition,
          main,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .email('Precisamos de um Email valido')
            .required('Email é obrigatório'),
          phone: Yup.string().required('Telefone é obrigatório'),
          coalition: Yup.string().required('Coligação é obrigatório'),
          main: Yup.boolean().required('Principal é obrigatório'),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put<IRequest>(
          `/contacts/${editingContact.id}`,
          data,
        );
        handleUpdateItem(response.data.contact);
        handleToggleModal();
        enqueueSnackbar(`Telefone atualizado com sucesso.`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            enqueueSnackbar(`Erro: ${error.message}`, {
              variant: 'error',
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao cadastrar o endereço', {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      areaCode,
      phone,
      email,
      coalition,
      main,
      editingContact.id,
      handleUpdateItem,
      handleToggleModal,
      enqueueSnackbar,
    ],
  );

  const handleChangeMain = (event: ChangeEvent<HTMLInputElement>): void => {
    setMain(event.target.checked);
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg">
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Novo Contato</DialogTitle>
        <IconButton onClick={handleToggleModal}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nome"
                required
                value={name}
                onChange={event => setName(event.target.value.toUpperCase())}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                required
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item md={1} xs={4}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="DDD"
                value={areaCode}
                onChange={event =>
                  setAreaCode(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 2 }}
              />
            </Grid>
            <Grid item md={3} xs={8}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Número"
                value={phone}
                onChange={event =>
                  setPhone(event.target.value.replace(/\D/g, ''))
                }
                inputProps={{ maxlength: 9 }}
              />
            </Grid>
            <Grid item md={4} xs={6} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Coligação"
                required
                value={coalition}
                onChange={event => setCoalition(event.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={main}
                    onChange={handleChangeMain}
                    name="main"
                    color="primary"
                  />
                }
                label="Tornar esse Contato como Principal?"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading}
          >
            {!isLoading ? 'Atualizar' : <CircularProgress />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditContacts;
