import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiPrinter, FiRefreshCw } from 'react-icons/fi';
import api from '../../services/api';
import FilterBillsByDeposit from '../FilterBillsByDeposit';
import { IRequestBill } from '../../dtos/IRequestBill';
import { formatBills } from '../../utils/formatBills';
import LocaleText from '../../LocaleText/index.json';

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

interface IParmsFormGet {
  expirationDateFilter: Date | null;
  statusPaymentFilter: string;
}

interface IRouteParams {
  id: string;
}

interface IGetBills {
  bills: IRequestBill[];
  total: number;
}

const ListBillsByDeposits: React.FC = () => {
  const { id } = useParams<IRouteParams>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [totalItem, setTotalItem] = useState<number>(0);
  const [listBills, setListBills] = useState<IRequestBill[]>([]);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    let active = true;
    setIsLoading(true);
    (async () => {
      api
        .get<IGetBills>(`bills/all/filter`, {
          params: {
            contractId: id,
            page: page + 1,
            pageSize,
            ...(expirationDate ? { expirationDate } : {}),
            ...(paymentStatus ? { status: paymentStatus } : {}),
          },
        })
        .then(response => {
          const { total, bills } = response.data;
          const billsFormatted = bills.map(bill => formatBills(bill));
          if (!active) {
            return;
          }

          setTotalItem(total);
          setListBills(billsFormatted);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [expirationDate, id, page, enqueueSnackbar, paymentStatus, pageSize]);

  const handleAddFilter = useCallback(
    ({ expirationDateFilter, statusPaymentFilter }: IParmsFormGet) => {
      setExpirationDate(expirationDateFilter);
      setPaymentStatus(statusPaymentFilter);
    },
    [],
  );

  const handlePrint = useCallback((printhrf: string) => {
    window.open(printhrf);
  }, []);

  const handleRefresh = useCallback(
    async (bill: IRequestBill) => {
      try {
        const response = await api.patch(`bills/${bill.id}/refresh`);
        const formattedBill = formatBills(response.data);

        const updatedList = listBills.filter(item => item.id !== bill.id);

        setListBills(
          [...updatedList, formattedBill].sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return 1;
            }
            if (a.createdAt < b.createdAt) {
              return -1;
            }
            return 0;
          }),
        );

        enqueueSnackbar('Item atualizado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar('Houve um erro ao atualizar o item, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [enqueueSnackbar, listBills],
  );

  return (
    <Container>
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Cobranças
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <FilterBillsByDeposit handleAddFilter={handleAddFilter} />
        {isLoading ? (
          <Grid container alignItems="center" justify="center" spacing={3}>
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listBills as RowModel[]}
                columns={[
                  {
                    field: 'contract.contractNumber',
                    headerName: 'Nº Contrato',
                    flex: 1,
                    align: 'left',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.contract
                          ? ''
                          : params.row.contract.contractNumber}
                      </div>
                    ),
                  },
                  {
                    field: 'client',
                    headerName: 'Cliente',
                    flex: 1,
                    align: 'left',
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.client ? '' : params.row.client.name}
                      </div>
                    ),
                  },
                  {
                    field: 'amount',
                    headerName: 'Valor',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formattedAmount}</>
                    ),
                  },
                  {
                    field: 'expirationDate',
                    headerName: 'Data de vencimento',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formattedExpirationDate}</>
                    ),
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formattedStatus}</>
                    ),
                  },
                  {
                    field: 'updatedAt',
                    headerName: 'Atualizado em',
                    flex: 1,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>{params.row.formattedUpdatedAt}</>
                    ),
                  },
                  {
                    field: 'actions',
                    headerName: 'Ações',
                    width: 150,
                    align: 'center',
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          onClick={() =>
                            handlePrint(params.row.printHref.toString())
                          }
                        >
                          <FiPrinter />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleRefresh(params.row as IRequestBill)
                          }
                        >
                          <FiRefreshCw />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </Card>
    </Container>
  );
};

export default ListBillsByDeposits;
