import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  CircularProgress,
  Card,
  Typography,
  ListItem,
  ListItemText,
  Grid,
  Box,
  Divider,
  CardContent,
  CardActions,
  Theme,
  List,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { shade } from 'polished';
import { Link, useHistory } from 'react-router-dom';

import { Pagination } from '@material-ui/lab';
import api from '../../../services/api';
import noDataSvg from '../../../assets/noData.svg';
import IRequestDefunctsActions from '../../../dtos/IRequestDefunctsActions';

interface IRequest {
  total: number;
  actions: IRequestDefunctsActions[];
}

const formatDefunctsAction = (
  defunctsAction: IRequestDefunctsActions,
): IRequestDefunctsActions => {
  return {
    ...defunctsAction,
    formatedCreatedAt: format(
      parseISO(defunctsAction.dateAction),
      'dd/MM/yyyy',
    ),
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    gridList: {
      width: '100%',
      height: '100%',
      maxHeight: '55vh',
      display: 'block',
      overflowY: 'auto',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        borderRadius: '25px',
      },
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    inline: {
      display: 'inline',
    },
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '13px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '18px',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      margin: '0 auto',
      width: 200,
    },
    linkAction: {
      textDecoration: 'none',
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: 700,
      width: '100%',
      padding: '15px',
      '&:hover': {
        color: `${shade(0.2, theme.palette.primary.main)}`,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        transition: 'color 1s',
      },
    },
  }),
);

const ActionList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [listDefunctsActions, setListDefunctsActions] = useState<
    IRequestDefunctsActions[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [countPages, setCountPages] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .get<IRequest>('all-actions', {
        params: {
          day: new Date(),
          page,
        },
      })
      .then(response => {
        const { total, actions } = response.data;

        const defunctsActionsFormatted = actions.map(action =>
          formatDefunctsAction(action),
        );
        setCountPages(Math.ceil(total / 5));
        setListDefunctsActions(defunctsActionsFormatted);
        setTotalItem(total);
      })
      .catch(() => {
        enqueueSnackbar('Houve um erro ao carregar a lista, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar, page]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      ) : (
        <Card
          variant="outlined"
          style={{
            padding: '0',
          }}
        >
          <Box className={classes.boxHeader}>
            <Grid container alignItems="center">
              <Grid item>
                <Typography className={classes.headerTitle}>
                  Ações diarias: {totalItem}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider variant="fullWidth" />
          <CardContent>
            {listDefunctsActions.length > 0 ? (
              <List className={classes.gridList}>
                {listDefunctsActions.map(actions => (
                  <ListItem
                    button
                    dense
                    onClick={() => {
                      history.push(
                        `/form/defuncts/${actions.defunct.id}?tabIndex=1`,
                      );
                    }}
                  >
                    <ListItemText
                      primary={`${actions.nroRegister} - ${actions.status}`}
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            <b>Sepultado: {actions.defunct.name}</b>
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            {`Criado em: ${actions.formatedCreatedAt}`}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
              >
                <Grid item>
                  <img src={noDataSvg} alt="no_data" width="250" />
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    Nenhuma ação cadastrada para hoje
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
          <Divider variant="fullWidth" />
          <CardActions>
            <Grid container justify="space-between">
              <Grid item alignContent="center">
                <Link to="/actions" className={classes.linkAction}>
                  Acessar Filtros
                </Link>
              </Grid>
              <Grid item>
                <Pagination
                  count={countPages}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      )}
    </div>
  );
};

export default ActionList;
