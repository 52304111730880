import DateFnsUtils from '@date-io/date-fns';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';
import { ptBR } from 'date-fns/esm/locale';
import { useSnackbar } from 'notistack';

import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import IRequestClient from '../../../../dtos/IRequestClient';
import IRequestDeposits from '../../../../dtos/IRequestDeposits';
import IRequestItemContract from '../../../../dtos/IRequestItemContract';
import api from '../../../../services/api';

interface IPropsConfirm {
  client: IRequestClient;
  selectedItem: IRequestItemContract;
  handleToggleModal: () => void;
  open: boolean;
  ccoNumber: number;
  deposit: IRequestDeposits;
}

const ModalConfirmItemToBill: React.FC<IPropsConfirm> = ({
  handleToggleModal,
  selectedItem,
  open,
  client,
  ccoNumber,
  deposit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  useEffect(() => {
    setExpirationDate(parseISO(selectedItem.maintenanceDate));
  }, [selectedItem]);

  const handleCreateABill = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        const data = {
          clientId: client.id,
          contractId: selectedItem.contractId,
          expirationDate,
          items: [
            {
              product: selectedItem.product,
              quantity: selectedItem.quantity,
              price: selectedItem.price,
            },
          ],
        };

        const schema = Yup.object().shape({
          clientId: Yup.string().required('Cliente é obrigatório'),
          contractId: Yup.string().required('Contrato é obrigatório'),
          expirationDate: Yup.date().required(
            'Data de vencimento é obrigatório',
          ),
          items: Yup.array()
            .of(
              Yup.object().shape({
                product: Yup.string().required('Produto é obrigatório'),
                price: Yup.number().required('Valor do item é obrigatório'),
                quantity: Yup.number().required('Quantidade é obrigatória'),
              }),
            )
            .required(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post(`bills`, data);

        handleToggleModal();

        enqueueSnackbar('Cobrança criado com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (err) {
        enqueueSnackbar(
          'Erro ao criar cobrança, Verifique os dados do cliente',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      }
    },
    [
      client.id,
      enqueueSnackbar,
      expirationDate,
      handleToggleModal,
      selectedItem.contractId,
      selectedItem.price,
      selectedItem.product,
      selectedItem.quantity,
    ],
  );

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item>Confirmar Cobrança</Grid>
          <Grid item>
            <IconButton onClick={() => handleToggleModal()}>
              <FiXCircle />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form autoComplete="off" noValidate onSubmit={handleCreateABill}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} lg={12}>
              <Typography variant="h5">Dados da cobrança</Typography>
              <Divider variant="fullWidth" style={{ marginBottom: '22px' }} />
              <Typography variant="h6">{`Cliente: ${client.name}`}</Typography>
              <Typography variant="h6">{`Contrato : ${ccoNumber}`}</Typography>
              <Typography variant="h6">{`Jazigo: ${deposit.street?.block.name}/${deposit.street?.name}/${deposit.name}`}</Typography>

              <Typography variant="h6">{`Produto: ${selectedItem.product}`}</Typography>
              <Typography variant="h6">{`Quantidade: ${selectedItem.quantity}`}</Typography>
              <Typography variant="h6">{`Valor: ${selectedItem.formatedPrice}`}</Typography>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Data de Vencimento"
                  onChange={(date: Date | null) => setExpirationDate(date)}
                  value={expirationDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" variant="contained" fullWidth>
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalConfirmItemToBill;
