import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { format, parseISO } from 'date-fns';
import { FiAirplay, FiArchive, FiEdit, FiXCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ModalLinkDefunct from '../ModalLinkDefunct';

import IRequestDefuncts from '../../dtos/IRequestDefuncts';

import LocaleText from '../../LocaleText/index.json';
import IRequestDrawers from '../../dtos/IRequestDrawers';
import api from '../../services/api';
import IRequestDefunctsActions from '../../dtos/IRequestDefunctsActions';

const formatDefunctActions = (
  action: IRequestDefunctsActions,
): IRequestDefunctsActions => {
  return {
    ...action,
    formatedDateAction: format(parseISO(action.dateAction), 'dd/MM/yyyy'),
  };
};

const formatDefunct = (defunct: IRequestDefuncts): IRequestDefuncts => {
  return {
    ...defunct,
    formatedCreatedAt: format(parseISO(defunct.createdAt), 'dd/MM/yyyy'),
    formatedDateDeath: format(parseISO(defunct.dateDeath), 'dd/MM/yyyy'),
    formatedDateBirth: format(parseISO(defunct.dateBirth), 'dd/MM/yyyy'),
    defunctActions: defunct.defunctActions
      .map(action => formatDefunctActions(action))
      .sort((a, b) => {
        return a.dateAction > b.dateAction ? 1 : -1;
      }),
  };
};

const useStyles = makeStyles(() => {
  return {
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
  };
});

interface IProps {
  handleToggle: () => void;
  open: boolean;
  drawer: IRequestDrawers;
}

interface IRequest {
  total: number;
  defuncts: IRequestDefuncts[];
}

const ModalDefunctsInDrawer: React.FC<IProps> = ({
  handleToggle,
  open,
  drawer,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [listDefuncts, setListDefuncts] = useState<IRequestDefuncts[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [editingDefunct, setEditingDefunct] = useState<IRequestDefuncts>(
    {} as IRequestDefuncts,
  );

  useEffect(() => {
    if ('id' in drawer) {
      const active = true;
      setIsLoading(true);

      (async () => {
        api
          .get<IRequest>(`defuncts/drawer/${drawer.id}`, {
            params: { page: page + 1, pageSize },
          })
          .then(response => {
            const { total, defuncts } = response.data;
            const defunctsFormatted = defuncts.map(defunct =>
              formatDefunct(defunct),
            );

            if (!active) {
              return;
            }

            setListDefuncts(defunctsFormatted);
            setTotalItem(total);
          })
          .catch(() => {
            enqueueSnackbar(
              'Houve um erro ao carregar a lista, tente novamente.',
              {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              },
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      })();
    }
  }, [page, pageSize, enqueueSnackbar, drawer]);

  const handleToggleTransferModal = useCallback(() => {
    setOpenTransferModal(!openTransferModal);
  }, [openTransferModal]);

  const handleEditing = useCallback(
    (defunct: IRequestDefuncts) => {
      setEditingDefunct(defunct);
      handleToggleTransferModal();
    },
    [handleToggleTransferModal],
  );

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  const handleUpdateItem = useCallback(
    (updatedDefunct: IRequestDefuncts) => {
      const updatedList = listDefuncts.filter(
        item => item.id !== updatedDefunct.id,
      );
      const historicsFormatted = formatDefunct(updatedDefunct);

      setListDefuncts([...updatedList, historicsFormatted]);
    },
    [listDefuncts],
  );

  const handleTransfer = useCallback(
    async (defunctId: string) => {
      try {
        const response = await api.delete(`/defuncts/transfer/${defunctId}`);
        handleUpdateItem(response.data.defunct);

        enqueueSnackbar('Defunto Exumado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      }
    },
    [enqueueSnackbar, handleUpdateItem],
  );

  return (
    <Dialog open={open} maxWidth="xl" fullWidth onClose={() => handleToggle()}>
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Gaveta - {drawer.name}</DialogTitle>
        <IconButton onClick={() => handleToggle()}>
          <FiXCircle />
        </IconButton>
      </header>
      <DialogContent dividers>
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <Typography variant="h3" style={{ fontWeight: 'bold' }}>
              Todos Sepultados
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 4, marginTop: 4 }}>
              Total: {totalItem}
            </Typography>
          </Grid>
        </Grid>
        <Divider style={{ width: '100%', marginBottom: 16 }} />
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              rowCount={totalItem}
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageChange={params => {
                setPage(params.page);
              }}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={params => {
                setPageSize(params.pageSize);
              }}
              disableColumnSelector
              disableSelectionOnClick
              localeText={LocaleText}
              rows={listDefuncts as RowModel[]}
              columns={[
                {
                  field: 'name',
                  headerName: 'Sepultado',
                  width: 180,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div className={classes.ellipsis}>
                      {!params.value ? 'Sem apelido' : params.value}
                    </div>
                  ),
                },
                {
                  field: 'registry',
                  headerName: 'Registro',
                  width: 170,
                  align: 'left',
                },
                {
                  field: 'funerary',
                  headerName: 'Funeraria',
                  width: 170,
                  align: 'left',
                },
                {
                  field: 'nroDeathCertificate',
                  headerName: 'Atestado de Óbito',
                  width: 170,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      {!params.row.nroDeathCertificate
                        ? ''
                        : params.row.nroDeathCertificate}
                    </>
                  ),
                },
                {
                  field: 'dateDeath',
                  headerName: 'Data de Falecimento',
                  width: 170,
                  align: 'left',
                  renderCell: (params: ValueFormatterParams) => (
                    <>{params.row.formatedDateDeath}</>
                  ),
                },

                // FIX: Ultima ação com problema(fica variando)
                {
                  field: 'LastAction',
                  headerName: 'Ultima Ação',
                  width: 178,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      {
                        params.row.defunctActions[
                          params.row.defunctActions.length - 1
                        ].status
                      }
                    </>
                  ),
                },
                {
                  field: 'LastActionDate',
                  headerName: 'Data Ultima Ação',
                  width: 178,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      {
                        params.row.defunctActions[
                          params.row.defunctActions.length - 1
                        ].formatedDateAction
                      }
                    </>
                  ),
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 156,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <Tooltip title="Acessar Sepultado">
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/form/defuncts/${params.row.id.toString()}`,
                            )
                          }
                        >
                          <FiEdit className={classes.editButton} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Exumar">
                        <IconButton
                          onClick={() =>
                            handleTransfer(params.row.id.toString())
                          }
                        >
                          <FiArchive />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Transladar">
                        <IconButton
                          onClick={() =>
                            handleEditing(params.row as IRequestDefuncts)
                          }
                        >
                          <FiAirplay />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
            />
          </div>
        )}
      </DialogContent>
      <ModalLinkDefunct
        handleToggle={handleToggleTransferModal}
        open={openTransferModal}
        defunct={editingDefunct}
      />
    </Dialog>
  );
};

export default ModalDefunctsInDrawer;
