import React, { useEffect, useState, useCallback } from 'react';

import {
  Badge,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import { FiPlusCircle, FiUsers } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import IRequestDrawers from '../../dtos/IRequestDrawers';
import ModalDefunctsInDrawer from '../ModalDefunctsInDrawer';

import api from '../../services/api';
import LocaleText from '../../LocaleText/index.json';

interface IProps {
  depositId: string;
}

interface IRequest {
  total: number;
  drawers: IRequestDrawers[];
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

const formatDrawer = (drawer: IRequestDrawers): IRequestDrawers => {
  return {
    ...drawer,
    formatedCreatedAt: format(parseISO(drawer.createdAt), 'dd/MM/yyyy'),
  };
};

const ListDepositDrawers: React.FC<IProps> = ({ depositId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [listDrawers, setListDrawers] = useState<IRequestDrawers[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [editingDrawer, setEditingDrawer] = useState<IRequestDrawers>(
    {} as IRequestDrawers,
  );
  const [
    openModalDefunctInDrawer,
    setOpenModalDefunctInDrawer,
  ] = useState<boolean>(false);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  const handleToggleDefunctInDrawer = useCallback(() => {
    setOpenModalDefunctInDrawer(!openModalDefunctInDrawer);
  }, [openModalDefunctInDrawer]);

  const handleEditing = useCallback(
    (drawer: IRequestDrawers) => {
      setEditingDrawer(drawer);
      handleToggleDefunctInDrawer();
    },
    [handleToggleDefunctInDrawer],
  );

  useEffect(() => {
    let active = true;
    setIsLoading(true);

    (async () => {
      api
        .get<IRequest>(`drawers/${depositId}/deposit`, {
          params: { page: page + 1, pageSize },
        })
        .then(response => {
          const { total, drawers } = response.data;
          const drawersFormatted = drawers.map(drawer => formatDrawer(drawer));
          if (!active) {
            return;
          }
          setListDrawers(drawersFormatted);
          setTotalItem(total);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [enqueueSnackbar, depositId, page, pageSize]);

  return (
    <>
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Todas gavetas
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Total: {totalItem}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ width: '100%', marginBottom: 16 }} />
        {isLoading ? (
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              rowCount={totalItem}
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageChange={params => {
                setPage(params.page);
              }}
              rowsPerPageOptions={[10, 20, 50]}
              onPageSizeChange={params => {
                setPageSize(params.pageSize);
              }}
              disableColumnSelector
              disableSelectionOnClick
              localeText={LocaleText}
              rows={listDrawers as RowModel[]}
              columns={[
                {
                  field: 'name',
                  headerName: 'Nome',
                  flex: 1,
                  align: 'left',
                },
                {
                  field: 'defunct',
                  headerName: 'Sepultado',
                  flex: 1,
                  align: 'left',
                  renderCell: (params: ValueFormatterParams) => (
                    <div className={classes.ellipsis}>
                      {params.row.defunct.length === 0
                        ? 'Não possui sepultado'
                        : params.row.defunct[params.row.defunct.length - 1]
                            .name}
                    </div>
                  ),
                },

                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 150,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <Tooltip title="Acessar Sepultados">
                        <IconButton
                          onClick={() =>
                            handleEditing(params.row as IRequestDrawers)
                          }
                        >
                          <Badge
                            badgeContent={`+${
                              params.row.defunct.length === 0
                                ? '0'
                                : params.row.defunct.length - 1
                            }`}
                            color="primary"
                          >
                            <FiUsers />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sepultar">
                        <IconButton
                          onClick={() =>
                            history.push({
                              pathname: '/form/defuncts',
                              state: {
                                clientId: params.row.deposit.clientId,
                                depositId: params.row.deposit.id,
                                drawerId: params.row.id,
                              },
                            })
                          }
                        >
                          <FiPlusCircle />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
            />
          </div>
        )}
        <ModalDefunctsInDrawer
          handleToggle={handleToggleDefunctInDrawer}
          open={openModalDefunctInDrawer}
          drawer={editingDrawer}
        />
      </Card>
    </>
  );
};

export default ListDepositDrawers;
