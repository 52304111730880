import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { IconType } from 'react-icons/lib';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import {
  FiDollarSign,
  FiEdit,
  FiInbox,
  FiPlusCircle,
  FiShoppingBag,
  FiX,
} from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import IRequestDeposits from '../../dtos/IRequestDeposits';
import formatDeposit from '../../utils/formatDeposit';
import ModalAddDepositClient from '../ModaAddDepositClient';
import LocaleText from '../../LocaleText/index.json';
import IRequestContracts from '../../dtos/IRequestContracts';

interface IProps {
  deposits: IRequestDeposits[];
  clientName: string;
}

interface IParams {
  id: string;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    editButton: {
      color: '#22863A',
    },
  };
});

type TIconsStatus = {
  [key: string]: ReactElement<IconType>;
};

function getIconStatus(status: string): ReactElement<IconType> {
  const depositState: TIconsStatus = {
    OPEN: <FiInbox style={{ color: '#5850EC' }} size={20} />,
    SOLD: <FiDollarSign style={{ color: '#22863a' }} size={20} />,
    INACTIVE: <FiX style={{ color: '#C70039' }} size={20} />,
    USING: <FiShoppingBag style={{ color: '#22863a' }} size={20} />,
  };

  return depositState[status];
}

type TTextStatus = {
  [key: string]: string;
};

function getTextStatus(status: string): string {
  const depositState: TTextStatus = {
    OPEN: 'Aberto',
    SOLD: 'Vendido',
    INACTIVE: 'Inativo',
    USING: 'Em uso',
  };

  return depositState[status];
}

const ListDepositClients: React.FC<IProps> = ({ deposits }) => {
  const { id } = useParams<IParams>();
  const classes = useStyles();
  const history = useHistory();

  const [listDeposist, setListDeposits] = useState<IRequestDeposits[]>([]);
  const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);

  const handleToggle = useCallback(() => {
    setOpenModalAdd(!openModalAdd);
  }, [openModalAdd]);

  useEffect(() => {
    const depositsFormatted = deposits.map(deposit => formatDeposit(deposit));
    setListDeposits(depositsFormatted);
  }, [deposits]);

  const handleUdpateList = useCallback((newDeposit: IRequestDeposits) => {
    const formatedDeposit = formatDeposit(newDeposit);
    setListDeposits(oldValue => [...oldValue, formatedDeposit]);
  }, []);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  const findActiveContractByDeposit = (
    contracts: IRequestContracts[],
  ): string => {
    const finded = contracts.find(item => item.active);

    if (finded && finded.formattedInitialDate) {
      return finded.formattedInitialDate;
    }

    return 'Não possui';
  };

  return (
    <>
      <ModalAddDepositClient
        handleToggle={handleToggle}
        open={openModalAdd}
        clientId={id}
        handleUdpateList={handleUdpateList}
      />
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.headerTitle}>Jazigos</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                disableElevation
                style={{ fontWeight: 700, marginRight: '15px' }}
                startIcon={<FiPlusCircle />}
                onClick={handleToggle}
              >
                Adicionar Jazigo
              </Button>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={() =>
                  history.push({
                    pathname: '/form/deposits',
                    state: { clientId: id },
                  })
                }
              >
                Novo Jazigo
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <div ref={gridWrapperRef} style={{ padding: 16 }}>
            <DataGrid
              autoHeight
              disableColumnSelector
              disableSelectionOnClick
              disableColumnMenu
              localeText={LocaleText}
              rows={listDeposist as RowModel[]}
              columns={[
                {
                  field: 'Avatar',
                  headerName: 'Situação',
                  width: 100,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <Tooltip
                      title={
                        <Typography variant="subtitle2">
                          {getTextStatus(params.row.status)}
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <div>{getIconStatus(params.row.status)}</div>
                    </Tooltip>
                  ),
                },
                {
                  field: 'street.name',
                  headerName: 'Quadra/Rua',
                  flex: 1,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div>
                      {!params.row.street.name
                        ? 'Sem apelido'
                        : `${params.row.street.block.name}/${params.row.street.name}`}
                    </div>
                  ),
                },
                {
                  field: 'name',
                  headerName: 'Nome',
                  flex: 1,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>{!params.value ? 'Sem apelido' : params.value}</>
                  ),
                },
                {
                  field: 'HiredAt',
                  headerName: 'Contratado em',
                  flex: 1,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <div>
                      {params.row.contracts
                        ? findActiveContractByDeposit(params.row.contracts)
                        : 'Não possui'}
                    </div>
                  ),
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  width: 150,
                  align: 'center',
                  renderCell: (params: ValueFormatterParams) => (
                    <>
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/form/deposits/${params.row.id.toString()}`,
                          )
                        }
                      >
                        <FiEdit className={classes.editButton} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
              pageSize={10}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ListDepositClients;
