import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { ptBR } from 'date-fns/esm/locale';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import IRequestClient from '../../dtos/IRequestClient';
import api from '../../services/api';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
  };
});

interface IRequestClientList {
  clients: IRequestClient[];
}

interface IParmsFilterLotsGet {
  maintenanceDateFilter: Date | null;
  initialDateFilter: Date | null;
  contractNumberFromFilter: number;
  contractNumberToFilter: number;
  clientFilter: IRequestClient;
  billYearFilter: Date | null;
  billStatusFilter: string;
}

interface IProps {
  handleAddFilter(data: IParmsFilterLotsGet): void;
}

const FilterBillLotsList: React.FC<IProps> = ({ handleAddFilter }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [listClients, setListClients] = useState<IRequestClient[]>([]);
  const [maintenanceDate, setMaintenanceDate] = useState<Date | null>(null);
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [contractNumberFrom, setContractNumberFrom] = useState<number>(0);
  const [contractNumberTo, setContractNumberTo] = useState<number>(0);
  const [billYear, setBillYear] = useState<Date | null>(null);
  const [billStatus, setBillStatus] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<IRequestClient>(
    {} as IRequestClient,
  );

  useEffect(() => {
    api
      .get<IRequestClientList>('clients-active')
      .then(response => {
        setListClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as clientes, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      handleAddFilter({
        maintenanceDateFilter: maintenanceDate || null,
        initialDateFilter: initialDate || null,
        contractNumberFromFilter: contractNumberFrom,
        contractNumberToFilter: contractNumberTo,
        clientFilter: selectedClient || null,
        billYearFilter: billYear,
        billStatusFilter: billStatus,
      });
    },
    [
      handleAddFilter,
      maintenanceDate,
      initialDate,
      contractNumberFrom,
      contractNumberTo,
      selectedClient,
      billYear,
      billStatus,
    ],
  );
  return (
    <Card>
      <Box className={classes.boxHeader}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography className={classes.headerTitle}>Filtros</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Autocomplete
                options={listClients}
                value={selectedClient}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, client) => {
                  if (client) {
                    setSelectedClient(client);
                  } else {
                    setSelectedClient({} as IRequestClient);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Cliente"
                  />
                )}
              />
            </Grid>
            <Grid item md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  inputVariant="outlined"
                  format="dd/MM"
                  label="Manutenção"
                  onChange={(date: Date | null) => setMaintenanceDate(date)}
                  value={maintenanceDate}
                  name="maintenanceDate"
                  clearable
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Data Inicial"
                  onChange={(date: Date | null) => setInitialDate(date)}
                  value={initialDate}
                  name="initialDate"
                  clearable
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={2}>
              <TextField
                type="number"
                variant="outlined"
                label="Nº Contrato De"
                fullWidth
                value={contractNumberFrom}
                onChange={event =>
                  setContractNumberFrom(Number(event.target.value))
                }
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                type="number"
                variant="outlined"
                label="Nº Contrato até"
                fullWidth
                value={contractNumberTo}
                onChange={event =>
                  setContractNumberTo(Number(event.target.value))
                }
              />
            </Grid>
          </Grid>
        </CardContent>

        <Box className={classes.boxHeader}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography className={classes.headerTitle}>
                Pagamentos Filtros
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  inputVariant="outlined"
                  format="yyyy"
                  label="Ano"
                  onChange={(date: Date | null) => setBillYear(date)}
                  value={billYear}
                  name="maintenanceDate"
                  clearable
                  views={['year']}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={4}>
              <TextField
                variant="outlined"
                label="Estado do boleto"
                fullWidth
                select
                value={billStatus}
                onChange={event => setBillStatus(event.target.value)}
              >
                <MenuItem value="" selected>
                  Todos
                </MenuItem>
                <MenuItem value="CREATED">Criado</MenuItem>
                <MenuItem value="WAITING">Aguardando</MenuItem>
                <MenuItem value="PAID">Pago</MenuItem>
                <MenuItem value="NOT_PAID">Não Pago</MenuItem>
                <MenuItem value="REVERTED">Revertido</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" type="submit" variant="contained">
            Filtrar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default FilterBillLotsList;
