import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { FiFilter, FiPlusCircle, FiShoppingCart } from 'react-icons/fi';
import api from '../../services/api';

import LocaleText from '../../LocaleText/index.json';

import IRequestClient from '../../dtos/IRequestClient';
import IRequestContracts from '../../dtos/IRequestContracts';
import { formatContracts } from '../../utils/formatContracts';
import FilterBillLotsList from './FilterBillLotsList';
import ModalLoading from './ModalLoading';
import { IRequestBill } from '../../dtos/IRequestBill';
import IRequestItemContract from '../../dtos/IRequestItemContract';

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconActive: {
      color: theme.palette.primary.main,
    },
    boxHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    cardAction: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});

interface IGetContracts {
  contracts: IRequestContracts[];
  total: number;
}

interface IParmsFilterLotsGet {
  maintenanceDateFilter: Date | null;
  initialDateFilter: Date | null;
  contractNumberFromFilter: number;
  contractNumberToFilter: number;
  clientFilter: IRequestClient;
  billYearFilter: Date | null;
  billStatusFilter: string;
}

interface IAPIResponseBill extends IRequestBill {
  error: boolean;
}

const GenerateMaintenceBillLots: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [listContracts, setListContracts] = useState<IRequestContracts[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  // filters
  const [maintenanceDate, setMaintenanceDate] = useState<Date | null>(null);
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [contractNumberFrom, setContractNumberFrom] = useState<number>(0);
  const [contractNumberTo, setContractNumberTo] = useState<number>(0);
  const [selectedClient, setSelectedClient] = useState<IRequestClient>(
    {} as IRequestClient,
  );
  const [billYear, setBillYear] = useState<Date | null>(null);
  const [billStatus, setBillStatus] = useState<string>('');
  // LoadingModal
  const [openModalLoading, setOpenModalLoading] = useState<boolean>(false);
  const [itemsSelected, setItemsSelected] = useState<IRequestContracts[]>([]);
  const [itemsReceived, setItemsReceived] = useState<IAPIResponseBill[]>([]);

  const handleToggleModalLoading = useCallback(() => {
    setOpenModalLoading(!openModalLoading);
  }, [openModalLoading]);

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    let active = true;
    setIsLoading(true);
    (async () => {
      api
        .get<IGetContracts>(`contracts/all/filter`, {
          params: {
            page: page + 1,
            pageSize,
            ...(maintenanceDate ? { maintenanceDate } : {}),
            ...(initialDate ? { initialDate } : {}),
            ...(contractNumberFrom ? { contractNumberFrom } : {}),
            ...(contractNumberTo ? { contractNumberTo } : {}),
            ...(selectedClient ? { contractorId: selectedClient.id } : {}),
            ...(billYear ? { billYear } : {}),
            ...(billStatus ? { billStatus } : {}),
          },
        })
        .then(response => {
          const { total, contracts } = response.data;

          const contractsFormmatted = contracts.map(contract =>
            formatContracts(contract),
          );
          if (!active) {
            return;
          }

          setTotalItem(total);
          setListContracts(contractsFormmatted);
        })
        .catch(() => {
          enqueueSnackbar(
            'Houve um erro ao carregar a lista, tente novamente.',
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();

    return () => {
      active = false;
    };
  }, [
    page,
    enqueueSnackbar,
    pageSize,
    selectedClient,
    maintenanceDate,
    initialDate,
    contractNumberFrom,
    contractNumberTo,
    billYear,
    billStatus,
  ]);

  const handleAddFilter = useCallback(
    ({
      maintenanceDateFilter,
      initialDateFilter,
      contractNumberFromFilter,
      contractNumberToFilter,
      clientFilter,
      billStatusFilter,
      billYearFilter,
    }: IParmsFilterLotsGet) => {
      setMaintenanceDate(maintenanceDateFilter);
      setInitialDate(initialDateFilter);
      setContractNumberFrom(contractNumberFromFilter);
      setContractNumberTo(contractNumberToFilter);
      setSelectedClient(clientFilter);
      setBillYear(billYearFilter);
      setBillStatus(billStatusFilter);
    },
    [],
  );

  const handleAddItemInBills = useCallback(
    (itemId: string) => {
      const findItemSelected = itemsSelected.find(item => item.id === itemId);
      if (!findItemSelected) {
        const contract = listContracts.find(element => element.id === itemId);
        if (contract) {
          setItemsSelected(oldValue => [...oldValue, contract]);
          enqueueSnackbar('Item selecionado para cobraça.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        }
      } else {
        const filtredList = itemsSelected.filter(
          itemSelect => itemSelect.id !== itemId,
        );
        setItemsSelected(filtredList);
        enqueueSnackbar('Item removido da cobraça.', {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
    },
    [itemsSelected, listContracts, enqueueSnackbar],
  );

  const handleApprove = useCallback((item: IRequestBill) => {
    setItemsReceived(state => [...state, { ...item, error: false }]);
  }, []);

  const handleDisapprove = useCallback((item: any) => {
    setItemsReceived(state => [
      ...state,
      { ...({} as IRequestBill), error: true, contractId: item.contractId },
    ]);
  }, []);

  const handleGenerateBillLots = useCallback(async () => {
    if (itemsSelected.length === 0) {
      enqueueSnackbar('Nenhum contrato foi selecionado.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    } else {
      try {
        setIsLoading(true);
        handleToggleModalLoading();
        setItemsReceived([]);

        const array = itemsSelected.map(item => {
          const maintenanceItem = item.items.find(
            x => x.product === 'Manutenção anual',
          );

          return {
            clientId: item.contractorId,
            contractId: item.id,
            maintenanceDate: maintenanceItem?.maintenanceDate,
            items: item.items.map(x => {
              return {
                product: x.product,
                price: x.price,
                quantity: x.quantity,
              };
            }),
          };
        });

        array.map(async item => {
          try {
            const response = await api.post('/bills', item);

            handleApprove(response.data);
          } catch (error) {
            handleDisapprove(item);
          }
        });
      } catch (error) {
        enqueueSnackbar('Houve um erro, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    enqueueSnackbar,
    handleApprove,
    handleDisapprove,
    handleToggleModalLoading,
    itemsSelected,
  ]);

  const getValueOfmaintenance = (items: IRequestItemContract[]): string => {
    const item = items.find(x => x.product === 'Manutenção anual');

    if (item && item.formatedPrice) {
      return item.formatedPrice;
    }
    return '';
  };
  const findlastBill = (bills: IRequestBill[]): IRequestBill => {
    const billsAsc = bills.sort((a, b) => {
      return a.expirationDate < b.expirationDate ? 1 : -1;
    });
    if (billsAsc.length > 0) {
      return billsAsc[0];
    }
    return {} as IRequestBill;
  };

  return (
    <Container>
      <ModalLoading
        open={openModalLoading}
        handleToggle={handleToggleModalLoading}
        itemsSelected={itemsSelected}
        itemsReceived={itemsReceived}
      />
      <Card>
        <Box className={classes.boxHeader}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                Contratos / Manutenção
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Total: {totalItem}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginLeft: 4, marginTop: 4 }}
              >
                Itens selecionados: {itemsSelected.length}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpenFilter(!openFilter)}>
                <FiFilter />
              </IconButton>
            </Grid>
          </Grid>
          <Collapse timeout="auto" in={openFilter}>
            <FilterBillLotsList handleAddFilter={handleAddFilter} />
          </Collapse>
        </Box>
        {isLoading ? (
          <Grid container alignItems="center" justify="center" spacing={3}>
            <Grid item>
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <div ref={gridWrapperRef} style={{ padding: 16 }}>
              <DataGrid
                autoHeight
                rowCount={totalItem}
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                disableColumnMenu
                disableColumnFilter
                onPageChange={params => {
                  setPage(params.page);
                }}
                rowsPerPageOptions={[10, 20, 50]}
                onPageSizeChange={params => {
                  setPageSize(params.pageSize);
                }}
                disableColumnSelector
                disableSelectionOnClick
                localeText={LocaleText}
                rows={listContracts as RowModel[]}
                columns={[
                  {
                    field: 'contract.contractNumber',
                    headerName: 'Nº Contrato',
                    flex: 0.5,
                    align: 'left',
                    sortable: false,
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.contractNumber
                          ? ''
                          : params.row.contractNumber}
                      </div>
                    ),
                  },
                  {
                    field: 'client',
                    headerName: 'Cliente',
                    flex: 1.5,
                    align: 'left',
                    sortable: false,
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {!params.row.contractor
                          ? ''
                          : params.row.contractor.name}
                      </div>
                    ),
                  },
                  {
                    field: 'maintenance.value',
                    headerName: 'Valor manutenção',
                    flex: 1.5,
                    align: 'left',
                    sortable: false,
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {getValueOfmaintenance(params.row.items)}
                      </div>
                    ),
                  },
                  {
                    field: 'lastBillDate',
                    headerName: 'Ultimo Pagamento',
                    flex: 1.5,
                    align: 'left',
                    sortable: false,
                    renderCell: (params: ValueFormatterParams) => (
                      <div className={classes.ellipsis}>
                        {'id' in findlastBill(params.row.bills)
                          ? `${
                              findlastBill(params.row.bills)
                                .formattedExpirationDate
                            }(${
                              findlastBill(params.row.bills).formattedStatus
                            })`
                          : 'Nenhum pagamento criado'}
                      </div>
                    ),
                  },
                  {
                    field: 'bills',
                    headerName: 'Cobrança',
                    width: 110,
                    disableColumnMenu: true,
                    align: 'center',
                    sortable: false,
                    renderCell: (params: ValueFormatterParams) => (
                      <>
                        <IconButton
                          color={
                            itemsSelected.find(
                              item => item.id === params.row.id.toString(),
                            )
                              ? 'primary'
                              : 'inherit'
                          }
                          disabled={!params.row.active}
                          onClick={() =>
                            handleAddItemInBills(params.row.id.toString())
                          }
                        >
                          <FiShoppingCart />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </div>
            <CardActions className={classes.cardAction}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={handleGenerateBillLots}
              >
                Gerar Cobrança
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </Container>
  );
};

export default GenerateMaintenceBillLots;
