import React from 'react';
import { makeStyles, createStyles, Grid, Paper } from '@material-ui/core';
import ActionList from './ActionList';
import Shortcuts from './Shortcuts';
import FinancialBox from './FinancialBox';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      padding: '16px',
    },
  }),
);

const Dashboard: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item md={3} sm={12} xs={12}>
          <Grid container item spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <Shortcuts />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <ActionList />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <Grid container item spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <FinancialBox />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;
