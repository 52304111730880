import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { FiEdit, FiPlusCircle, FiPrinter, FiTrash2 } from 'react-icons/fi';
import {
  DataGrid,
  RowModel,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import api from '../../services/api';
import IRequestContracts from '../../dtos/IRequestContracts';
import { formatContracts } from '../../utils/formatContracts';
import LocaleText from '../../LocaleText/index.json';
import IRequestClient from '../../dtos/IRequestClient';
import ModalContractDepositItem from '../ModalContractDepositItem';

interface IPropsContract {
  depositId: string;
  clientSelected: IRequestClient;
}

interface IRequest {
  contracts: IRequestContracts[];
  total: number;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    tabLabel: {
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    editButton: {
      color: '#22863A',
    },
    trashButton: {
      color: '#87222B',
    },
  };
});

const ListContractDeposit: React.FC<IPropsContract> = ({
  depositId,
  clientSelected,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listContracts, setListContracts] = useState<IRequestContracts[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [openButton, setOpenButton] = useState<boolean>(false);

  const [openModalEdit, setOpenModal] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IRequestContracts>(
    {} as IRequestContracts,
  );

  const handleToggle = useCallback(() => {
    setOpenModal(!openModalEdit);
  }, [openModalEdit]);

  const handleSelectContract = useCallback(
    (contract: IRequestContracts) => {
      setSelectedContract(contract);
      handleToggle();
    },
    [handleToggle],
  );

  const gridWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current;
    if (gridDiv) {
      const gridEl: HTMLDivElement | null = gridDiv.querySelector('div');
      if (gridEl) {
        gridEl.style.height = '';
      }
    }
  });

  useEffect(() => {
    api
      .get<IRequest>(`contracts/${depositId}/deposit`, {
        params: { page: page + 1, pageSize },
      })
      .then(response => {
        const { total, contracts } = response.data;

        const formatedContract = contracts.map(contract =>
          formatContracts(contract),
        );

        if (
          contracts.length === 0 ||
          Boolean(contracts.find(item => !item.active))
        ) {
          setOpenButton(true);
        }
        setListContracts(formatedContract);
        setTotalItem(total);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao buscar o contrato.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [depositId, page, pageSize, enqueueSnackbar]);

  return (
    <Card>
      <ModalContractDepositItem
        open={openModalEdit}
        contract={selectedContract}
        handleToggleModal={handleToggle}
      />
      <Box className={classes.boxHeader}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.headerTitle}>Contratos</Typography>
          </Grid>
          {openButton && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                style={{ fontWeight: 700 }}
                startIcon={<FiPlusCircle />}
                onClick={() =>
                  history.push({
                    pathname: '/contract',
                    state: { depositId, client: clientSelected },
                  })
                }
              >
                Novo contrato
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider />
      <CardContent>
        <div ref={gridWrapperRef} style={{ padding: 16 }}>
          <DataGrid
            autoHeight
            loading={isLoading}
            rowCount={totalItem}
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={params => {
              setPage(params.page);
            }}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={params => {
              setPageSize(params.pageSize);
            }}
            disableColumnSelector
            disableSelectionOnClick
            localeText={LocaleText}
            rows={listContracts as RowModel[]}
            columns={[
              {
                field: 'cod',
                headerName: 'Codigo',
                flex: 0.5,
                align: 'left',
              },
              {
                field: 'formattedInitialDate',
                headerName: 'Data inicial',
                flex: 1,
                align: 'left',
                renderCell: (params: ValueFormatterParams) => (
                  <>
                    {!params.row.formattedInitialDate
                      ? ''
                      : params.row.formattedInitialDate}
                  </>
                ),
              },
              {
                field: 'contractor.name',
                headerName: 'Contratante',
                flex: 1,
                align: 'left',
                renderCell: (params: ValueFormatterParams) => (
                  <>
                    {!params.row.contractor.name
                      ? ''
                      : params.row.contractor.name}
                  </>
                ),
              },
              {
                field: 'formattedStatus',
                headerName: 'Status',
                flex: 1,
                align: 'left',
              },
              {
                field: 'actions',
                headerName: 'Ações',
                width: 150,
                disableColumnMenu: true,
                align: 'center',
                renderCell: (params: ValueFormatterParams) => (
                  <>
                    <IconButton
                      onClick={() => {
                        handleSelectContract(params.row as IRequestContracts);
                      }}
                    >
                      <FiPrinter />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        history.push(`/contract/${params.row.id.toString()}`)
                      }
                    >
                      <FiEdit className={classes.editButton} />
                    </IconButton>
                    <IconButton
                      disabled={!params.row.active}
                      onClick={() =>
                        history.push(`/cancel/${params.row.id.toString()}`)
                      }
                    >
                      <FiTrash2 className={classes.trashButton} />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default ListContractDeposit;
