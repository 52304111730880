import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as Yup from 'yup';

import { ptBR } from 'date-fns/esm/locale';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { useLocation, useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import { FiPrinter } from 'react-icons/fi';
import IRequestClient from '../../../dtos/IRequestClient';
import api from '../../../services/api';
import IRequestDeposits from '../../../dtos/IRequestDeposits';
import ItemsForm from './Items/ItemsForm';
import IRequestContracts from '../../../dtos/IRequestContracts';
import IRequestItemContract from '../../../dtos/IRequestItemContract';
import formatItemContract from '../../../utils/formatItemContract';
import IRequestContractCancelDTO from '../../../dtos/IRequestContractCancel';
import TabPanel from '../../../components/TabPanel';
import ListBillsByDeposits from '../../../components/ListBillsByDeposits';
import ModalContractDepositItem from '../../../components/ModalContractDepositItem';

interface IRequestClients {
  clients: IRequestClient[];
}

interface IRequestSelectedDeposit {
  deposit: IRequestDeposits;
}

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
  };
});

interface ILocationState {
  depositId?: string;
  client?: IRequestClient;
}

interface IParams {
  id: string;
}

interface IRequest {
  contract: IRequestContracts;
}

const formatCancel = (
  cancel: IRequestContractCancelDTO,
): IRequestContractCancelDTO => {
  return {
    ...cancel,
    formatedCreatedAt: format(parseISO(cancel.createdAt), 'dd/MM/yyyy'),
  };
};

const ContractForm: React.FC = () => {
  const classes = useStyles();
  const location = useLocation<ILocationState>();
  const { id } = useParams<IParams>();

  const { enqueueSnackbar } = useSnackbar();

  const depositId = useMemo(() => {
    if (location.state?.depositId) {
      return location.state.depositId;
    }
    return '';
  }, [location]);

  const [value, setValue] = useState<number>(0);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [contractId, setContractId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(true);
  const [cancel, setCancel] = useState<IRequestContractCancelDTO[]>([]);
  const [contractCreated, setContractCreated] = useState<boolean>(false);
  const [issueDate, setIssueDate] = useState<Date | null>(new Date());
  const [initialDate, setInitialDate] = useState<Date | null>(new Date());
  const [clients, setClients] = useState<IRequestClient[]>([]);
  const [contractor, setContractor] = useState<IRequestClient>(() => {
    if (location.state?.client) {
      return location.state.client;
    }
    return {} as IRequestClient;
  });

  const [complement, setComplement] = useState<string>('');
  const [cconumber, setCconumber] = useState<number>(0);
  const [deposit, setDeposit] = useState<IRequestDeposits>(
    {} as IRequestDeposits,
  );
  const [errors, setErrors] = useState<Yup.ValidationError>(
    {} as Yup.ValidationError,
  );
  const [items, setItems] = useState<IRequestItemContract[]>([]);

  const [openModalEdit, setOpenModal] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IRequestContracts>(
    {} as IRequestContracts,
  );

  useEffect(() => {
    api
      .get<IRequestClients>('clients/active')
      .then(response => {
        setClients(response.data.clients);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar ao clientes, tente novamente.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (depositId) {
      api
        .get<IRequestSelectedDeposit>(`deposits/${depositId}`)
        .then(response => {
          setDeposit(response.data.deposit);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar Jazigo, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [enqueueSnackbar, depositId]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      setContractCreated(true);
      api
        .get<IRequest>(`contracts/${id}`)
        .then(response => {
          setSelectedContract(response.data.contract);
          setContractId(response.data.contract.id);
          setInitialDate(new Date(response.data.contract.initialDate));
          setCconumber(response.data.contract.contractNumber);
          setComplement(response.data.contract.complement);
          setActive(response.data.contract.active);

          if (response.data.contract.deposit) {
            setDeposit(response.data.contract.deposit);
          }
          if (response.data.contract.contractor) {
            setContractor(response.data.contract.contractor);
          }
          if (response.data.contract.cancel) {
            const cancelFormated = response.data.contract.cancel.map(item =>
              formatCancel(item),
            );
            setCancel(cancelFormated);
          }
          if (response.data.contract.items) {
            const formatedItems = response.data.contract.items.map(item =>
              formatItemContract(item),
            );
            setItems(formatedItems);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar o contrato , tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [enqueueSnackbar, id]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        setIsLoading(true);
        event.preventDefault();
        const data = {
          contractNumber: cconumber,
          issueDate,
          initialDate,
          ...(complement ? { complement } : {}),
          contractorId: contractor.id,
          depositId: deposit.id,
        };
        const schema = Yup.object().shape({
          contractNumber: Yup.number().required(
            'Numero de Contrato é obrigatório',
          ),
          issueDate: Yup.date().required('Data de emissão é obrigatória'),
          initialDate: Yup.date().required('Data inicial é obrigatória'),
          contractorId: Yup.string().required('Contratante é obrigatório'),
          depositId: Yup.string().required('Jazigo é obrigatório'),
          complement: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.post('/contracts', data);

        setSelectedContract(response.data.contract);
        setContractId(response.data.contract.id);
        setContractCreated(true);
        enqueueSnackbar('Contrato gerado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          setErrors(error);
          return;
        }
        enqueueSnackbar(
          'Erro inesperado ao gerar o contrato, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      cconumber,
      issueDate,
      initialDate,
      complement,
      contractor.id,
      deposit.id,
      enqueueSnackbar,
    ],
  );

  const handleUpdate = useCallback(
    async (event: FormEvent) => {
      try {
        setIsLoading(true);
        event.preventDefault();
        const data = {
          contractNumber: cconumber,
          issueDate,
          initialDate,
          ...(complement ? { complement } : {}),
          contractorId: contractor.id,
          depositId: deposit.id,
        };
        const schema = Yup.object().shape({
          contractNumber: Yup.number().required(
            'Numero de Contrato é obrigatório',
          ),
          issueDate: Yup.date().required('Data de emissão é obrigatória'),
          initialDate: Yup.date().required('Data inicial é obrigatória'),
          contractorId: Yup.string().required('Contratante é obrigatório'),
          depositId: Yup.string().required('Jazigo é obrigatório'),
          complement: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        await api.put(`/contracts/${id}`, data);
        enqueueSnackbar('Contrato atualizado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          setErrors(error);
          return;
        }
        enqueueSnackbar(
          'Erro inesperado ao atualizar o contrato, tente novamente.',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      cconumber,
      issueDate,
      initialDate,
      complement,
      contractor.id,
      deposit.id,
      id,
      enqueueSnackbar,
    ],
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setValue(newValue);
  };

  const handleToggle = useCallback(() => {
    setOpenModal(!openModalEdit);
  }, [openModalEdit]);

  return (
    <Container>
      <ModalContractDepositItem
        open={openModalEdit}
        contract={selectedContract}
        handleToggleModal={handleToggle}
      />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="scrollable force tabs example"
      >
        <Tab label="Contrato" />
        <Tab label="Cobranças" />
      </Tabs>
      <TabPanel value={value} index={0}>
        {!active &&
          cancel.map(item => (
            <div key={item.id}>
              <Alert severity="error" variant="filled">
                <AlertTitle>Contrato cancelado</AlertTitle>
                <Typography>{`Motivo: ${item.note} - Relizado por ${item.user.name} em ${item.formatedCreatedAt}`}</Typography>
              </Alert>
            </div>
          ))}
        <form
          onSubmit={isUpdate ? handleUpdate : handleSubmit}
          style={{ marginTop: '22px' }}
        >
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item md={12}>
              <Card>
                <Box className={classes.boxHeader}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Typography className={classes.headerTitle}>
                        Novo Contrato
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton onClick={handleToggle}>
                        <FiPrinter />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.headerTitle}>
                        {`Localização: ${
                          deposit.street?.block ? deposit.street.block.name : ''
                        }/ ${deposit.street ? deposit.street.name : ''}/${
                          deposit ? deposit.name : ''
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={3} sm={12} xs={12}>
                      <TextField
                        disabled={!active}
                        variant="outlined"
                        label="Número do contrato"
                        fullWidth
                        value={cconumber}
                        onChange={event =>
                          setCconumber(Number(event.target.value))
                        }
                        helperText={
                          <FormControlLabel
                            control={
                              <Checkbox name="checkedB" color="primary" />
                            }
                            label="Gerar nome automático"
                            disabled
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <Autocomplete
                        disabled={!active}
                        options={clients}
                        value={contractor}
                        getOptionLabel={option => option.name}
                        style={{ width: '100%' }}
                        onChange={(event, client) => {
                          if (client) {
                            setContractor(client);
                          } else {
                            setContractor({} as IRequestClient);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Contratante"
                            fullWidth
                            error={
                              errors.inner &&
                              Boolean(
                                errors.inner.find(
                                  e => e.path === 'contractorId',
                                ),
                              )
                            }
                          />
                        )}
                        noOptionsText="Não encontramos nenhum cliente ativo, verifique acessando a lista"
                      />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          disabled={!active}
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Data de emissão"
                          onChange={(date: Date | null) => setIssueDate(date)}
                          value={issueDate}
                          name="issueDate"
                          error={
                            errors.inner &&
                            Boolean(
                              errors.inner.find(e => e.path === 'issueDate'),
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          fullWidth
                          autoOk
                          disabled={!active}
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          label="Data inicial"
                          onChange={(date: Date | null) => setInitialDate(date)}
                          value={initialDate}
                          name="initialDate"
                          error={
                            errors.inner &&
                            Boolean(
                              errors.inner.find(e => e.path === 'initialDate'),
                            )
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <TextField
                        disabled={!active}
                        variant="outlined"
                        label="Complemento"
                        fullWidth
                        name="name"
                        value={complement}
                        onChange={event => setComplement(event.target.value)}
                        error={
                          errors.inner &&
                          Boolean(
                            errors.inner.find(e => e.path === 'complement'),
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box className={classes.boxAction}>
                  {active && (
                    <Button
                      variant="contained"
                      disableElevation
                      type="submit"
                      color={isUpdate ? 'secondary' : 'primary'}
                      disabled={isLoading || (contractCreated && !isUpdate)}
                    >
                      {!isLoading ? (
                        <>
                          {isUpdate ? 'Atualizar Contrato' : 'Criar contrato'}
                        </>
                      ) : (
                        <CircularProgress color="primary" />
                      )}
                    </Button>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </form>
        <ItemsForm
          contractCreated={contractCreated}
          contractId={contractId}
          contractItems={items}
          contractActive={active}
          client={contractor}
          ccoNumber={cconumber}
          deposit={deposit}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListBillsByDeposits />
      </TabPanel>
    </Container>
  );
};

export default ContractForm;
