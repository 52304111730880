import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';

import IRequestDeposits from '../../dtos/IRequestDeposits';
import api from '../../services/api';
import IRequestStreets from '../../dtos/IRequestStreets';
import IRequestBlocks from '../../dtos/IRequestBlocks';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IAPIRequestBlocks {
  blocks: IRequestBlocks[];
}

interface IAPIRequestDeposits {
  deposits: IRequestDeposits[];
}

interface IAPIRequestStreet {
  streets: IRequestStreets[];
}

interface IRequestUpdate {
  deposit: IRequestDeposits;
}

interface IProps {
  handleToggle: () => void;
  handleUdpateList: (newDeposit: IRequestDeposits) => void;
  open: boolean;
  clientId: string;
}

const ModalAddDepositClient: React.FC<IProps> = ({
  handleToggle,
  open,

  clientId,
  handleUdpateList,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [blocks, setBlocks] = useState<IRequestBlocks[]>([]);
  const [deposits, setDeposits] = useState<IRequestDeposits[]>([]);
  const [streets, setStreets] = useState<IRequestStreets[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedDeposit, setSelectedDeposit] = useState<IRequestDeposits>(
    {} as IRequestDeposits,
  );
  const [selectedStreets, setSelectedStreets] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );

  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );

  useEffect(() => {
    api
      .get<IAPIRequestBlocks>(`blocks/actives`, {
        params: { page: 0, pageSize: 0 },
      })
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if ('id' in blockSelected) {
      api
        .get<IAPIRequestStreet>(`streets/${blockSelected.id}/actives`)
        .then(response => {
          setStreets(response.data.streets);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as ruas, tente novamente.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [blockSelected, enqueueSnackbar]);

  useEffect(() => {
    if (selectedStreets.id) {
      api
        .get<IAPIRequestDeposits>(`deposits/${selectedStreets.id}/street`)
        .then(response => {
          setDeposits(response.data.deposits);
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar os jazigos, tente novamente.', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [enqueueSnackbar, selectedStreets]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const response = await api.patch<IRequestUpdate>(
          `deposits/${selectedDeposit.id}/clients`,
          {
            clientId,
          },
        );
        handleUdpateList(response.data.deposit);
        enqueueSnackbar('Jazigo adiconado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
        handleToggle();
      } catch {
        enqueueSnackbar('Erro ao adiconar o jazigo, tente novamente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      enqueueSnackbar,
      handleToggle,
      clientId,
      selectedDeposit,
      handleUdpateList,
    ],
  );

  return (
    <Dialog open={open} maxWidth="lg" onClose={() => handleToggle()}>
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Adicionar Jazigo</DialogTitle>
        <IconButton onClick={() => handleToggle()}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers style={{ width: '550px' }}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <Autocomplete
                options={blocks}
                value={blockSelected}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, block) => {
                  if (block) {
                    setBlockSelected(block);
                    setSelectedStreets({} as IRequestStreets);
                    setSelectedDeposit({} as IRequestDeposits);
                  } else {
                    setBlockSelected({} as IRequestBlocks);
                    setSelectedStreets({} as IRequestStreets);
                    setSelectedDeposit({} as IRequestDeposits);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Quadras"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <Autocomplete
                options={streets}
                value={selectedStreets}
                disabled={!('id' in blockSelected)}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, street) => {
                  if (street) {
                    setSelectedStreets(street);
                    setSelectedDeposit({} as IRequestDeposits);
                  } else {
                    setSelectedStreets({} as IRequestStreets);
                    setSelectedDeposit({} as IRequestDeposits);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Ruas"
                    required
                  />
                )}
              />
            </Grid>

            <Grid item md={12} xs={12} sm={12}>
              <Autocomplete
                options={deposits}
                value={selectedDeposit}
                disabled={!('id' in selectedStreets)}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, deposit) => {
                  if (deposit) {
                    setSelectedDeposit(deposit);
                  } else {
                    setSelectedDeposit({} as IRequestDeposits);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Jazigo"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading || !selectedDeposit.id}
          >
            {isLoading ? <CircularProgress /> : 'Adicionar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalAddDepositClient;
