import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/esm/locale';
import api from '../../../services/api';
import IRequestClient from '../../../dtos/IRequestClient';
import { cpfMask, cnpjMask, rgMask } from '../../../utils/cpfCnpjMask';

const useStyles = makeStyles(() => {
  return {
    boxHeader: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '16px',
    },
    boxAction: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '16px',
    },
    formControl: {
      minWidth: 120,
    },
    InputLabel: {
      backgroundColor: 'white',
      width: 140,
    },
  };
});

interface IRequest {
  client: IRequestClient;
}

const ClientForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [typeSubscription, setTypeSubscription] = useState<string>('FISICA');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [document, setDocument] = useState<string>('');
  const [rg, setRg] = useState<string>('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeSubscription = (
    event: ChangeEvent<{ value: unknown }>,
  ): void => {
    setTypeSubscription(event.target.value as string);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setIsLoading(true);
        const data = {
          name: name.toUpperCase(),
          ...(email
            ? {
                email,
              }
            : {}),
          typeSubscription,
          ...(typeSubscription === 'FISICA'
            ? {
                cpf: document,
                rg,
              }
            : {
                cnpj: document,
              }),
          birthDate,
        };
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string().email('Precisamos de E-mail válido'),
          birthDate: Yup.string().required('Data de nascimento é obrigatório'),
          typeSubscription: Yup.string().required(
            'Tipo da inscrição é obrigatório',
          ),
          cpf: Yup.string().when('typeSubscription', {
            is: val => val === 'FISICA',
            then: Yup.string().required('CPF é obrigatório'),
            otherwise: Yup.string(),
          }),
          rg: Yup.string().when('typeSubscription', {
            is: val => val === 'FISICA',
            then: Yup.string().required('RG é obrigatório'),
            otherwise: Yup.string(),
          }),
          cnpj: Yup.string().when('typeSubscription', {
            is: val => val === 'JURIDICA',
            then: Yup.string().required('CNPJ é obrigatório'),
            otherwise: Yup.string(),
          }),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.post<IRequest>('/clients', data);
        history.push(`/form/client/${response.data.client.id}`);
        enqueueSnackbar('Cliente cadastrado com sucesso.', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
          return;
        }
        enqueueSnackbar('Erro inesperado ao cadastrar o cliente.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      email,
      typeSubscription,
      document,
      rg,
      birthDate,
      history,
      enqueueSnackbar,
    ],
  );

  const handleApplyMask = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      if (typeSubscription === 'FISICA') {
        setDocument(cpfMask(event.target.value as string));
      } else {
        setDocument(cnpjMask(event.target.value as string));
      }
    },
    [typeSubscription],
  );

  const handleApplyRgMask = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      setRg(rgMask(event.target.value as string));
    },
    [],
  );
  return (
    <Container>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item md={12}>
            <Card>
              <Box className={classes.boxHeader}>
                <Typography className={classes.headerTitle}>
                  Novo Cliente
                </Typography>
              </Box>
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={4} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Nome Completo"
                      required
                      name="name"
                      value={name}
                      onChange={event =>
                        setName(event.target.value.toUpperCase())
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={4} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Email"
                      name="email"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <KeyboardDatePicker
                        fullWidth
                        autoOk
                        disableFuture
                        variant="inline"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: 'end' }}
                        format="dd/MM/yyyy"
                        label="Data de Nascimento"
                        required
                        name="birthDate"
                        onChange={(date: Date | null) => setBirthDate(date)}
                        value={birthDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <FormControl
                      fullWidth
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel
                        id="subscription-label"
                        className={classes.InputLabel}
                      >
                        Tipo de inscrição
                      </InputLabel>
                      <Select
                        labelId="subscription-label"
                        id="subscription-select"
                        label="Status"
                        required
                        variant="outlined"
                        value={typeSubscription}
                        onChange={handleChangeSubscription}
                      >
                        <MenuItem value="FISICA" selected>
                          Física
                        </MenuItem>
                        <MenuItem value="JURIDICA">Jurídica</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={typeSubscription === 'FISICA' ? 'CPF' : 'CNPJ'}
                      required
                      name="document"
                      value={document}
                      onChange={event => handleApplyMask(event)}
                    />
                  </Grid>

                  {typeSubscription === 'FISICA' && (
                    <Grid item md={3} sm={4} xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Rg"
                        required
                        name="document"
                        value={rg}
                        onChange={event => handleApplyRgMask(event)}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <Box className={classes.boxAction}>
                <Button
                  variant="contained"
                  disableElevation
                  type="submit"
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    'Novo Cliente'
                  )}
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ClientForm;
