import React, { useCallback, useState, FormEvent, useEffect } from 'react';
import * as Yup from 'yup';

import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';

import { Autocomplete } from '@material-ui/lab';
import api from '../../services/api';
import IRequestDefunctsActions from '../../dtos/IRequestDefunctsActions';

interface IProps {
  handleToogleModal: () => void;
  addNewItemInList: (action: IRequestDefunctsActions) => void;
  open: boolean;
  editingAction: IRequestDefunctsActions;
}

interface IRequest {
  defunctAction: IRequestDefunctsActions;
}

const ModalEditDefunctAction: React.FC<IProps> = ({
  handleToogleModal,
  addNewItemInList,
  open,
  editingAction,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [dateAction, setDateAction] = useState<Date | null>(null);

  useEffect(() => {
    const dateFormatedAction = new Date(editingAction.dateAction);
    setStatus(editingAction.status);
    if (editingAction.note) {
      setNote(editingAction.note);
    }
    setDateAction(dateFormatedAction);
  }, [editingAction]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const data = {
          status,
          dateAction,
          ...(note && {
            note,
          }),
        };
        const schema = Yup.object().shape({
          status: Yup.string().required('Status é obrigatório.'),
          dateAction: Yup.string().required('Rota é obrigatório.'),
          note: Yup.string(),
        });
        await schema.validate(data, { abortEarly: false });
        const response = await api.put<IRequest>(
          `defuncts-actions/${editingAction.id}`,
          data,
        );
        addNewItemInList(response.data.defunctAction);
        setStatus('');
        setNote('');
        setDateAction(null);
        handleToogleModal();
        enqueueSnackbar('Ação atualizada com sucesso', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach(err => {
            enqueueSnackbar(err.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      status,
      dateAction,
      note,
      editingAction,
      enqueueSnackbar,
      handleToogleModal,
      addNewItemInList,
    ],
  );

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item>Nova Ação</Grid>
          <Grid item>
            <IconButton onClick={() => handleToogleModal()}>
              <FiXCircle />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete="off" noValidate>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <Autocomplete
                options={['EXUMAÇÃO', 'SEPULTAMENTO', 'TRANSLADADO']}
                value={status}
                getOptionLabel={option => option}
                style={{ width: '100%' }}
                onChange={(event, actionStatus) => {
                  if (actionStatus) {
                    setStatus(actionStatus);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Status"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  autoOk
                  disableFuture
                  variant="inline"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: 'end' }}
                  format="dd/MM/yyyy"
                  label="Data"
                  required
                  name="dateAction"
                  onChange={(date: Date | null) => setDateAction(date)}
                  value={dateAction}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={12} sm={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Observação"
                name="note"
                multiline
                rows="6"
                onChange={event => setNote(event.target.value)}
                value={note}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : 'Atualizar Ação'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditDefunctAction;
