import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';

import { FiXCircle } from 'react-icons/fi';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';

import IRequestStreets from '../../dtos/IRequestStreets';
import IRequestBlocks from '../../dtos/IRequestBlocks';
import IRequestDeposits from '../../dtos/IRequestDeposits';
import api from '../../services/api';
import formatStreet from '../../utils/formatStreet';
import FieldNumberFormatCustom from '../FieldNumberFormatCustom';

const useStyles = makeStyles(() =>
  createStyles({
    headerModal: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

interface IRequest {
  deposits: IRequestDeposits[];
  blocks: IRequestBlocks;
}

interface IRequestBlock {
  blocks: IRequestBlocks[];
}

interface IProps {
  handleToggle: () => void;
  open: boolean;
  handleUpdateItem: (deposit: IRequestDeposits) => void;
  handleUpdateTotalItem: (value: number) => void;
}

const ModalEditDepositLots: React.FC<IProps> = ({ handleToggle, open }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [blocks, setBlocks] = useState<IRequestBlocks[]>([]);
  const [streets, setStreets] = useState<IRequestStreets[]>([]);
  const [blockSelected, setBlockSelected] = useState<IRequestBlocks>(
    {} as IRequestBlocks,
  );
  const [streetSelected, setStreetSelected] = useState<IRequestStreets>(
    {} as IRequestStreets,
  );
  const [page] = useState<number>(1);
  const [pageSize] = useState<number>(10);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [min, setMin] = useState<string>('');
  const [max, setMax] = useState<string>('');
  const [standardValue, setStandardValue] = useState<string>('');

  useEffect(() => {
    api
      .get<IRequestBlock>(`blocks/actives`)
      .then(response => {
        setBlocks(response.data.blocks);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao buscar o jazigo.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if ('id' in blockSelected) {
      api
        .get<IRequest>(`blocks/${blockSelected.id}`, {
          params: { page, pageSize },
        })
        .then(response => {
          if (response.data.blocks.streets) {
            const streetsFormatted = response.data.blocks.streets.map(street =>
              formatStreet(street),
            );
            setStreets(streetsFormatted);
          }
        })
        .catch(() => {
          enqueueSnackbar('Erro ao carregar as quadras, tente novamente.', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        });
    }
  }, [blockSelected, enqueueSnackbar, pageSize, page]);

  const handleEditDepositLots = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);
      enqueueSnackbar(
        `Deseja realmente editar ${
          parseInt(max, 10) - parseInt(min, 10) + 1
        } JAZIGOS ?`,
        {
          variant: 'warning',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          action: (
            <>
              <Button
                onClick={async () => {
                  closeSnackbar();
                  try {
                    const data = {
                      min,
                      max,
                      standardValue,
                    };
                    const schema = Yup.object().shape({
                      standardValue: Yup.string().required(
                        'Valor padrão é obrigatório',
                      ),
                      min: Yup.string().required('Valor Minimo é obrigatório'),
                      max: Yup.string().required('Valor Maximo é obrigatório'),
                    });
                    await schema.validate(data, { abortEarly: false });
                    await api.put<IRequest>(
                      `/deposits/${streetSelected.id}/update-value`,
                      data,
                    );
                    handleToggle();
                    enqueueSnackbar('Jazigo Editado com sucesso.', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                    });
                  } catch (error) {
                    if (error instanceof Yup.ValidationError) {
                      error.inner.forEach(err => {
                        enqueueSnackbar(err.message, {
                          variant: 'error',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                        });
                      });
                      return;
                    }
                    enqueueSnackbar('Erro inesperado ao cadastrar o jazigo.', {
                      variant: 'error',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                    });
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                Sim
              </Button>
              <Button
                onClick={() => {
                  setIsLoading(false);
                  closeSnackbar();
                }}
              >
                Não
              </Button>
            </>
          ),
        },
      );
    },
    [
      enqueueSnackbar,
      handleToggle,
      standardValue,
      streetSelected,
      closeSnackbar,
      min,
      max,
    ],
  );

  return (
    <Dialog open={open} maxWidth="lg" onClose={() => handleToggle()}>
      <header className={classes.headerModal}>
        <DialogTitle id="form-dialog-title">Editar Valores Padrão</DialogTitle>
        <IconButton onClick={() => handleToggle()}>
          <FiXCircle />
        </IconButton>
      </header>
      <form onSubmit={handleEditDepositLots}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item md={6} xs={6} sm={6}>
              <Autocomplete
                options={blocks}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                style={{ width: '100%' }}
                onChange={(event, optionClient) => {
                  if (optionClient) {
                    setBlockSelected(optionClient);
                    setStreetSelected({} as IRequestStreets);
                  } else {
                    setBlockSelected({} as IRequestBlocks);
                    setStreetSelected({} as IRequestStreets);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Quadras"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhuma quadra ativa, verifique acessando a lista"
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <Autocomplete
                options={streets}
                value={streetSelected}
                getOptionLabel={option => option.name}
                style={{ width: '100%' }}
                onChange={(event, street) => {
                  if (street) {
                    setStreetSelected(street);
                  } else {
                    setStreetSelected({} as IRequestStreets);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Ruas"
                    required
                  />
                )}
                noOptionsText="Não encontramos nenhuma rua referente a esta quadra, selecione outra quadra"
              />
            </Grid>
            <Grid item md={4} xs={4} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label="Jazigo Inicial"
                required
                name="min"
                onChange={event => setMin(event.target.value)}
                value={min}
              />
            </Grid>
            <Grid item md={4} xs={4} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label="Jazigo Final"
                required
                name="max"
                onChange={event => setMax(event.target.value)}
                value={max}
              />
            </Grid>
            <Grid item md={4} xs={4} sm={4}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                label="Valor Padrão"
                required
                name="standardValue"
                onChange={event => setStandardValue(event.target.value)}
                value={standardValue}
                InputProps={{
                  inputComponent: FieldNumberFormatCustom as any,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : 'Atualizar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalEditDepositLots;
